"use strict";
'use client';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FormSelect = void 0;
var _server = require("@whitbread-eos/utils/server");
var _image = _interopRequireDefault(require("next/image"));
var _react = _interopRequireWildcard(require("react"));
var React = _react;
var _index = require("../../Popover/index");
var _index2 = require("../../Skeleton/index");
var _index3 = require("../../Tooltip/index");
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var FormSelect = exports.FormSelect = React.forwardRef((_ref, ref) => {
  var _errors$name, _errors$name2, _selectedOption$icon;
  var {
    showLabel = true,
    className,
    name,
    placeholder,
    id,
    disabled = false,
    value,
    onChange = () => {
      return;
    },
    onBlur = () => {
      return;
    },
    onFocus = () => {
      return;
    },
    errors,
    errorIcon,
    inputIcon = '',
    arrowIcon,
    options,
    isLoading = false
  } = _ref;
  var [selectedOption, setSelectedOption] = (0, _react.useState)(value);
  var [isOpen, setIsOpen] = (0, _react.useState)(false);
  var error = (errors === null || errors === void 0 || (_errors$name = errors[name]) === null || _errors$name === void 0 ? void 0 : _errors$name.value) || (errors === null || errors === void 0 || (_errors$name2 = errors[name]) === null || _errors$name2 === void 0 ? void 0 : _errors$name2.displayValue);
  var hasError = !!(error !== null && error !== void 0 && error.type);
  (0, _react.useEffect)(() => {
    setSelectedOption(value);
  }, [value]);
  var handleChange = value => {
    onChange(value);
    setSelectedOption(value);
    setIsOpen(false);
  };
  var toggleDropdown = value => {
    if (disabled) return;
    setIsOpen(value);
    if (value) {
      onFocus();
    } else {
      onBlur();
    }
  };
  var renderButton = (0, _jsxRuntime.jsxs)("div", {
    className: "relative flex justify-center items-center",
    children: [(inputIcon || (selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.icon)) && (0, _jsxRuntime.jsx)(_image.default, {
      className: (0, _server.cn)(inputIconStyle, disabled ? disabledStyle : ''),
      src: inputIcon ? inputIcon : (_selectedOption$icon = selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.icon) !== null && _selectedOption$icon !== void 0 ? _selectedOption$icon : '',
      alt: 'Select Icon',
      width: 24,
      height: 24,
      unoptimized: true
    }), (0, _jsxRuntime.jsx)("button", {
      type: "button",
      ref: ref,
      disabled: disabled,
      className: (0, _server.cn)(selectButtonStyle, isOpen ? 'outline outline-primaryColor outline-2 -outline-offset-2' : '', hasError ? '!outline !outline-2 !outline-error !-outline-offset-2' : '', disabled ? disabledStyle : '', inputIcon || selectedOption !== null && selectedOption !== void 0 && selectedOption.icon ? 'pl-14' : ''),
      onClick: () => toggleDropdown(!isOpen),
      "data-testid": "".concat(id, "-IB-Form-Select-Button"),
      children: isLoading ? (0, _jsxRuntime.jsx)(_index2.Skeleton, {
        className: countrySkeletonStyle
      }) : selectedOption !== null && selectedOption !== void 0 && selectedOption.value ? (0, _jsxRuntime.jsx)("span", {
        className: textStyle,
        children: selectedOption.displayValue
      }) : (0, _jsxRuntime.jsx)("span", {
        className: "".concat(placeholderStyle, " ").concat(textStyle),
        children: placeholder
      })
    }), showLabel && (0, _jsxRuntime.jsx)("label", {
      htmlFor: id,
      className: (0, _server.cn)(labelStyle, hasError ? '!text-error' : '', selectedOption !== null && selectedOption !== void 0 && selectedOption.value || hasError ? '!block' : '', isOpen ? '!block text-primaryColor' : ''),
      "data-testid": "".concat(id, "-Label"),
      children: placeholder
    }), (0, _jsxRuntime.jsx)(_image.default, {
      className: (0, _server.cn)(arrowIconStyle, isOpen ? 'transform -scale-y-100' : '', disabled ? disabledStyle : ''),
      src: arrowIcon,
      alt: 'Iron icon',
      width: 24,
      height: 24
    })]
  });
  var renderOptions = () => {
    return options === null || options === void 0 ? void 0 : options.map((option, index) => (0, _jsxRuntime.jsxs)("button", {
      "data-testid": "".concat(id, "-").concat(option.value, "-Option"),
      type: "button",
      className: optionStyle,
      onClick: () => handleChange(option),
      children: [option.icon && (0, _jsxRuntime.jsx)(_image.default, {
        src: option.icon,
        alt: option.displayValue,
        width: 24,
        height: 24,
        unoptimized: true,
        className: "mr-4"
      }), (0, _jsxRuntime.jsx)("span", {
        children: option.displayValue
      })]
    }, "".concat(option.value, "-").concat(index)));
  };
  return (0, _jsxRuntime.jsx)("div", {
    "data-testid": "".concat(id, "-IB-Form-Select"),
    className: (0, _server.cn)('relative', className),
    children: (0, _jsxRuntime.jsxs)(_index.Popover, {
      open: isOpen,
      onOpenChange: () => toggleDropdown(!isOpen),
      children: [(0, _jsxRuntime.jsx)(_index.PopoverTrigger, {
        asChild: true,
        children: renderButton
      }), (0, _jsxRuntime.jsx)(_index3.ErrorTooltip, {
        icon: errorIcon,
        content: error === null || error === void 0 ? void 0 : error.message,
        open: hasError,
        testId: "".concat(id, "-Error-Tooltip"),
        className: "!flex",
        mobile: true
      }), (0, _jsxRuntime.jsx)(_index.PopoverContent, {
        align: "start",
        avoidCollisions: false,
        "data-testid": "".concat(id, "-IB-Form-Select-Dropdown"),
        className: popoverStyle,
        children: renderOptions()
      })]
    })
  });
});
FormSelect.displayName = 'Form Select';
var inputIconStyle = 'absolute left-4 w-6 h-6 text-transparent z-28';
var labelStyle = 'hidden absolute text-darkGrey1 -top-2.5 left-0 ml-3 text-sm px-1 bg-baseWhite peer-focus:text-primaryColor';
var selectButtonStyle = 'peer flex h-14 text-darkGrey1 border-lightGrey1 hover:border-darkGrey1 w-full rounded border bg-transparent p-4 pr-14 text-base transition-colors focus:outline-primaryColor bg-baseWhite';
var popoverStyle = 'w-full flex flex-col max-h-60 overflow-scroll p-0';
var placeholderStyle = 'text-darkGrey2';
var optionStyle = 'flex w-full items-center hover:bg-lightGrey5 text-left text-sm py-2.5 px-4 focus-visible:outline-none';
var arrowIconStyle = 'absolute right-4 w-6 h-6 cursor-pointer';
var disabledStyle = 'opacity-50 cursor-not-allowed hover:border-lightGrey1';
var textStyle = 'truncate';
var countrySkeletonStyle = 'absolute left-0 w-[6.375rem] h-6 ml-4';