"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "CREATE_GROUP_BOOKING", {
  enumerable: true,
  get: function get() {
    return _createGroupBooking.CREATE_GROUP_BOOKING;
  }
});
Object.defineProperty(exports, "CREATE_MEMO", {
  enumerable: true,
  get: function get() {
    return _createMemo.CREATE_MEMO;
  }
});
Object.defineProperty(exports, "GET_AGENT_MEMOS", {
  enumerable: true,
  get: function get() {
    return _getAgentMemos.GET_AGENT_MEMOS;
  }
});
Object.defineProperty(exports, "GET_ANONYMOUS_NEWSLETTER_PREFERENCES", {
  enumerable: true,
  get: function get() {
    return _anonymousNewsletterPreferences.GET_ANONYMOUS_NEWSLETTER_PREFERENCES;
  }
});
Object.defineProperty(exports, "GET_HEADER_BOOKING_FLOW_INFORMATION", {
  enumerable: true,
  get: function get() {
    return _getHeaderBookingFlowInformation.GET_HEADER_BOOKING_FLOW_INFORMATION;
  }
});
Object.defineProperty(exports, "GET_HEADER_BOOKING_INFORMATION", {
  enumerable: true,
  get: function get() {
    return _getHeaderBookingInformation.GET_HEADER_BOOKING_INFORMATION;
  }
});
Object.defineProperty(exports, "GET_HOTEL_INFORMATION", {
  enumerable: true,
  get: function get() {
    return _getHotelInformation.GET_HOTEL_INFORMATION;
  }
});
Object.defineProperty(exports, "GET_SEO_INFORMATION", {
  enumerable: true,
  get: function get() {
    return _getSEOInformation.GET_SEO_INFORMATION;
  }
});
Object.defineProperty(exports, "GET_STATIC_CONTENT", {
  enumerable: true,
  get: function get() {
    return _getStaticContent.GET_STATIC_CONTENT;
  }
});
Object.defineProperty(exports, "RETRIEVE_CHANGES_LOG", {
  enumerable: true,
  get: function get() {
    return _changesLog.RETRIEVE_CHANGES_LOG;
  }
});
Object.defineProperty(exports, "getFooterDetails", {
  enumerable: true,
  get: function get() {
    return _footer.getFooterDetails;
  }
});
Object.defineProperty(exports, "getInnBusinessHeader", {
  enumerable: true,
  get: function get() {
    return _getInnBusinessHeader.getInnBusinessHeader;
  }
});
Object.defineProperty(exports, "getStaticLabels", {
  enumerable: true,
  get: function get() {
    return _getStaticLabels.getStaticLabels;
  }
});
var _anonymousNewsletterPreferences = require("./anonymousNewsletterPreferences");
var _changesLog = require("./changesLog");
var _createGroupBooking = require("./createGroupBooking");
var _createMemo = require("./createMemo");
var _footer = require("./footer");
var _getAgentMemos = require("./getAgentMemos");
var _getHeaderBookingFlowInformation = require("./getHeaderBookingFlowInformation");
var _getHeaderBookingInformation = require("./getHeaderBookingInformation");
var _getHotelInformation = require("./getHotelInformation");
var _getInnBusinessHeader = require("./getInnBusinessHeader");
var _getSEOInformation = require("./getSEOInformation");
var _getStaticContent = require("./getStaticContent");
var _getStaticLabels = require("./getStaticLabels");