"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRegistrationQuestionsQuery = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var getRegistrationQuestionsQuery = () => (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query getEmployeeRegistrationQuestionsAndAnswers($companyId: String!, $employeeId: String!) {\n    getEmployeeRegistrationQuestionsAndAnswers(companyId: $companyId, employeeId: $employeeId) {\n      purchaseOrderManagement {\n        questionId\n        label\n        mandatory\n        managementHeader\n        active\n        location\n        managementInformationAnswer {\n          answerType\n          answers\n        }\n        type\n        positionId\n        answer\n      }\n      customerReferenceManagement {\n        questionId\n        label\n        mandatory\n        managementHeader\n        active\n        location\n        managementInformationAnswer {\n          answerType\n          answers\n        }\n        type\n        positionId\n        answer\n      }\n      userDefinedQuestions {\n        questionId\n        label\n        mandatory\n        managementHeader\n        active\n        location\n        managementInformationAnswer {\n          answerType\n          answers\n        }\n        type\n        positionId\n        userDefinedAnswer\n      }\n    }\n  }\n"])));
exports.getRegistrationQuestionsQuery = getRegistrationQuestionsQuery;