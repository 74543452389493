"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_EMPLOYEE_STAY_RULES_QUERY = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var GET_EMPLOYEE_STAY_RULES_QUERY = exports.GET_EMPLOYEE_STAY_RULES_QUERY = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query getEmployeeStayRules($channel: Channel!) {\n    maxRoomsLimitation(channel: $channel) {\n      maxRooms\n      maxRoomsAmend\n    }\n  }\n"])));