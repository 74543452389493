"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useIPageSubmission = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _api = require("@whitbread-eos/api");
var _react = require("react");
var _analytics = _interopRequireDefault(require("../services/analyticsService/analytics"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var isPaymentCompletionMessage = event => {
  if (typeof event.data === 'string') {
    try {
      var _JSON$parse;
      return Boolean((_JSON$parse = JSON.parse(event.data)) === null || _JSON$parse === void 0 ? void 0 : _JSON$parse.paymentId);
    } catch (e) {
      console.log(e);
    }
  }
  return false;
};
var useIPageSubmission = () => {
  var [transactionState, setTransactionState] = (0, _react.useState)({
    paymentId: undefined,
    isPaymentComplete: false,
    merchantReference: undefined,
    transactionId: undefined,
    authCode: undefined,
    cardType: undefined,
    tokenNo: undefined,
    paymentStatus: undefined
  });
  var setIsPaymentComplete = isPaymentComplete => setTransactionState(_objectSpread(_objectSpread({}, transactionState), {}, {
    isPaymentComplete
  }));
  var handler = (0, _react.useCallback)(event => {
    if (event.data === _api.APGP_PLANET_MESSAGE_GP) {
      _analytics.default.update({
        gp: true,
        ap: false
      });
    } else if (event.data === _api.APGP_PLANET_MESSAGE_AP) {
      _analytics.default.update({
        gp: false,
        ap: true
      });
    }
    if (isPaymentCompletionMessage(event)) {
      var data = JSON.parse(event.data);
      var {
        paymentId,
        cardType,
        merchantReference,
        transactionId,
        authCode,
        tokenNo,
        paymentStatus
      } = data;
      setTransactionState({
        paymentId,
        isPaymentComplete: true,
        cardType,
        merchantReference,
        transactionId,
        authCode,
        tokenNo,
        paymentStatus
      });
    }
  }, []);
  (0, _react.useEffect)(() => {
    window.addEventListener('message', handler);
    return () => window.removeEventListener('message', handler);
  }, []);
  return _objectSpread(_objectSpread({}, transactionState), {}, {
    setIsPaymentComplete,
    handler
  });
};
exports.useIPageSubmission = useIPageSubmission;