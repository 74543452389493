"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "FormRadioGroup", {
  enumerable: true,
  get: function get() {
    return _formRadioGroup.FormRadioGroup;
  }
});
var _formRadioGroup = require("./form-radio-group");