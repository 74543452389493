"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CompanySearch;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _yup = require("yup");
var _CompanySelection = _interopRequireDefault(require("../../common/CompanySelection"));
var _modalType = require("../../common/CompanySelection/modalType.enum");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var COMPANY_NAME_VALIDATIONS = {
  MATCHES: /^[a-zA-Z0-9\s.,:;_!?"*%=+£$€¥&@#()\-'À-ÖØ-ʒͰ-ͳͶ-ͷͻ-ͽvΑ-Ͽἀ-ῼЀ-ӿễấ]+$/g,
  MIN: 2,
  MAX: 50
};
var COMPANY_ID_VALIDATIONS = {
  MATCHES: /^\d+$/,
  MIN: 2,
  MAX: 50
};
function CompanySearch(_ref) {
  var {
    showCompanyIdInput = false,
    setSearchDisabled,
    contractRateCompanyState
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var [inputNameValue, setInputNameValue] = (0, _react2.useState)('');
  var [inputIdValue, setInputIdValue] = (0, _react2.useState)('');
  var [disabledInput, setDisabledInput] = (0, _react2.useState)('');
  var [showCheckCompany, setShowCheckCompany] = (0, _react2.useState)(false);
  var [companyNameError, setCompanyNameError] = (0, _react2.useState)('');
  var [companyIdError, setCompanyIdError] = (0, _react2.useState)('');
  var [searchByName, setSearchByName] = (0, _react2.useState)(true);
  var [showCompanySelectionModal, setShowCompanySelectionModal] = (0, _react2.useState)(false);
  var [companies, setCompanies] = (0, _react2.useState)([]);
  var [checkCompanyClicked, setCheckCompanyClicked] = (0, _react2.useState)(false);
  var [verifiedCompany, setVerifiedCompany] = (0, _react2.useState)(null);
  var nameInputRef = (0, _react2.useRef)();
  var idInputRef = (0, _react2.useRef)();
  var [lastFocus, setLastFocus] = (0, _react2.useState)('');
  var inputNamePlaceholder = t('ccui.search.companyName.placeholder');
  var inputIdPlaceholder = t('ccui.search.companyId.placeholder');
  var baseDataTestId = 'CompanyName';
  var baseDataIdTestId = 'CompanyId';
  var companyNameInput = 'companyName';
  var companyIdInput = 'companyId';
  var [contractRateCompany, setContractRateCompany] = contractRateCompanyState;
  var inputNameChangeValidationSchema = (0, _yup.string)().when('$value', _ref2 => {
    var [value] = _ref2;
    return (value === null || value === void 0 ? void 0 : value.length) > 0 ? (0, _yup.string)().matches(COMPANY_NAME_VALIDATIONS.MATCHES, t('ccui.company.invalidCompanyError')).max(COMPANY_NAME_VALIDATIONS.MAX, t('ccui.company.characterLengthError')) : (0, _yup.string)().notRequired();
  });
  var inputIdChangeValidationSchema = (0, _yup.string)().when('$value', _ref3 => {
    var [value] = _ref3;
    return (value === null || value === void 0 ? void 0 : value.length) > 0 ? (0, _yup.string)().matches(COMPANY_ID_VALIDATIONS.MATCHES, t('ccui.company.invalidCompanyError')).max(COMPANY_ID_VALIDATIONS.MAX, t('ccui.company.characterLengthError')) : (0, _yup.string)().notRequired();
  });
  var submitValidationSchema = inputValidation => (0, _yup.string)().required(t('ccui.company.invalidCompanyError')).matches(inputValidation.MATCHES, t('ccui.company.invalidCompanyError')).min(inputValidation.MIN, t('ccui.company.minCharacterLengthError')).max(inputValidation.MAX, t('ccui.company.characterLengthError'));
  var {
    data: companiesData,
    isFetching: companiesRequestFetching,
    isError: companiesRequestError,
    refetch: fetchCompanies
  } = (0, _utils.useQueryRequest)(['searchCompanies', inputNameValue, true, 50, 1], _api.SEARCH_COMPANIES, {
    searchTerm: inputNameValue,
    negotiatedRateCompanies: true,
    limit: 50,
    offset: 1
  }, {
    enabled: false
  }, undefined, true);
  var {
    data: companyData,
    isFetching: companyDataRequestFetching,
    isError: companyDataRequestError,
    refetch: fetchCompanyData
  } = (0, _utils.useQueryRequest)(['searchCompany', inputIdValue], _api.SEARCH_COMPANY_BY_ID_OR_CORP_ID, {
    id: inputIdValue
  }, {
    enabled: false
  }, undefined, true);
  (0, _react2.useEffect)(() => {
    if (searchByName && !companiesRequestFetching && checkCompanyClicked) {
      if (companiesRequestError) {
        setCompanies([]);
        setCompanyNameError(t('ccui.company.technicalerror'));
      } else {
        var _companiesData$search;
        validateCompanies(companiesData === null || companiesData === void 0 || (_companiesData$search = companiesData.searchCompanies) === null || _companiesData$search === void 0 ? void 0 : _companiesData$search.companies);
      }
      setCheckCompanyClicked(false);
    }
  }, [companiesRequestFetching, companiesData, companiesRequestError, checkCompanyClicked, searchByName]);
  (0, _react2.useEffect)(() => {
    if (!searchByName && !companyDataRequestFetching && checkCompanyClicked) {
      if (companyDataRequestError) {
        setCompanies([]);
        setCompanyIdError(t('ccui.company.technicalerror'));
      } else if (companyData) {
        validateCompanies(companyData.companyProfileById.companyId ? [companyData.companyProfileById] : []);
      }
      setCheckCompanyClicked(false);
    }
  }, [companyDataRequestFetching, companyData, companyDataRequestError, checkCompanyClicked, searchByName]);
  (0, _react2.useEffect)(() => {
    if (contractRateCompany) {
      setVerifiedCompany(contractRateCompany);
      setInputNameValue(contractRateCompany.name);
      setInputIdValue(contractRateCompany.searchCriteriaId || '');
    }
  }, [contractRateCompany]);
  var validateCompanies = companies => {
    if (companies && companies.length > 0) {
      setCompanies(companies);
      setShowCompanySelectionModal(true);
    } else {
      setCompanies([]);
      if (searchByName) {
        setCompanyNameError(t('ccui.company.nocompanies'));
      } else {
        setCompanyIdError(t('ccui.company.nocompanies'));
      }
    }
  };
  var handleFocusLost = event => {
    if (!event.relatedTarget || !event.currentTarget.contains(event.relatedTarget)) {
      setShowCheckCompany(false);
      var searchEnabled = verifiedCompany && verifiedCompany.name === inputNameValue || inputNameValue === '' && inputIdValue === '';
      setSearchDisabled(!searchEnabled);
    }
    setLastFocus(event.target.id);
    setDisabledInput('');
  };
  var handleFocus = event => {
    var isVerifiedCompany = searchByName ? inputNameValue === (verifiedCompany === null || verifiedCompany === void 0 ? void 0 : verifiedCompany.name) : inputIdValue === (verifiedCompany === null || verifiedCompany === void 0 ? void 0 : verifiedCompany.corpId) || inputIdValue === (verifiedCompany === null || verifiedCompany === void 0 ? void 0 : verifiedCompany.companyId);
    setShowCheckCompany(!isVerifiedCompany);
    setSearchDisabled(!isVerifiedCompany);
    if (event.target.id === companyNameInput) {
      setDisabledInput(companyIdInput);
    } else if (event.target.id === companyIdInput) {
      setDisabledInput(companyNameInput);
    }
  };
  var handleInputErrors = function () {
    var _ref4 = (0, _asyncToGenerator2.default)(function* (value, fieldValidation, fieldType) {
      var _validationResult$err5;
      var validationResult = yield fieldValidation.validate(value.toUpperCase(), {
        context: {
          value
        }
      }).catch(err => {
        return err;
      });
      var hasValidationError = (validationResult === null || validationResult === void 0 ? void 0 : validationResult.errors) && validationResult.errors.length > 0;
      if (fieldType === companyIdInput) {
        var _validationResult$err, _validationResult$err2;
        setCompanyIdError((_validationResult$err = validationResult === null || validationResult === void 0 || (_validationResult$err2 = validationResult.errors) === null || _validationResult$err2 === void 0 ? void 0 : _validationResult$err2[0]) !== null && _validationResult$err !== void 0 ? _validationResult$err : '');
      } else {
        var _validationResult$err3, _validationResult$err4;
        setCompanyNameError((_validationResult$err3 = validationResult === null || validationResult === void 0 || (_validationResult$err4 = validationResult.errors) === null || _validationResult$err4 === void 0 ? void 0 : _validationResult$err4[0]) !== null && _validationResult$err3 !== void 0 ? _validationResult$err3 : '');
      }
      return {
        hasError: hasValidationError,
        error: (_validationResult$err5 = validationResult.errors) === null || _validationResult$err5 === void 0 ? void 0 : _validationResult$err5[0]
      };
    });
    return function handleInputErrors(_x, _x2, _x3) {
      return _ref4.apply(this, arguments);
    };
  }();
  var onChangeNameFn = val => {
    handleInputErrors(val, inputNameChangeValidationSchema, companyNameInput);
    var isVerifiedCompany = val === (verifiedCompany === null || verifiedCompany === void 0 ? void 0 : verifiedCompany.name);
    setShowCheckCompany(!isVerifiedCompany);
    setSearchDisabled(!isVerifiedCompany);
    setContractRateCompany(isVerifiedCompany ? verifiedCompany : null);
    if (val) {
      setInputIdValue('');
      setCompanyIdError('');
    }
    setInputNameValue(val);
  };
  var onChangeIdFn = val => {
    handleInputErrors(val, inputIdChangeValidationSchema, companyIdInput);
    var isVerifiedCompany = [verifiedCompany === null || verifiedCompany === void 0 ? void 0 : verifiedCompany.corpId, verifiedCompany === null || verifiedCompany === void 0 ? void 0 : verifiedCompany.companyId].includes(val);
    setShowCheckCompany(!isVerifiedCompany);
    setSearchDisabled(!isVerifiedCompany);
    setContractRateCompany(isVerifiedCompany ? verifiedCompany : null);
    if (val) {
      setInputNameValue('');
      setCompanyNameError('');
    }
    setInputIdValue(val);
  };
  var handleCheckCompanyClicked = function () {
    var _ref5 = (0, _asyncToGenerator2.default)(function* () {
      var searchValue = lastFocus === companyNameInput ? inputNameValue : inputIdValue;
      var validationSchema = lastFocus === companyNameInput ? COMPANY_NAME_VALIDATIONS : COMPANY_ID_VALIDATIONS;
      var {
        hasError
      } = yield handleInputErrors(searchValue, submitValidationSchema(validationSchema), lastFocus);
      if (!hasError) {
        if (lastFocus === companyNameInput) {
          fetchCompanies();
        } else {
          fetchCompanyData();
        }
        setCheckCompanyClicked(true);
        setSearchByName(lastFocus === companyNameInput);
      }
    });
    return function handleCheckCompanyClicked() {
      return _ref5.apply(this, arguments);
    };
  }();
  var handleCompanySelectionModalClosed = () => {
    setShowCompanySelectionModal(false);
    setSearchDisabled(true);
    setShowCheckCompany(true);
  };
  var handleCompanyVerified = company => {
    setInputNameValue(company.name);
    var searchCriteriaId = searchByName ? company.corpId : inputIdValue;
    if (inputNameValue) {
      setInputIdValue((company === null || company === void 0 ? void 0 : company.corpId) || '');
    }
    setShowCompanySelectionModal(false);
    setSearchDisabled(false);
    setShowCheckCompany(false);
    setVerifiedCompany(_objectSpread(_objectSpread({}, company), {}, {
      searchCriteriaId
    }));
    setContractRateCompany(_objectSpread(_objectSpread({}, company), {}, {
      searchCriteriaId
    }));
  };
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(_react.VStack, _objectSpread(_objectSpread({
      spacing: 4,
      align: "stretch",
      alignSelf: "end"
    }, checkCompanyStyles), {}, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId),
      onFocus: handleFocus,
      onBlur: handleFocusLost,
      children: [(0, _jsxRuntime.jsx)(_atoms.Input, {
        name: companyNameInput,
        placeholderText: inputNamePlaceholder,
        value: inputNameValue,
        onChange: onChangeNameFn,
        label: inputNamePlaceholder,
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Input'),
        styles: inputStyles,
        inputRef: nameInputRef,
        error: showCheckCompany && !!companyNameError,
        isDisabled: disabledInput === companyNameInput
      }), showCheckCompany && companyNameError && (0, _jsxRuntime.jsx)(_atoms.InfoMessage, {
        infoMessage: companyNameError,
        otherStyles: errorMessageStyles,
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Error')
      }), showCompanyIdInput && (0, _jsxRuntime.jsx)(_atoms.Input, {
        name: companyIdInput,
        placeholderText: inputIdPlaceholder,
        value: inputIdValue,
        onChange: onChangeIdFn,
        label: inputIdPlaceholder,
        "data-testid": (0, _utils.formatDataTestId)(baseDataIdTestId, 'Input'),
        styles: inputStyles,
        inputRef: idInputRef,
        error: showCheckCompany && !!companyIdError,
        isDisabled: disabledInput === companyIdInput
      }), showCheckCompany && companyIdError && (0, _jsxRuntime.jsx)(_atoms.InfoMessage, {
        infoMessage: companyIdError,
        otherStyles: errorMessageStyles,
        "data-testid": (0, _utils.formatDataTestId)(baseDataIdTestId, 'Error')
      }), showCheckCompany && (0, _jsxRuntime.jsx)(_atoms.Button, {
        size: "full",
        variant: "tertiary",
        transition: 'unset',
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'CheckCompanyBtn'),
        isDisabled: companiesRequestFetching || companyDataRequestFetching,
        onClick: handleCheckCompanyClicked,
        children: t('ccui.company.checkCompany')
      })]
    })), showCompanySelectionModal && (0, _jsxRuntime.jsx)(_CompanySelection.default, {
      showCompanySelectionModal: showCompanySelectionModal,
      companyModalType: _modalType.COMPANY_MODAL_TYPE.CONTRACT_RATE_CODE,
      companies: companies,
      finalFocusRef: lastFocus === companyNameInput ? nameInputRef : idInputRef,
      onClose: handleCompanySelectionModalClosed,
      onCompanyVerified: handleCompanyVerified
    })]
  });
}
var checkCompanyStyles = {
  w: {
    mobile: '21.4375rem',
    md: '17.625rem',
    lg: '17.625rem',
    xl: '26rem'
  }
};
var errorMessageStyles = {
  position: 'relative',
  w: 'full',
  marginTop: '0'
};
var inputStyles = {
  inputElementStyles: {
    p: '1rem',
    borderRadius: 'var(--chakra-space-radiusSmall)',
    borderColor: 'lightGrey1',
    color: 'darkGrey1',
    bg: 'baseWhite',
    boxShadow: {
      base: 'none',
      sm: '0px 0.125rem 0.75rem var(--chakra-colors-lightGrey2)'
    },
    _hover: {
      borderColor: 'darkGrey1'
    }
  }
};