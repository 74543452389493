"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "GET_HOTELS_INFORMATION", {
  enumerable: true,
  get: function get() {
    return _getHotelsInformation.GET_HOTELS_INFORMATION;
  }
});
var _getHotelsInformation = require("./getHotelsInformation");