"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.INITIATE_PRE_CHECKIN_SCA = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var INITIATE_PRE_CHECKIN_SCA = exports.INITIATE_PRE_CHECKIN_SCA = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  mutation InitiatePreCheckInSCA($requestId: String!, $environment: String!, $language: String!) {\n    authorizeCard(\n      initiateAuthorizeScaRequest: {\n        requestId: $requestId\n        environment: $environment\n        language: $language\n      }\n    ) {\n      paymentRedirect\n      template\n      sessionId\n      providerUrl\n    }\n  }\n"])));