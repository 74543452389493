"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_ROOM_CLASS_CONFIG_QUERY = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var GET_ROOM_CLASS_CONFIG_QUERY = exports.GET_ROOM_CLASS_CONFIG_QUERY = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query getRoomClassConfig(\n    $brand: String\n    $channel: Channel!\n    $country: String\n    $language: String\n  ) {\n    roomClassConfig(brand: $brand, channel: $channel, country: $country, language: $language) {\n      roomClassConfig {\n        code\n        order\n      }\n    }\n  }\n"])));