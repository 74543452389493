"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_HOTELS_INFORMATION = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var GET_HOTELS_INFORMATION = exports.GET_HOTELS_INFORMATION = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query getHotelsInformation(\n    $hotelIds: [<String>!]!,\n    $country: String!,\n    $language: String!,\n    $latitudeRef: Float,\n    $longitudeRef: Float\n  ) {\n    getHotelsInformation(hotelIds: $hotelIds, country: $country, language: $language, latitudeRef: $latitudeRef, longitudeRef: $longitudeRef) {\n    name\n    brand\n    distanceFromReference\n    links {\n      detailsPage\n    }\n    hotelFacilities {\n      code\n      description\n      icon\n      isVisible\n      name\n      weight\n    }\n    topSectionImages {\n      thumbnailSrc\n      tags\n      imageSrc\n      iconSrc\n      caption\n      alt\n    }\n  }\n}\n"])));