"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUserManagementLabels = exports.getProfileManagementLabels = exports.getInnBusinessHeaderLabels = exports.getInitials = exports.getFooterLabels = exports.getCardManagementLabels = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _api = require("@whitbread-eos/api");
var _gql = require("../gql");
var getFooterLabels = exports.getFooterLabels = function () {
  var _ref = (0, _asyncToGenerator2.default)(function* (language) {
    return yield (0, _gql.executeGraphQLQueries)((0, _api.getFooterDetails)(), {
      language,
      country: language === 'en' ? 'gb' : 'de',
      site: 'business-booker'
    }, result => {
      var _result$data;
      return result === null || result === void 0 || (_result$data = result.data) === null || _result$data === void 0 ? void 0 : _result$data.footer;
    });
  });
  return function getFooterLabels(_x) {
    return _ref.apply(this, arguments);
  };
}();
var getInnBusinessHeaderLabels = exports.getInnBusinessHeaderLabels = function () {
  var _ref2 = (0, _asyncToGenerator2.default)(function* (language) {
    return yield (0, _gql.executeGraphQLQueries)((0, _api.getInnBusinessHeader)(), {
      language,
      country: language === 'en' ? 'gb' : 'de',
      businessBooker: true
    }, result => {
      var _result$data2;
      return result === null || result === void 0 || (_result$data2 = result.data) === null || _result$data2 === void 0 || (_result$data2 = _result$data2.headerInformation) === null || _result$data2 === void 0 ? void 0 : _result$data2.innBusinessHeader;
    });
  });
  return function getInnBusinessHeaderLabels(_x2) {
    return _ref2.apply(this, arguments);
  };
}();
var getCardManagementLabels = exports.getCardManagementLabels = function () {
  var _ref3 = (0, _asyncToGenerator2.default)(function* (language) {
    return yield (0, _gql.executeGraphQLQueries)((0, _api.getCardManagementLabels)(), {
      language
    }, result => {
      var _result$data3;
      return result === null || result === void 0 || (_result$data3 = result.data) === null || _result$data3 === void 0 || (_result$data3 = _result$data3.getCardManagementLabels) === null || _result$data3 === void 0 ? void 0 : _result$data3.cardManagementContent;
    });
  });
  return function getCardManagementLabels(_x3) {
    return _ref3.apply(this, arguments);
  };
}();
var getUserManagementLabels = exports.getUserManagementLabels = function () {
  var _ref4 = (0, _asyncToGenerator2.default)(function* (language) {
    var data = yield (0, _gql.executeGraphQLQueries)((0, _api.getUserManagementLabels)(), {
      country: language === 'en' ? 'gb' : 'de',
      language
    }, result => {
      var _result$data4;
      return result === null || result === void 0 || (_result$data4 = result.data) === null || _result$data4 === void 0 || (_result$data4 = _result$data4.getPageData) === null || _result$data4 === void 0 ? void 0 : _result$data4.userManagementEndpoint;
    });
    return JSON.parse(data);
  });
  return function getUserManagementLabels(_x4) {
    return _ref4.apply(this, arguments);
  };
}();
var getProfileManagementLabels = exports.getProfileManagementLabels = function () {
  var _ref5 = (0, _asyncToGenerator2.default)(function* (language) {
    var data = yield (0, _gql.executeGraphQLQueries)((0, _api.getProfileManagementLabelsQuery)(), {
      country: language === 'en' ? 'gb' : 'de',
      language
    }, result => {
      var _result$data5;
      return result === null || result === void 0 || (_result$data5 = result.data) === null || _result$data5 === void 0 || (_result$data5 = _result$data5.getPageData) === null || _result$data5 === void 0 ? void 0 : _result$data5.profileManagementEndpoint;
    });
    return JSON.parse(data);
  });
  return function getProfileManagementLabels(_x5) {
    return _ref5.apply(this, arguments);
  };
}();
var getInitials = (firstName, lastName) => {
  return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
};
exports.getInitials = getInitials;