"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ListView;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _index = require("../../index");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var MAP_SMALL_ITEMS_NUM = 3;
function ListView(_ref) {
  var _partialTranslations$;
  var {
    fetchNewHotels,
    items,
    hasMore,
    partialTranslations,
    resultsMeta,
    orderedHotels,
    roomTypes,
    multiSearchParams,
    language,
    currentPage,
    baseDataTestId,
    isHotelAvailable,
    variant,
    changeViewType,
    headerInformation
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var SCROLL_THRESHOLD_CONSTANT = 1;
  var {
    fullyBooked = '',
    openingSoon = ''
  } = (partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$ = partialTranslations.searchInformation) === null || _partialTranslations$ === void 0 || (_partialTranslations$ = _partialTranslations$.content) === null || _partialTranslations$ === void 0 || (_partialTranslations$ = _partialTranslations$.results) === null || _partialTranslations$ === void 0 ? void 0 : _partialTranslations$.notifications) || {};
  var isMlos = t('search.mlos.ccui.notification');
  var {
    isLessThanMd
  } = (0, _utils.useScreenSize)();
  return (0, _jsxRuntime.jsxs)(_atoms.InfiniteScroller, {
    next: fetchNewHotels,
    dataLength: items.length,
    hasMore: hasMore,
    scrollThreshold: SCROLL_THRESHOLD_CONSTANT,
    style: {
      overflow: 'hidden'
    },
    children: [resultsMeta.total > 0 && (0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({}, totalResultsTextStyles), {}, {
      children: [resultsMeta.total, " ", partialTranslations.searchInformation.content.totalHotels]
    })), variant !== _api.Area.CCUI && isLessThanMd && (0, _jsxRuntime.jsxs)(_react.Box, {
      height: "75px",
      position: "relative",
      children: [(0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({
        "data-testid": "mobile-map-view-button"
      }, mobileViewTypeButtonStyles), {}, {
        onClick: changeViewType,
        children: (0, _jsxRuntime.jsx)(_atoms.MapExpand, {})
      })), variant === _api.Area.PI && (0, _jsxRuntime.jsx)(_index.MapViewPIVariant, {
        items: items.slice(0, MAP_SMALL_ITEMS_NUM),
        multiSearchParams: multiSearchParams,
        locale: language,
        partialTranslations: partialTranslations,
        headerInformation: headerInformation,
        baseDataTestId: baseDataTestId
      }), variant === _api.Area.BB && (0, _jsxRuntime.jsx)(_index.MapViewBBVariant, {
        items: items.slice(0, MAP_SMALL_ITEMS_NUM),
        multiSearchParams: multiSearchParams,
        locale: language,
        partialTranslations: partialTranslations,
        headerInformation: headerInformation,
        baseDataTestId: baseDataTestId
      })]
    }), isHotelAvailable && (!(isHotelAvailable !== null && isHotelAvailable !== void 0 && isHotelAvailable.isAvailable) || (isHotelAvailable === null || isHotelAvailable === void 0 ? void 0 : isHotelAvailable.isOpeningSoon) || isHotelAvailable.hasMlos) && (0, _jsxRuntime.jsx)(_molecules.SRHotelNotification, {
      description: isHotelAvailable.hasMlos ? isMlos : isHotelAvailable !== null && isHotelAvailable !== void 0 && isHotelAvailable.isOpeningSoon ? openingSoon : fullyBooked
    }), orderedHotels.map(hotelData => (0, _jsxRuntime.jsx)(_index.SRHotelCard, {
      roomTypes: roomTypes,
      data: hotelData,
      multiSearchParams: multiSearchParams,
      partialTranslations: partialTranslations,
      locale: language || 'en',
      variant: variant
    }, hotelData.name)), hasMore && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
      align: "center"
    }, loadingTextStyles), {}, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'loading'),
      children: t('searchresults.list.hotel.loading')
    }), currentPage)]
  });
}
var loadingTextStyles = {
  color: 'darkGrey1',
  fontSize: 'xl',
  fontWeight: 'bold',
  lineHeight: '3',
  my: '2xl'
};
var totalResultsTextStyles = {
  lineHeight: '2',
  fontSize: 'sm',
  fontWeight: 'medium',
  m: 'auto',
  mb: 'var(--chakra-space-sm)',
  color: 'darkGrey1',
  w: {
    mobile: 'full',
    md: '45rem',
    lg: '50.5rem',
    xl: '54rem'
  }
};
var mobileViewTypeButtonStyles = {
  pos: 'absolute',
  top: 'var(--chakra-space-lg)',
  right: 'var(--chakra-space-sm)',
  zIndex: '10',
  background: 'white',
  padding: 'var(--chakra-space-sm)',
  cursor: 'pointer'
};