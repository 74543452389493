"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  getPathForLocale: true,
  DEFAULT_TRACING_COOKIE_NAME: true,
  ID_TOKEN_COOKIE: true,
  RolesRequired: true,
  useTranslationServer: true,
  TranslationProvider: true,
  useTranslation: true,
  cn: true
};
Object.defineProperty(exports, "DEFAULT_TRACING_COOKIE_NAME", {
  enumerable: true,
  get: function get() {
    return _globalConstants.DEFAULT_TRACING_COOKIE_NAME;
  }
});
Object.defineProperty(exports, "ID_TOKEN_COOKIE", {
  enumerable: true,
  get: function get() {
    return _globalConstants.ID_TOKEN_COOKIE;
  }
});
Object.defineProperty(exports, "RolesRequired", {
  enumerable: true,
  get: function get() {
    return _index.RolesRequired;
  }
});
Object.defineProperty(exports, "TranslationProvider", {
  enumerable: true,
  get: function get() {
    return _i18nClient.TranslationProvider;
  }
});
Object.defineProperty(exports, "cn", {
  enumerable: true,
  get: function get() {
    return _tailwind.cn;
  }
});
Object.defineProperty(exports, "getPathForLocale", {
  enumerable: true,
  get: function get() {
    return _getPathForLocale.getPathForLocale;
  }
});
Object.defineProperty(exports, "useTranslation", {
  enumerable: true,
  get: function get() {
    return _i18nClient.useTranslation;
  }
});
Object.defineProperty(exports, "useTranslationServer", {
  enumerable: true,
  get: function get() {
    return _i18n.useTranslation;
  }
});
var _getPathForLocale = require("../formatters/getPathForLocale");
var _globalConstants = require("../global-constants");
var _index = require("../hoc/index");
var _i18n = require("../utils/i18n");
var _i18nClient = require("../utils/i18nClient");
var _tailwind = require("../utils/tailwind");
var _getters = require("./getters");
Object.keys(_getters).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _getters[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _getters[key];
    }
  });
});
var _gql = require("./gql");
Object.keys(_gql).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _gql[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _gql[key];
    }
  });
});
var _formatters = require("./formatters");
Object.keys(_formatters).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _formatters[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _formatters[key];
    }
  });
});
var _validators = require("./validators");
Object.keys(_validators).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _validators[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _validators[key];
    }
  });
});