"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAccountList = exports.getAccessLevel = exports.businessTetherLogin = void 0;
exports.getActivePath = getActivePath;
exports.getUserDetails = exports.getTetheredStatus = exports.getServerUnleashToggles = exports.getRegistrationQuestionsWithAnswers = exports.getRandomTracingId = exports.getPostCodeAddresses = exports.getLocationResults = exports.getLocaleByPathname = exports.getInnBusinessServerSideProps = exports.getHotelInformationIB = exports.getHotelAvailabilitiesIB = exports.getFormattedAddress = exports.getEmployees = exports.getEmployeeDetails = exports.getDaysInMonth = exports.getCountryName = exports.getCountryLanguageByLocale = exports.getCountriesList = exports.getCompanyDetails = exports.getCommonIconsIB = exports.getCommonIcons = void 0;
exports.roomDefaultValueIfError = roomDefaultValueIfError;
exports.staticHotelInformationIB = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _ = require("..");
var _api = require("@whitbread-eos/api");
var _nanoid = require("nanoid");
var _config = _interopRequireDefault(require("next/config"));
var _headers = require("next/headers");
var _decodeIdToken = _interopRequireDefault(require("../../utils/decodeIdToken"));
var _unleash = require("../../utils/unleash");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var getCountriesList = exports.getCountriesList = function () {
  var _ref = (0, _asyncToGenerator2.default)(function* (language) {
    return yield (0, _.executeGraphQLQueries)(_api.GET_COUNTRIES, {
      language,
      country: language === 'en' ? 'gb' : 'de',
      site: 'business-booker'
    }, result => {
      var _result$data;
      return result === null || result === void 0 || (_result$data = result.data) === null || _result$data === void 0 || (_result$data = _result$data.countries) === null || _result$data === void 0 ? void 0 : _result$data.countries;
    });
  });
  return function getCountriesList(_x) {
    return _ref.apply(this, arguments);
  };
}();
var getUserDetails = exports.getUserDetails = function () {
  var _ref2 = (0, _asyncToGenerator2.default)(function* (token) {
    var {
      publicRuntimeConfig = {}
    } = (0, _config.default)() || {};
    var {
      email
    } = (0, _decodeIdToken.default)(token);
    if (!email) {
      return null;
    }
    try {
      var res = yield fetch("".concat(publicRuntimeConfig.NEXT_PUBLIC_REST_API, "/customers/hotels/").concat(email, "?business=true"), {
        method: 'GET',
        headers: {
          Origin: publicRuntimeConfig.NEXT_PUBLIC_ASSETS_URL_WITHOUT_BASIC,
          Authorization: "Bearer ".concat(token)
        },
        cache: 'no-cache'
      });
      if (!res.ok) {
        return null;
      }
      return yield res.json();
    } catch (error) {
      return null;
    }
  });
  return function getUserDetails(_x2) {
    return _ref2.apply(this, arguments);
  };
}();
var getAccessLevel = exports.getAccessLevel = function () {
  var _ref3 = (0, _asyncToGenerator2.default)(function* () {
    var _cookies$get$value, _cookies$get, _userDetails$business;
    var token = (_cookies$get$value = (_cookies$get = (0, _headers.cookies)().get(_.ID_TOKEN_COOKIE)) === null || _cookies$get === void 0 ? void 0 : _cookies$get.value) !== null && _cookies$get$value !== void 0 ? _cookies$get$value : '';
    var userDetails = yield getUserDetails(token);
    return userDetails === null || userDetails === void 0 || (_userDetails$business = userDetails.business) === null || _userDetails$business === void 0 ? void 0 : _userDetails$business.accessLevel;
  });
  return function getAccessLevel() {
    return _ref3.apply(this, arguments);
  };
}();
var getTetheredStatus = exports.getTetheredStatus = function () {
  var _ref4 = (0, _asyncToGenerator2.default)(function* () {
    var _cookies$get$value2, _cookies$get2, _userDetails$business2;
    var token = (_cookies$get$value2 = (_cookies$get2 = (0, _headers.cookies)().get(_.ID_TOKEN_COOKIE)) === null || _cookies$get2 === void 0 ? void 0 : _cookies$get2.value) !== null && _cookies$get$value2 !== void 0 ? _cookies$get$value2 : '';
    var userDetails = yield getUserDetails(token);
    return !!(userDetails !== null && userDetails !== void 0 && (_userDetails$business2 = userDetails.business) !== null && _userDetails$business2 !== void 0 && _userDetails$business2.tethered);
  });
  return function getTetheredStatus() {
    return _ref4.apply(this, arguments);
  };
}();
var getCompanyDetails = exports.getCompanyDetails = function () {
  var _ref5 = (0, _asyncToGenerator2.default)(function* (companyId, sessionId, employeeId, token) {
    var {
      publicRuntimeConfig = {}
    } = (0, _config.default)() || {};
    var {
      email
    } = (0, _decodeIdToken.default)(token);
    if (!email || !companyId) {
      return null;
    }
    try {
      var res = yield global['fetch']("".concat(publicRuntimeConfig.NEXT_PUBLIC_REST_API, "/company/").concat(companyId), {
        method: 'GET',
        headers: {
          'session-id': sessionId,
          'employee-id': employeeId,
          'company-id': companyId,
          Origin: publicRuntimeConfig.NEXT_PUBLIC_ASSETS_URL_WITHOUT_BASIC,
          Authorization: "Bearer ".concat(token)
        },
        cache: 'no-cache'
      });
      if (!res.ok) {
        return null;
      }
      return yield res.json();
    } catch (error) {
      return null;
    }
  });
  return function getCompanyDetails(_x3, _x4, _x5, _x6) {
    return _ref5.apply(this, arguments);
  };
}();
var getLocationResults = exports.getLocationResults = function () {
  var _ref6 = (0, _asyncToGenerator2.default)(function* (value) {
    var response = yield fetch("".concat(process.env.NEXT_PUBLIC_SNOWDROP_BASE_URL, "v1/autocomplete?input=/").concat(value, "&gplaces[components]=country:uk|country:de"));
    return response.json();
  });
  return function getLocationResults(_x7) {
    return _ref6.apply(this, arguments);
  };
}();
var getRegistrationQuestionsWithAnswers = exports.getRegistrationQuestionsWithAnswers = function () {
  var _ref7 = (0, _asyncToGenerator2.default)(function* (employeeId) {
    var _cookies$get$value3, _cookies$get3;
    var token = (_cookies$get$value3 = (_cookies$get3 = (0, _headers.cookies)().get(_.ID_TOKEN_COOKIE)) === null || _cookies$get3 === void 0 ? void 0 : _cookies$get3.value) !== null && _cookies$get$value3 !== void 0 ? _cookies$get$value3 : '';
    var userDetails = yield getUserDetails(token);
    return yield (0, _.executeGraphQLQueries)((0, _api.getRegistrationQuestionsQuery)(), {
      companyId: userDetails === null || userDetails === void 0 ? void 0 : userDetails.companyId,
      employeeId
    }, result => {
      var _result$data2;
      return (0, _.parseRegistrationQuestions)(result === null || result === void 0 || (_result$data2 = result.data) === null || _result$data2 === void 0 ? void 0 : _result$data2.getEmployeeRegistrationQuestionsAndAnswers);
    }, token, true);
  });
  return function getRegistrationQuestionsWithAnswers(_x8) {
    return _ref7.apply(this, arguments);
  };
}();
var getEmployees = exports.getEmployees = function () {
  var _ref8 = (0, _asyncToGenerator2.default)(function* (companyId, size, token, page, searchCriteria, pageToken, bookingChannel, awaitingApproval) {
    return yield (0, _.executeGraphQLQueries)((0, _api.getEmployeesQuery)(), {
      companyId,
      size,
      page,
      searchCriteria,
      pageToken,
      bookingChannel,
      awaitingApproval
    }, result => {
      var _result$data3;
      return result === null || result === void 0 || (_result$data3 = result.data) === null || _result$data3 === void 0 ? void 0 : _result$data3.getEmployees;
    }, token);
  });
  return function getEmployees(_x9, _x10, _x11, _x12, _x13, _x14, _x15, _x16) {
    return _ref8.apply(this, arguments);
  };
}();
var getEmployeeDetails = exports.getEmployeeDetails = function () {
  var _ref9 = (0, _asyncToGenerator2.default)(function* (companyId, employeeId, token) {
    return yield (0, _.executeGraphQLQueries)((0, _api.getEmployeeByIdQuery)(), {
      companyId,
      employeeId
    }, result => {
      var _result$data4;
      return result === null || result === void 0 || (_result$data4 = result.data) === null || _result$data4 === void 0 ? void 0 : _result$data4.getEmployeeDetails;
    }, token);
  });
  return function getEmployeeDetails(_x17, _x18, _x19) {
    return _ref9.apply(this, arguments);
  };
}();
var getCommonIconsIB = exports.getCommonIconsIB = function () {
  var _ref10 = (0, _asyncToGenerator2.default)(function* (language) {
    var data = yield (0, _.executeGraphQLQueries)((0, _api.getUserManagementLabels)(), {
      country: language === 'en' ? 'gb' : 'de',
      language
    }, result => {
      var _result$data5;
      return result === null || result === void 0 || (_result$data5 = result.data) === null || _result$data5 === void 0 || (_result$data5 = _result$data5.getPageData) === null || _result$data5 === void 0 ? void 0 : _result$data5.commonIconsEndpoint;
    });
    return JSON.parse(data);
  });
  return function getCommonIconsIB(_x20) {
    return _ref10.apply(this, arguments);
  };
}();
var getCommonIcons = exports.getCommonIcons = function () {
  var _ref11 = (0, _asyncToGenerator2.default)(function* (language) {
    return yield (0, _.executeGraphQLQueries)((0, _api.getCardManagementLabels)(), {
      language
    }, result => {
      var _result$data6;
      return result === null || result === void 0 || (_result$data6 = result.data) === null || _result$data6 === void 0 || (_result$data6 = _result$data6.getCardManagementLabels) === null || _result$data6 === void 0 ? void 0 : _result$data6.commonIcons;
    });
  });
  return function getCommonIcons(_x21) {
    return _ref11.apply(this, arguments);
  };
}();
var getPostCodeAddresses = exports.getPostCodeAddresses = function () {
  var _ref12 = (0, _asyncToGenerator2.default)(function* (postCode, token) {
    return yield (0, _.executeGraphQLQueries)(_api.GET_ADDRESSES, {
      searchTerm: postCode
    }, result => {
      var _result$data7;
      return result === null || result === void 0 || (_result$data7 = result.data) === null || _result$data7 === void 0 ? void 0 : _result$data7.partialAddress;
    }, token);
  });
  return function getPostCodeAddresses(_x22, _x23) {
    return _ref12.apply(this, arguments);
  };
}();
var getAccountList = exports.getAccountList = function () {
  var _ref13 = (0, _asyncToGenerator2.default)(function* (token) {
    return yield (0, _.executeGraphQLQueries)(_api.GET_ACCOUNT_LIST, {
      viewAll: false
    }, result => {
      var _result$data8;
      return result === null || result === void 0 || (_result$data8 = result.data) === null || _result$data8 === void 0 || (_result$data8 = _result$data8.getAccountList) === null || _result$data8 === void 0 ? void 0 : _result$data8.accounts;
    }, token);
  });
  return function getAccountList(_x24) {
    return _ref13.apply(this, arguments);
  };
}();
var businessTetherLogin = exports.businessTetherLogin = function () {
  var _ref14 = (0, _asyncToGenerator2.default)(function* (token, tetheredGuid, locale) {
    return yield (0, _.executeGraphQLQueries)(_api.BUSINESS_TETHER_LOGIN, {
      loginCriteria: {
        guid: tetheredGuid,
        scheme: locale === _api.LOCALES.EN ? 'GB' : 'DE'
      }
    }, result => {
      var _result$data9;
      return result === null || result === void 0 || (_result$data9 = result.data) === null || _result$data9 === void 0 ? void 0 : _result$data9.businessTetherLogin;
    }, token);
  });
  return function businessTetherLogin(_x25, _x26, _x27) {
    return _ref14.apply(this, arguments);
  };
}();
var getServerUnleashToggles = exports.getServerUnleashToggles = function () {
  var _ref15 = (0, _asyncToGenerator2.default)(function* (label, fallbackFlags, url, query) {
    var _tracingCookie$name;
    var cookieStore = (0, _headers.cookies)();
    var tracingCookie = cookieStore.get(_.DEFAULT_TRACING_COOKIE_NAME);
    return (0, _unleash.getUnleashTogglesServerOrClient)(cookieStore, label, fallbackFlags, url, query, {
      [_.DEFAULT_TRACING_COOKIE_NAME]: (_tracingCookie$name = tracingCookie === null || tracingCookie === void 0 ? void 0 : tracingCookie.name) !== null && _tracingCookie$name !== void 0 ? _tracingCookie$name : ''
    });
  });
  return function getServerUnleashToggles(_x28, _x29, _x30, _x31) {
    return _ref15.apply(this, arguments);
  };
}();
var getRandomTracingId = () => {
  return (0, _nanoid.nanoid)();
};
exports.getRandomTracingId = getRandomTracingId;
function getActivePath(currentPath, navObj) {
  var _Object$values$find$l, _Object$values;
  return (_Object$values$find$l = (_Object$values = Object.values(navObj)) === null || _Object$values === void 0 || (_Object$values = _Object$values.find(value => currentPath === null || currentPath === void 0 ? void 0 : currentPath.includes(value === null || value === void 0 ? void 0 : value.path))) === null || _Object$values === void 0 ? void 0 : _Object$values.label) !== null && _Object$values$find$l !== void 0 ? _Object$values$find$l : '';
}
var getLocaleByPathname = pathname => {
  var segments = pathname.split('/');
  var locale = segments[1].toLowerCase();
  var mappedLocale = {
    en: _api.LOCALES.EN,
    gb: _api.LOCALES.EN,
    de: _api.LOCALES.DE
  };
  return mappedLocale[locale] || locale;
};
exports.getLocaleByPathname = getLocaleByPathname;
var getCountryLanguageByLocale = locale => {
  if (locale === _api.CountryCode.DE) {
    return {
      country: _api.CountryCode.DE,
      language: _api.CountryCode.DE
    };
  }
  return {
    country: locale === _api.LOCALES.DE ? _api.CountryCode.DE : _api.CountryCode.GB,
    language: locale === _api.LOCALES.DE ? _api.CountryCode.DE : _api.CountryCode.EN
  };
};
exports.getCountryLanguageByLocale = getCountryLanguageByLocale;
var getInnBusinessServerSideProps = exports.getInnBusinessServerSideProps = function () {
  var _ref16 = (0, _asyncToGenerator2.default)(function* (token, language) {
    var needsFooter = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    try {
      var _footer;
      var footer;
      if (needsFooter) {
        footer = yield (0, _.getFooterLabels)(language);
      }
      var [userDetails, labels, icons, cards] = yield Promise.all([getUserDetails(token), (0, _.getInnBusinessHeaderLabels)(language), getCommonIcons(language), (0, _.getCardManagementLabels)(language)]);
      var companyDetails = yield getCompanyDetails(userDetails.companyId, userDetails.sessionId, userDetails.employeeId, token);
      return {
        labels,
        icons,
        cards,
        userDetails,
        companyDetails,
        footer: (_footer = footer) !== null && _footer !== void 0 ? _footer : null,
        language
      };
    } catch (_unused) {
      return null;
    }
  });
  return function getInnBusinessServerSideProps(_x32, _x33) {
    return _ref16.apply(this, arguments);
  };
}();
var getHotelInformationIB = exports.getHotelInformationIB = function () {
  var _ref17 = (0, _asyncToGenerator2.default)(function* (hotelId, language) {
    return yield (0, _.executeGraphQLQueries)(_api.GET_HOTEL_INFORMATION, {
      hotelId,
      country: language === 'en' ? 'gb' : 'de',
      language
    }, result => {
      var _result$data10;
      return result === null || result === void 0 || (_result$data10 = result.data) === null || _result$data10 === void 0 ? void 0 : _result$data10.hotelInformation;
    });
  });
  return function getHotelInformationIB(_x34, _x35) {
    return _ref17.apply(this, arguments);
  };
}();
var getHotelAvailabilitiesIB = exports.getHotelAvailabilitiesIB = function () {
  var _ref18 = (0, _asyncToGenerator2.default)(function* (hotelId, arrival, departure, brand, country, rooms, language, bookingChannel, channel, token) {
    return yield (0, _.executeGraphQLQueries)(_api.HOTEL_AVAILABILITY_QUERY, {
      hotelId,
      arrival,
      departure,
      brand,
      country,
      rooms,
      language,
      bookingChannel,
      channel
    }, result => result === null || result === void 0 ? void 0 : result.data, token);
  });
  return function getHotelAvailabilitiesIB(_x36, _x37, _x38, _x39, _x40, _x41, _x42, _x43, _x44, _x45) {
    return _ref18.apply(this, arguments);
  };
}();
var staticHotelInformationIB = exports.staticHotelInformationIB = function () {
  var _ref19 = (0, _asyncToGenerator2.default)(function* (slug, language) {
    return yield (0, _.executeGraphQLQueries)(_api.STATIC_HOTEL_INFORMATION_QUERY, {
      slug,
      country: language === 'en' ? 'gb' : 'de',
      language
    }, result => {
      var _result$data11;
      return result === null || result === void 0 || (_result$data11 = result.data) === null || _result$data11 === void 0 ? void 0 : _result$data11.hotelInformationBySlug;
    });
  });
  return function staticHotelInformationIB(_x46, _x47) {
    return _ref19.apply(this, arguments);
  };
}();
function roomDefaultValueIfError(rooms) {
  var defaultValue = {
    adultsNumber: _api.DEFAULT_NUMBER,
    childrenNumber: 0,
    type: _api.ROOM_CODES.double
  };
  if (rooms.length === 0) {
    return [_objectSpread({}, defaultValue)];
  }
  var hasInvalidValue = rooms.some(room => {
    return (0, _.validateRoomOccupancyConditions)(room.adultsNumber.toString(), room.childrenNumber.toString(), room.type);
  });
  if (hasInvalidValue) {
    return [_objectSpread({}, defaultValue)];
  }
  return rooms;
}
var getDaysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate();
};
exports.getDaysInMonth = getDaysInMonth;
var getCountryName = (countryCode, countries) => {
  if (countryCode === 'D') {
    countryCode = _api.ShortCountry.DE;
  }
  var country = countries.find(country => country.countryCode === countryCode);
  return country && country.countryName;
};
exports.getCountryName = getCountryName;
var getFormattedAddress = exports.getFormattedAddress = function () {
  var _ref20 = (0, _asyncToGenerator2.default)(function* (addressId) {
    return yield (0, _.executeGraphQLQueries)(_api.GET_FORMATTED_ADDRESS, {
      identifier: encodeURIComponent(addressId)
    }, result => {
      var _result$data12;
      return result === null || result === void 0 || (_result$data12 = result.data) === null || _result$data12 === void 0 ? void 0 : _result$data12.formattedAddress;
    });
  });
  return function getFormattedAddress(_x48) {
    return _ref20.apply(this, arguments);
  };
}();