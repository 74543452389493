"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isInnBusinessApp = exports.isHotelOpeningSoon = void 0;
exports.isMoreThan364DaysInFuture = isMoreThan364DaysInFuture;
exports.validateRoomOccupancy = validateRoomOccupancy;
exports.validateRoomOccupancyConditions = validateRoomOccupancyConditions;
var _api = require("@whitbread-eos/api");
var _dateFns = require("date-fns");
var isHotelOpeningSoon = (hotelOpeningDate, selectedDate) => {
  var openingSoonDate = hotelOpeningDate !== '' && new Date(JSON.parse(JSON.stringify(hotelOpeningDate)));
  return openingSoonDate && (0, _dateFns.differenceInDays)(openingSoonDate, selectedDate) > 0;
};
exports.isHotelOpeningSoon = isHotelOpeningSoon;
function validateRoomOccupancyConditions(adult, child, roomType, cot) {
  return Number.isNaN(Number(adult)) || Number(adult) < _api.DEFAULT_NUMBER || Number(adult) > 2 || Number(adult) > _api.DEFAULT_NUMBER && roomType === _api.ROOM_CODES.single || Number(adult) === _api.DEFAULT_NUMBER && roomType === _api.ROOM_CODES.twin || Number.isNaN(Number(child)) || Number(child) < 0 || Number(child) > 2 || Number(child) === 0 && roomType === _api.ROOM_CODES.family || Number(child) > 0 && roomType !== _api.ROOM_CODES.family || cot !== null && cot !== undefined && (Number.isNaN(Number(cot)) || Number(cot) < 0 || Number(cot) > _api.DEFAULT_NUMBER) || roomType !== _api.ROOM_CODES.single && roomType !== _api.ROOM_CODES.double && roomType !== _api.ROOM_CODES.family && roomType !== _api.ROOM_CODES.twin && roomType !== _api.ROOM_CODES.accessible;
}
function isMoreThan364DaysInFuture(startDate, today) {
  var dayInFuture = (0, _dateFns.addDays)(today, 364);
  return (0, _dateFns.isAfter)(startDate, dayInFuture);
}
function validateRoomOccupancy(roomIndex, searchParamsObj) {
  var adultKey = "".concat(_api.URLParams.adult).concat(roomIndex);
  var childKey = "".concat(_api.URLParams.child).concat(roomIndex);
  var cotKey = "".concat(_api.URLParams.cot).concat(roomIndex);
  var roomTypeKey = "".concat(_api.URLParams.roomType).concat(roomIndex);
  var isAdultMissing = searchParamsObj[adultKey] === undefined;
  var isChildMissing = searchParamsObj[childKey] === undefined;
  var isCotMissing = searchParamsObj[cotKey] === undefined;
  var isRoomTypeMissing = searchParamsObj[roomTypeKey] === undefined;
  if (isAdultMissing || isChildMissing || isCotMissing || isRoomTypeMissing) {
    return true;
  }
  var isAdultValid = !isNaN(Number(searchParamsObj[adultKey]));
  var isChildValid = !isNaN(Number(searchParamsObj[childKey]));
  var isCotValid = !isNaN(Number(searchParamsObj[cotKey]));
  var isRoomTypeValid = searchParamsObj[roomTypeKey] !== '';
  var allValid = isAdultValid && isChildValid && isCotValid && isRoomTypeValid;
  return !allValid;
}
var isInnBusinessApp = hostname => {
  if (process.env.NODE_ENV === 'development') {
    return true;
  }
  return hostname && hostname.includes('innbusiness');
};
exports.isInnBusinessApp = isInnBusinessApp;