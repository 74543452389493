"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "RadioGroup", {
  enumerable: true,
  get: function get() {
    return _radioGroup.RadioGroup;
  }
});
Object.defineProperty(exports, "RadioGroupItem", {
  enumerable: true,
  get: function get() {
    return _radioGroup.RadioGroupItem;
  }
});
var _radioGroup = require("./radio-group");