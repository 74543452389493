"use strict";
'use client';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DialogTrigger = exports.DialogTitle = exports.DialogPortal = exports.DialogOverlay = exports.DialogHeader = exports.DialogFooter = exports.DialogDescription = exports.DialogContent = exports.DialogClose = exports.Dialog = void 0;
var _objectDestructuringEmpty2 = _interopRequireDefault(require("@babel/runtime/helpers/objectDestructuringEmpty"));
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var DialogPrimitive = _interopRequireWildcard(require("@radix-ui/react-dialog"));
var _server = require("@whitbread-eos/utils/server");
var _lucideReact = require("lucide-react");
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["className"],
  _excluded2 = ["className", "children", "hasCloseButton"],
  _excluded3 = ["className"],
  _excluded4 = ["className"],
  _excluded5 = ["className"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var Dialog = exports.Dialog = DialogPrimitive.Root;
var DialogTrigger = exports.DialogTrigger = DialogPrimitive.Trigger;
var DialogPortal = exports.DialogPortal = DialogPrimitive.Portal;
var DialogClose = exports.DialogClose = DialogPrimitive.Close;
var DialogOverlay = exports.DialogOverlay = React.forwardRef((_ref, ref) => {
  var {
      className
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  return (0, _jsxRuntime.jsx)(DialogPrimitive.Overlay, _objectSpread({
    ref: ref,
    className: (0, _server.cn)('fixed inset-0 z-50 bg-black/50 data-[state=open]:animate-in data-[state=closed]:animate-out', 'data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0', className)
  }, props));
});
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;
var DialogContent = exports.DialogContent = React.forwardRef((_ref2, ref) => {
  var {
      className,
      children,
      hasCloseButton = true
    } = _ref2,
    props = (0, _objectWithoutProperties2.default)(_ref2, _excluded2);
  return (0, _jsxRuntime.jsxs)(DialogPortal, {
    children: [(0, _jsxRuntime.jsx)(DialogOverlay, {
      className: "mobile:hidden"
    }), (0, _jsxRuntime.jsxs)(DialogPrimitive.Content, _objectSpread(_objectSpread({
      ref: ref,
      className: (0, _server.cn)('fixed left-[50%] top-[50%] z-50 grid w-full max-w-[836px] mobile:h-screen', 'translate-x-[-50%] translate-y-[-50%] gap-12 bg-background p-12 duration-200', 'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0', 'data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95', 'data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%]', 'data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]', 'rounded-lg mobile:rounded-none mobile:px-4 mobile:py-6 mobile:flex mobile:flex-col', 'overflow-hidden mobile:overflow-y-auto', className)
    }, props), {}, {
      children: [hasCloseButton && (0, _jsxRuntime.jsx)(DialogPrimitive.Close, {
        "data-testid": "Dialog-X-Close-Button",
        className: "absolute top-12 right-12 mobile:top-6 mobile:right-4",
        children: (0, _jsxRuntime.jsx)(_lucideReact.X, {
          className: "stroke-lightGrey2 w-10 h-10"
        })
      }), children]
    }))]
  });
});
DialogContent.displayName = DialogPrimitive.Content.displayName;
var DialogHeader = _ref3 => {
  var {
      className
    } = _ref3,
    props = (0, _objectWithoutProperties2.default)(_ref3, _excluded3);
  return (0, _jsxRuntime.jsx)("div", _objectSpread({
    className: (0, _server.cn)('flex flex-col space-y-1.5', className)
  }, props));
};
exports.DialogHeader = DialogHeader;
DialogHeader.displayName = 'DialogHeader';
var DialogFooter = _ref4 => {
  var {
      className
    } = _ref4,
    props = (0, _objectWithoutProperties2.default)(_ref4, _excluded4);
  return (0, _jsxRuntime.jsx)("div", _objectSpread({
    className: (0, _server.cn)('flex gap-8 mobile:flex-col mobile:gap-6', className)
  }, props));
};
exports.DialogFooter = DialogFooter;
DialogFooter.displayName = 'DialogFooter';
var DialogTitle = exports.DialogTitle = React.forwardRef((_ref5, ref) => {
  var {
      className
    } = _ref5,
    props = (0, _objectWithoutProperties2.default)(_ref5, _excluded5);
  return (0, _jsxRuntime.jsx)(DialogPrimitive.Title, _objectSpread({
    ref: ref,
    className: (0, _server.cn)('text-secondaryColor text-4xl font-black leading-none tracking-tight mb-4', className)
  }, props));
});
DialogTitle.displayName = DialogPrimitive.Title.displayName;
var DialogDescription = exports.DialogDescription = React.forwardRef((_ref6, ref) => {
  var props = (0, _extends2.default)({}, ((0, _objectDestructuringEmpty2.default)(_ref6), _ref6));
  return (0, _jsxRuntime.jsx)(DialogPrimitive.Description, _objectSpread({
    ref: ref
  }, props));
});
DialogDescription.displayName = DialogPrimitive.Description.displayName;