"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CollapseExpandText;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _react2 = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function CollapseExpandText(props) {
  var baseTestId = props.baseTestId;
  var [isDescriptionExpanded, setIsDescriptionExpanded] = (0, _react2.useState)(false);
  var [isExpandLinkClicked, setIsExpandLinkClicked] = (0, _react2.useState)(false);
  var [collapseHeight, setCollapseHeight] = (0, _react2.useState)(props.startingHeight);
  var [isTextHeightClamped, setIsTextHeightClamped] = (0, _react2.useState)(false);
  var collapseRef = _react2.default.useRef(null);
  var descriptionRef = _react2.default.useRef(null);
  var handleToggleExpand = () => {
    setIsExpandLinkClicked(true);
    setIsDescriptionExpanded(!isDescriptionExpanded);
  };
  (0, _react2.useEffect)(() => {
    var _descriptionRef$curre, _collapseRef$current;
    if (descriptionRef !== null && descriptionRef !== void 0 && (_descriptionRef$curre = descriptionRef.current) !== null && _descriptionRef$curre !== void 0 && _descriptionRef$curre.scrollHeight) {
      var _descriptionRef$curre2, _descriptionRef$curre3;
      var value = (descriptionRef === null || descriptionRef === void 0 || (_descriptionRef$curre2 = descriptionRef.current) === null || _descriptionRef$curre2 === void 0 ? void 0 : _descriptionRef$curre2.scrollHeight) <= collapseHeight ? descriptionRef === null || descriptionRef === void 0 || (_descriptionRef$curre3 = descriptionRef.current) === null || _descriptionRef$curre3 === void 0 ? void 0 : _descriptionRef$curre3.scrollHeight : collapseHeight;
      setCollapseHeight(value);
    }
    if (collapseRef !== null && collapseRef !== void 0 && (_collapseRef$current = collapseRef.current) !== null && _collapseRef$current !== void 0 && _collapseRef$current.scrollHeight) {
      var _descriptionRef$curre4, _collapseRef$current2;
      var _value = (descriptionRef === null || descriptionRef === void 0 || (_descriptionRef$curre4 = descriptionRef.current) === null || _descriptionRef$curre4 === void 0 ? void 0 : _descriptionRef$curre4.scrollHeight) > (collapseRef === null || collapseRef === void 0 || (_collapseRef$current2 = collapseRef.current) === null || _collapseRef$current2 === void 0 ? void 0 : _collapseRef$current2.scrollHeight) || isExpandLinkClicked;
      setIsTextHeightClamped(_value);
    }
  });
  return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, descriptionWrapperStyle), {}, {
    "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'Content'),
    children: [(0, _jsxRuntime.jsx)(_react.Collapse, {
      ref: collapseRef,
      startingHeight: collapseHeight,
      in: isDescriptionExpanded,
      children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, descriptionStyle), {}, {
        "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'Description'),
        ref: descriptionRef,
        style: _objectSpread({
          WebkitLineClamp: isDescriptionExpanded ? 'unset' : props.noOfLines,
          WebkitBoxOrient: 'vertical'
        }, descriptionInlineStyle),
        children: props.contentText
      }))
    }), props.isFadeEffect && (0, _jsxRuntime.jsx)(_react.Box, {
      mt: "-5rem",
      height: "5rem",
      position: "relative",
      bg: "".concat(!isDescriptionExpanded && props.contentText.length > 200 && 'linear-gradient(to bottom, var(--chakra-colors-fadedBaseWhite) 0%, var(--chakra-colors-baseWhite) 75%)')
    }), (0, _jsxRuntime.jsx)(_react.Link, _objectSpread(_objectSpread({
      "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'Expand-Collapse'),
      display: isTextHeightClamped ? 'block' : 'none'
    }, linkStyle), {}, {
      onClick: handleToggleExpand,
      children: isDescriptionExpanded ? props.collapseButtonText : props.expandButtonText
    }))]
  }));
}
var descriptionStyle = {
  fontSize: 'lg',
  fontWeight: 'normal'
};
var descriptionInlineStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box'
};
var linkStyle = {
  textDecoration: 'underline',
  color: 'tertiary',
  _focus: {
    outline: 'none'
  },
  fontSize: 'md',
  fontWeight: 'normal',
  marginTop: 'var(--chakra-space-xs)'
};
var descriptionWrapperStyle = {
  marginBottom: 'var(--chakra-space-lg)',
  marginTop: 'var(--chakra-space-lg)'
};