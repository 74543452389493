"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelBadges;
exports.getColor = getColor;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _constants = require("../../utils/constants");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function HotelBadges(_ref) {
  var _premierPlusBadge$cod;
  var {
    hasMlosRestriction,
    hotelOpeningDate,
    isHotelOpeningSoon,
    labels,
    testId,
    hotelFacilities,
    messagingFlag,
    isColumnDisplay,
    styles
  } = _ref;
  var premierPlusBadge = hotelFacilities === null || hotelFacilities === void 0 ? void 0 : hotelFacilities.find(facility => facility.code === _constants.PREMIER_PLUS_FACILITY_CODE);
  var hasBadges = hotelOpeningDate && isHotelOpeningSoon || premierPlusBadge || (messagingFlag === null || messagingFlag === void 0 ? void 0 : messagingFlag.text) && (messagingFlag === null || messagingFlag === void 0 ? void 0 : messagingFlag.text) !== 'hub' || hasMlosRestriction;
  var badges = (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [hasMlosRestriction && renderHotelBadge({
      badgeColor: _constants.MLOS,
      badgeText: labels === null || labels === void 0 ? void 0 : labels.mlos,
      isColumnDisplay,
      variant: 'solid'
    }), hotelOpeningDate && isHotelOpeningSoon && renderHotelBadge({
      badgeColor: _constants.OPENING_SOON_MESSAGING_FLAG_TEXT,
      badgeText: labels === null || labels === void 0 ? void 0 : labels.openingSoon,
      isColumnDisplay
    }), premierPlusBadge && renderHotelBadge({
      badgeColor: (_premierPlusBadge$cod = premierPlusBadge === null || premierPlusBadge === void 0 ? void 0 : premierPlusBadge.code) !== null && _premierPlusBadge$cod !== void 0 ? _premierPlusBadge$cod : '',
      badgeText: labels === null || labels === void 0 ? void 0 : labels.premierPlus,
      isColumnDisplay
    }), (messagingFlag === null || messagingFlag === void 0 ? void 0 : messagingFlag.text) && (messagingFlag === null || messagingFlag === void 0 ? void 0 : messagingFlag.text) !== 'hub' && renderHotelBadge({
      badgeColor: _constants.NEW_HOTEL_MESSAGING_FLAG_TEXT,
      badgeText: messagingFlag.text,
      isColumnDisplay
    })]
  });
  if (!hasBadges) {
    return null;
  }
  return isColumnDisplay ? (0, _jsxRuntime.jsx)(_react.Grid, _objectSpread(_objectSpread({}, styles), {}, {
    "data-testid": testId,
    templateColumns: "repeat(2, 1fr)",
    justifyItems: "flex-start",
    gridGap: "sm",
    width: "fit-content",
    children: badges
  })) : (0, _jsxRuntime.jsx)(_react.Wrap, _objectSpread(_objectSpread({}, styles), {}, {
    "data-testid": testId,
    children: badges
  }));
}
function renderHotelBadge(_ref2) {
  var {
    badgeColor,
    badgeText,
    variant = 'secondary',
    isColumnDisplay
  } = _ref2;
  if (!badgeText) {
    return null;
  }
  var badge = (0, _jsxRuntime.jsx)(_atoms.Badge, {
    badgecolor: getColor(badgeColor),
    color: variant === 'secondary' ? getColor(badgeColor) : 'baseWhite',
    variant: variant,
    width: "100%",
    textAlign: "center",
    cursor: "pointer",
    children: badgeText
  });
  return isColumnDisplay ? (0, _jsxRuntime.jsx)(_react.GridItem, {
    width: "100%",
    children: badge
  }) : (0, _jsxRuntime.jsx)(_react.WrapItem, {
    children: badge
  });
}
function getColor(label) {
  switch (label) {
    case _constants.BIGGER_ROOM_CODE:
      return 'lightPurple';
    case _constants.PREMIER_PLUS_FACILITY_CODE:
      return 'primary';
    case _constants.NEW_HOTEL_MESSAGING_FLAG_TEXT:
    case _constants.NEW_ROOMS_MESSAGING_FLAG_TEXT:
    case _constants.OPENING_SOON_MESSAGING_FLAG_TEXT:
      return 'maroon';
    case _constants.PREMIER_EXTRA_CODE:
      return 'blue';
    case _constants.MLOS:
      return 'green';
    default:
      return 'darkPink';
  }
}