"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AmendContainer;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _reactQuery = require("@tanstack/react-query");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _format = _interopRequireDefault(require("date-fns/format"));
var _formatISO = _interopRequireDefault(require("date-fns/formatISO"));
var _isThisMonth = _interopRequireDefault(require("date-fns/isThisMonth"));
var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));
var _isEqual = _interopRequireDefault(require("lodash/isEqual"));
var _router = require("next/router");
var _react2 = _interopRequireWildcard(require("react"));
var _AmendPayment = _interopRequireDefault(require("../AmendPayment"));
var _BookingSummary = require("../BookingSummary");
var _RoomsExtrasSelection = _interopRequireDefault(require("../RoomsExtrasSelection"));
var _RoomsMealSelection = _interopRequireDefault(require("../RoomsMealSelection"));
var _constants = require("../constants");
var _helpers = require("../helpers");
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function AmendContainer(_ref) {
  var _headerInformationDat, _bookingConfirmation, _bookingConfirmation2, _temporaryBookingData, _bookingConfirmation3, _temporaryBookingData2, _temporaryBookingData3, _bookingConfirmation4, _firstReservation$roo, _firstReservation$roo2, _bookingConfirmation5, _firstReservation$roo3, _firstReservation$roo4, _mealPackagesData$roo, _mealPackagesData$roo2, _bookingConfirmation6, _data$hotelInformatio, _paymentProps$selecte, _paymentProps$selecte2, _headerInformationDat2, _headerInformationDat3, _headerInformationDat4, _headerInformationDat5, _headerInformationDat6, _headerInformationDat7, _headerInformationDat8, _headerInformationDat9, _headerInformationDat10, _headerInformationDat11, _headerInformationDat12, _headerInformationDat13, _headerInformationDat14, _headerInformationDat15, _headerInformationDat16, _headerInformationDat17, _headerInformationDat18, _headerInformationDat19, _headerInformationDat20, _headerInformationDat21, _temporaryBookingConf, _headerInformationDat22, _headerInformationDat23, _headerInformationDat24, _paymentProps$selecte3, _getRoomsPackages, _mealPackagesData$ext, _mealPackagesData$ext2, _mealPackagesData$ext3;
  var {
    language,
    country,
    t,
    basketReference,
    bookingReference,
    temporaryBasketReference,
    channel,
    data,
    variant,
    paymentProps,
    amendVisited
  } = _ref;
  var router = (0, _router.useRouter)();
  var baseDataTestId = 'amend';
  var queryClient = (0, _reactQuery.useQueryClient)();
  var {
    bookingConfirmationData,
    headerInformationData,
    stayRulesData,
    employeeStayRulesData,
    RoomOccupancyLimitationsData,
    amendStayDates,
    addNewRoomMutation,
    addNewRoomIsSuccess,
    addNewRoomIsLoading,
    amendEditRoom,
    removeRoom,
    saveReservation,
    confirmAmend,
    brand
  } = data;
  var {
    amendStayDatesMutation,
    amendStayDatesIsError,
    amendStayDatesIsSuccess,
    amendStayDatesIsLoading
  } = amendStayDates;
  var [userHasMadeChanges, setUserHasMadeChanges] = (0, _react2.useState)(false);
  var [mealsSectionHasUpdates, setMealsSectionHasUpdates] = (0, _react2.useState)(false);
  var [tempAmendData, setTempAmendData] = (0, _react2.useState)('');
  var {
    confirmAmendMutation,
    confirmAmendIsLoading,
    confirmAmendIsError,
    confirmAmendIsSuccess
  } = confirmAmend;
  var {
    bookingSummaryLabels,
    summaryOfPaymentsLabels: _summaryOfPaymentsLabels,
    notificationLabels,
    removeRoomModalLabels,
    stayDatesLabels: _stayDatesLabels,
    leadGuestValidationLabels,
    leadGuestLabels,
    roomAvailabilityLabels
  } = (0, _utils.getAmendSectionTranslations)(t);
  var {
    amendEditRoomMutation,
    amendEditRoomIsSuccess,
    amendEditRoomIsLoading
  } = amendEditRoom;
  var {
    amendSaveReservationMutation,
    amendSaveReservationIsLoading,
    amendSaveReservationIsSuccess
  } = saveReservation;
  var {
    removeRoomIsSuccess,
    removeRoomIsLoading,
    removeRoomMutation
  } = removeRoom;
  var isAdultsDecreased = (0, _react2.useRef)(false);
  var currentRoom = (0, _react2.useRef)(0);
  var removedRoomNumber = (0, _react2.useRef)(0);
  var intervalRef = (0, _react2.useRef)();
  var isWindowDefined = typeof window !== 'undefined';
  var [origin, setOrigin] = (0, _react2.useState)('');
  var [email, setEmail] = (0, _react2.useState)('');
  var [, setConfirmAmendErrorValue] = (0, _utils.useSessionStorage)(_api.INITIAL_CONFIRM_AMEND_ERROR_KEY, _api.INITIAL_CONFIRM_AMEND_ERROR_VALUE);
  var [, setAmend3cpVisited] = (0, _utils.useSessionStorage)(_api.AMEND_3CP_VISITED_KEY, _api.AMEND_3CP_VISITED_INITIAL_VALUE);
  var isAmendPayNowEnabled = (0, _utils.useFeatureSwitch)({
    featureSwitchKey: _api.FS_ENABLE_AMEND_PAY_NOW_PI_BB,
    fallbackValue: true
  });
  var isAmendPaymentPageEnabled = (0, _utils.useFeatureSwitch)({
    featureSwitchKey: _api.FS_SHOW_AMEND_PAYMENT_PAGE,
    fallbackValue: true
  });
  var amendConfirmationCommonURL = "/amend/booking-confirmation?bookingReference=".concat(bookingReference);
  var amendConfirmationURL = variant === _api.Area.BB ? "/".concat(country, "/").concat(language, "/business-booker").concat(amendConfirmationCommonURL) : "/".concat(country, "/").concat(language).concat(amendConfirmationCommonURL);
  var discountRateReservationData = (0, _utils.useGetDiscountRateReservationData)(headerInformationData === null || headerInformationData === void 0 || (_headerInformationDat = headerInformationData.headerInformation) === null || _headerInformationDat === void 0 || (_headerInformationDat = _headerInformationDat.content) === null || _headerInformationDat === void 0 || (_headerInformationDat = _headerInformationDat.global) === null || _headerInformationDat === void 0 ? void 0 : _headerInformationDat.offers, bookingConfirmationData, variant);
  (0, _react2.useEffect)(() => {
    setOrigin(window.location.origin);
  }, [isWindowDefined]);
  var paramsForBookingConfirmation = {
    basketReference: temporaryBasketReference,
    country,
    language
  };
  var paramsForBookingChannel = {
    channel,
    subchannel: _constants.SUBCHANNEL,
    language
  };
  var paramsForSummaryOfPayments = {
    originalBasketRef: basketReference,
    copyBasketRef: temporaryBasketReference,
    token: (0, _utils.formatFindBookingToken)((0, _utils.getFindBookingToken)().token),
    bookingChannel: paramsForBookingChannel,
    country: country
  };
  var {
    data: temporaryBookingData,
    isError: temporaryBookingIsError,
    isFetching: temporaryBookingIsFetching,
    error: temporaryBookingError
  } = (0, _utils.useQueryRequest)(['getBookingConfirmationAmend', temporaryBasketReference, country, language], _api.GET_BOOKING_CONFIRMATION_AMEND, paramsForBookingConfirmation, {
    enabled: !!temporaryBasketReference,
    placeholderData: _reactQuery.keepPreviousData
  });
  var {
    data: summaryOfPaymentsData,
    isLoading: summaryOfPaymentsIsLoading,
    isError: summaryOfPaymentsIsError,
    error: summaryOfPaymentsError,
    isFetching: summaryOfPaymentsIsFetching
  } = (0, _utils.useQueryRequest)(['AmendSummary', paramsForSummaryOfPayments], _api.GET_SUMMARY_OF_PAYMENTS, paramsForSummaryOfPayments, {
    enabled: !!temporaryBasketReference
  });
  if (!summaryOfPaymentsIsLoading) {
    var _summaryOfPaymentsDat, _summaryOfPaymentsDat2, _summaryOfPaymentsDat3, _summaryOfPaymentsDat4;
    (0, _utils.updateAmendPageAnalytics)(_objectSpread(_objectSpread({}, window.analyticsData), {}, {
      revenue: summaryOfPaymentsData === null || summaryOfPaymentsData === void 0 || (_summaryOfPaymentsDat = summaryOfPaymentsData.amendSummary) === null || _summaryOfPaymentsDat === void 0 ? void 0 : _summaryOfPaymentsDat.totalCost,
      extrasRevenueChange: summaryOfPaymentsData === null || summaryOfPaymentsData === void 0 || (_summaryOfPaymentsDat2 = summaryOfPaymentsData.amendSummary) === null || _summaryOfPaymentsDat2 === void 0 ? void 0 : _summaryOfPaymentsDat2.charitable,
      totalRevenueChange: (summaryOfPaymentsData === null || summaryOfPaymentsData === void 0 || (_summaryOfPaymentsDat3 = summaryOfPaymentsData.amendSummary) === null || _summaryOfPaymentsDat3 === void 0 ? void 0 : _summaryOfPaymentsDat3.totalCost) - (summaryOfPaymentsData === null || summaryOfPaymentsData === void 0 || (_summaryOfPaymentsDat4 = summaryOfPaymentsData.amendSummary) === null || _summaryOfPaymentsDat4 === void 0 ? void 0 : _summaryOfPaymentsDat4.previousTotal)
    }), bookingReference);
  }
  (0, _react2.useEffect)(() => {
    (0, _utils.updateAmendPageAnalytics)(_objectSpread(_objectSpread({}, window.analyticsData), {}, {
      change: '',
      foodRevenueChange: 0,
      nightsChange: 0,
      roomTypeChange: false,
      roomsChange: 0
    }), bookingReference);
  }, []);
  (0, _react2.useEffect)(() => {
    return () => {
      var _intervalRef$current;
      clearInterval((_intervalRef$current = intervalRef.current) !== null && _intervalRef$current !== void 0 ? _intervalRef$current : '');
    };
  }, []);
  (0, _react2.useEffect)(() => {
    var removeTempBasketFromStorage = () => {
      sessionStorage.removeItem(_api.TEMPORARY_BASKET_KEY);
    };
    router.events.on('routeChangeStart', removeTempBasketFromStorage);
    return () => {
      router.events.off('routeChangeStart', removeTempBasketFromStorage);
    };
  }, []);
  var {
    data: manageBookingData,
    isError: manageBookingIsError,
    isLoading: manageBookingIsLoading,
    error: manageBookingError
  } = (0, _utils.useQueryRequest)(['manageBookingDashBoard', basketReference, temporaryBookingData === null || temporaryBookingData === void 0 || (_bookingConfirmation = temporaryBookingData.bookingConfirmation) === null || _bookingConfirmation === void 0 ? void 0 : _bookingConfirmation.hotelId], _api.DASHBOARD_MANAGE_BOOKING, {
    cancelInformationCriteria: {
      userDateTime: (0, _formatISO.default)(Date.now()),
      bookingChannel: {
        channel: channel,
        subchannel: _api.BOOKING_SUBCHANNEL.WEB,
        language: language
      },
      basketReference: basketReference,
      hotelId: temporaryBookingData === null || temporaryBookingData === void 0 || (_bookingConfirmation2 = temporaryBookingData.bookingConfirmation) === null || _bookingConfirmation2 === void 0 ? void 0 : _bookingConfirmation2.hotelId,
      token: (0, _utils.formatFindBookingToken)((0, _utils.getFindBookingToken)().token)
    }
  }, {
    enabled: !!(temporaryBookingData !== null && temporaryBookingData !== void 0 && (_temporaryBookingData = temporaryBookingData.bookingConfirmation) !== null && _temporaryBookingData !== void 0 && _temporaryBookingData.hotelId) && !!basketReference,
    staleTime: 0,
    cacheTime: 0
  }, (0, _utils.getAuthCookie)());
  var [firstReservation] = (temporaryBookingData === null || temporaryBookingData === void 0 || (_bookingConfirmation3 = temporaryBookingData.bookingConfirmation) === null || _bookingConfirmation3 === void 0 ? void 0 : _bookingConfirmation3.reservationByIdList) || [];
  var {
    isLoading: packagesIsLoading,
    error: packagesError,
    isError: packagesIsError,
    packages: mealPackagesData,
    restaurant: restaurantData,
    privacyPolicy: privacyPolicyPackData
  } = (0, _utils.usePackages)({
    adultsNumber: (0, _utils.getMaxValueFromRoomStays)(temporaryBookingData === null || temporaryBookingData === void 0 || (_temporaryBookingData2 = temporaryBookingData.bookingConfirmation) === null || _temporaryBookingData2 === void 0 ? void 0 : _temporaryBookingData2.reservationByIdList, 'adultsNumber'),
    childrenNumber: (0, _utils.getMaxValueFromRoomStays)(temporaryBookingData === null || temporaryBookingData === void 0 || (_temporaryBookingData3 = temporaryBookingData.bookingConfirmation) === null || _temporaryBookingData3 === void 0 ? void 0 : _temporaryBookingData3.reservationByIdList, 'childrenNumber'),
    hotelId: temporaryBookingData === null || temporaryBookingData === void 0 || (_bookingConfirmation4 = temporaryBookingData.bookingConfirmation) === null || _bookingConfirmation4 === void 0 ? void 0 : _bookingConfirmation4.hotelId,
    basketReferenceId: temporaryBasketReference,
    endDate: firstReservation === null || firstReservation === void 0 || (_firstReservation$roo = firstReservation.roomStay) === null || _firstReservation$roo === void 0 ? void 0 : _firstReservation$roo.departureDate,
    startDate: firstReservation === null || firstReservation === void 0 || (_firstReservation$roo2 = firstReservation.roomStay) === null || _firstReservation$roo2 === void 0 ? void 0 : _firstReservation$roo2.arrivalDate,
    bookingFlowId: temporaryBookingData === null || temporaryBookingData === void 0 || (_bookingConfirmation5 = temporaryBookingData.bookingConfirmation) === null || _bookingConfirmation5 === void 0 ? void 0 : _bookingConfirmation5.bookingFlowId,
    nightsNumber: (0, _utils.getNightsNumber)(firstReservation === null || firstReservation === void 0 || (_firstReservation$roo3 = firstReservation.roomStay) === null || _firstReservation$roo3 === void 0 ? void 0 : _firstReservation$roo3.arrivalDate, firstReservation === null || firstReservation === void 0 || (_firstReservation$roo4 = firstReservation.roomStay) === null || _firstReservation$roo4 === void 0 ? void 0 : _firstReservation$roo4.departureDate),
    channel: channel,
    options: {
      enabled: !!temporaryBasketReference && !!temporaryBookingData,
      placeholderData: _reactQuery.keepPreviousData
    }
  });
  var hasAncillariesWifiSelected = mealPackagesData === null || mealPackagesData === void 0 || (_mealPackagesData$roo = mealPackagesData.roomSelection) === null || _mealPackagesData$roo === void 0 ? void 0 : _mealPackagesData$roo.some(selection => {
    var _selection$packagesSe;
    return (_selection$packagesSe = selection.packagesSelection) === null || _selection$packagesSe === void 0 ? void 0 : _selection$packagesSe.some(packageSelection => packageSelection.id === _api.ExtrasId.ULTIMATE_WIFI);
  });
  var isPageLoaded = !!temporaryBasketReference;
  var isStayDatesLoading = amendStayDatesIsLoading || temporaryBookingIsFetching;
  var isAddNewRoomLoading = addNewRoomIsLoading || temporaryBookingIsFetching;
  var isEditRoomLoading = amendEditRoomIsLoading || temporaryBookingIsFetching;
  var isRemoveRoomLoading = removeRoomIsLoading || temporaryBookingIsFetching;
  var isRoomLoading = isAddNewRoomLoading || isEditRoomLoading || isRemoveRoomLoading;
  var isSaveReservationLoading = amendSaveReservationIsLoading || temporaryBookingIsFetching;
  var isAccordionLoading = isStayDatesLoading || isRoomLoading || isSaveReservationLoading;
  var [isAddOrRemoveMealLoading, setIsAddOrRemoveMealLoading] = (0, _react2.useState)(false);
  var shouldRenderRoomsSection = !temporaryBookingIsFetching && !addNewRoomIsLoading && !amendEditRoomIsLoading && !isRemoveRoomLoading && !manageBookingIsLoading;
  var searchParams = new URLSearchParams(document.location.search);
  var shouldRetryPayment = searchParams.get('status') === _api.CONFIRM_AMEND_STATUS.paymentError;
  var shouldDisplayAddRoomNotification = !temporaryBookingIsFetching && addNewRoomIsSuccess;
  var shouldDisplayEditRoomNotification = !temporaryBookingIsFetching && amendEditRoomIsSuccess;
  var shouldDisplayRemoveRoomNotification = !temporaryBookingIsFetching && removeRoomIsSuccess;
  var shouldDisplaySaveReservationNotification = !temporaryBookingIsFetching && amendSaveReservationIsSuccess;
  var [newMeals, setNewMeals] = (0, _react2.useState)();
  var [prevMeals, setPrevMeals] = (0, _react2.useState)();
  var [mealsNotification, setMealsNotification] = (0, _react2.useState)('');
  var saveRoomsMealsDebounced = (0, _utils.useDebounce)(saveRoomsMeals, 1500);
  var [iframeContent, setIframeContent] = (0, _react2.useState)('');
  var {
    isPaymentComplete,
    cardType
  } = (0, _utils.useIPageSubmission)();
  (0, _react2.useEffect)(() => {
    if (!isSaveReservationLoading) {
      setIsAddOrRemoveMealLoading(false);
    }
  }, [isSaveReservationLoading]);
  (0, _react2.useEffect)(() => {
    if (isPaymentComplete) {
      window.__satelliteLoaded && window._satellite.track(_constants.ANALYTICS_TRACKING_AMEND_CONFIRMATION);
      router.push("".concat(amendConfirmationURL, "&status=").concat(_api.CONFIRM_AMEND_STATUS.payment, "&tempBookingReference=").concat(temporaryBasketReference, "&basketReference=").concat(basketReference));
    }
  }, [isPaymentComplete]);
  (0, _react2.useEffect)(() => {
    if (cardType) {
      _utils.analytics.update({
        cardType
      });
      _utils.analyticsConfirmation.update({
        cardType
      });
    }
  }, [cardType]);
  var amendPaymentContainer = document.getElementById('amend-payment-container');
  (0, _react2.useEffect)(() => {
    if (shouldRetryPayment && amendPaymentContainer) {
      window.requestAnimationFrame(() => amendPaymentContainer.scrollIntoView({
        block: 'start',
        behavior: 'smooth'
      }));
    }
  }, [amendPaymentContainer, shouldRetryPayment]);
  var {
    [_api.FT_PI_AMEND_DELETE_REG_CARD]: isDeleteRegCardEnabled,
    [_api.FT_PI_BB_CCUI_MAXROOMS_AMEND]: isAmendMaxRoomsEnabled
  } = (0, _utils.useFeatureToggle)();
  (0, _react2.useEffect)(() => {
    if (isPageLoaded && isDeleteRegCardEnabled && temporaryBookingData !== null && temporaryBookingData !== void 0 && temporaryBookingData.bookingConfirmation && temporaryBookingData.bookingConfirmation.reservationByIdList.length === bookingConfirmationData.reservationByIdList.length && tempAmendData === '') {
      var clonedBookingData = (0, _cloneDeep.default)(temporaryBookingData);
      var list = clonedBookingData.bookingConfirmation.reservationByIdList.map(room => {
        var matchingRoom = bookingConfirmationData.reservationByIdList.find(orgRoom => orgRoom.reservationGuestList[0].givenName === room.reservationGuestList[0].givenName && orgRoom.reservationGuestList[0].email === room.reservationGuestList[0].email);
        return _objectSpread(_objectSpread({}, room), {}, {
          originalReservationId: matchingRoom === null || matchingRoom === void 0 ? void 0 : matchingRoom.reservationId,
          preCheckInStatus: matchingRoom === null || matchingRoom === void 0 ? void 0 : matchingRoom.preCheckInStatus
        });
      });
      setTempAmendData(_objectSpread(_objectSpread({}, clonedBookingData), {}, {
        bookingConfirmation: _objectSpread(_objectSpread({}, clonedBookingData.bookingConfirmation), {}, {
          reservationByIdList: list
        })
      }));
    }
  }, [isPageLoaded, temporaryBookingData, tempAmendData, isDeleteRegCardEnabled]);
  var handleOnAddMeal = (0, _react2.useCallback)(mealId => {
    if (!saveMealsAllAtOnce) {
      var _mealPackagesData$mea, _window$analyticsData, _window;
      setIsAddOrRemoveMealLoading(true);
      setMealsNotification(t('amend.mealAdded'));
      var selectedMeal = mealPackagesData === null || mealPackagesData === void 0 || (_mealPackagesData$mea = mealPackagesData.meals) === null || _mealPackagesData$mea === void 0 ? void 0 : _mealPackagesData$mea.find(item => item.id === mealId);
      var analyticsChange = selectedMeal && "1 ".concat(selectedMeal.name, " has been added");
      var foodRevenueChange = (_window$analyticsData = (_window = window) === null || _window === void 0 || (_window = _window.analyticsData) === null || _window === void 0 ? void 0 : _window.foodRevenueChange) !== null && _window$analyticsData !== void 0 ? _window$analyticsData : 0;
      editAmendPageAnalytics(analyticsChange, {
        foodRevenueChange: selectedMeal && foodRevenueChange + Number(selectedMeal.price)
      });
      amendSaveReservationMutation.reset();
    }
  }, [mealsNotification, amendSaveReservationMutation]);
  var handleOnRemoveMeal = (0, _react2.useCallback)(mealId => {
    if (!saveMealsAllAtOnce) {
      var _mealPackagesData$mea2, _window$analyticsData2, _window2;
      setIsAddOrRemoveMealLoading(true);
      setMealsNotification(t('amend.mealRemoved'));
      var selectedMeal = mealPackagesData === null || mealPackagesData === void 0 || (_mealPackagesData$mea2 = mealPackagesData.meals) === null || _mealPackagesData$mea2 === void 0 ? void 0 : _mealPackagesData$mea2.find(item => item.id === mealId);
      var analyticsChange = selectedMeal && "1 ".concat(selectedMeal.name, " has been removed");
      var foodRevenueChange = (_window$analyticsData2 = (_window2 = window) === null || _window2 === void 0 || (_window2 = _window2.analyticsData) === null || _window2 === void 0 ? void 0 : _window2.foodRevenueChange) !== null && _window$analyticsData2 !== void 0 ? _window$analyticsData2 : 0;
      editAmendPageAnalytics(analyticsChange, {
        foodRevenueChange: selectedMeal && foodRevenueChange - Number(selectedMeal.price)
      });
      amendSaveReservationMutation.reset();
    }
  }, [mealsNotification, amendSaveReservationMutation]);
  var onRoomsMealSelect = (prevRoomsSelection, roomsSelections, selectedRoom) => {
    setNewMeals(roomsSelections);
    setPrevMeals((prevRoomsSelection === null || prevRoomsSelection === void 0 ? void 0 : prevRoomsSelection.length) === 0 ? preselectedMeals : prevRoomsSelection);
    currentRoom.current = selectedRoom;
    saveRoomsMealsDebounced();
    if (saveMealsAllAtOnce) {
      setMealsNotification(t('ccui.amend.mealsChangesApplied'));
      updateAnalyticsForAllMeals(roomsSelections, preselectedMeals);
    }
  };
  var updateAnalyticsForAllMeals = (roomsSelections, preselectedMeals) => {
    var oldMealIds = preselectedMeals.reduce((prev, cur) => {
      prev.push(...cur.adults);
      return prev;
    }, []);
    var newMealIds = roomsSelections.reduce((prev, cur) => {
      prev.push(...cur.adults);
      return prev;
    }, []);
    var addedMeals = newMealIds.filter(id => !oldMealIds.includes(id)).map(id => ({
      isAdded: true,
      id
    }));
    var removedMeals = oldMealIds.filter(id => !newMealIds.includes(id)).map(id => ({
      isAdded: false,
      id
    }));
    [...addedMeals, ...removedMeals].forEach(meal => {
      var _window$analyticsData3, _window3, _mealPackagesData$mea3;
      var foodRevenueChange = (_window$analyticsData3 = (_window3 = window) === null || _window3 === void 0 || (_window3 = _window3.analyticsData) === null || _window3 === void 0 ? void 0 : _window3.foodRevenueChange) !== null && _window$analyticsData3 !== void 0 ? _window$analyticsData3 : 0;
      var selectedMeal = mealPackagesData === null || mealPackagesData === void 0 || (_mealPackagesData$mea3 = mealPackagesData.meals) === null || _mealPackagesData$mea3 === void 0 ? void 0 : _mealPackagesData$mea3.find(item => item.id === meal.id);
      var analyticsChange = selectedMeal && "1 ".concat(selectedMeal.name, " has been ").concat(meal.isAdded ? 'added' : 'removed');
      selectedMeal && editAmendPageAnalytics(analyticsChange, {
        foodRevenueChange: !packagesIsLoading && meal.isAdded ? foodRevenueChange + Number(selectedMeal.price) : foodRevenueChange - Number(selectedMeal.price)
      });
    });
  };
  var {
    meals,
    mealsKids
  } = mealPackagesData;
  var sortedMeals = [];
  var preselectedMeals = [];
  var selectedMeals = [];
  if (mealPackagesData !== null && mealPackagesData !== void 0 && (_mealPackagesData$roo2 = mealPackagesData.roomSelection) !== null && _mealPackagesData$roo2 !== void 0 && _mealPackagesData$roo2.length && temporaryBookingData !== null && temporaryBookingData !== void 0 && (_bookingConfirmation6 = temporaryBookingData.bookingConfirmation) !== null && _bookingConfirmation6 !== void 0 && (_bookingConfirmation6 = _bookingConfirmation6.reservationByIdList) !== null && _bookingConfirmation6 !== void 0 && _bookingConfirmation6.length) {
    var _bookingConfirmation7;
    sortedMeals = (0, _utils.sortMealsByReservationId)(mealPackagesData.roomSelection, temporaryBookingData === null || temporaryBookingData === void 0 || (_bookingConfirmation7 = temporaryBookingData.bookingConfirmation) === null || _bookingConfirmation7 === void 0 ? void 0 : _bookingConfirmation7.reservationByIdList);
  }
  if (sortedMeals.length && meals && mealsKids) {
    var previousSelectedMeals = (0, _utils.mealsMapperSelector)(meals, mealsKids, sortedMeals);
    selectedMeals = previousSelectedMeals;
    preselectedMeals = previousSelectedMeals;
  }
  if (!isPageLoaded || !temporaryBookingData || summaryOfPaymentsIsLoading || manageBookingIsLoading || packagesIsLoading) {
    return (0, _jsxRuntime.jsx)(_molecules.PageLoader, {
      text: t('searchresults.list.hotel.loading')
    });
  }
  if (confirmAmendIsLoading || confirmAmendIsError || confirmAmendIsSuccess && !iframeContent) {
    return (0, _jsxRuntime.jsx)(_molecules.PageLoader, {
      text: t('booking.loading')
    });
  }
  if (temporaryBookingIsError) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'error-temporary-booking-confirmation'),
      children: temporaryBookingError.message
    });
  }
  if (summaryOfPaymentsIsError) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'error-summary-of-payments'),
      children: summaryOfPaymentsError.message
    });
  }
  if (manageBookingIsError) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'error-manage-booking'),
      children: manageBookingError.message
    });
  }
  if (packagesIsError) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'error-meal-packages'),
      children: packagesError.message
    });
  }
  var temporaryBookingConfirmation = temporaryBookingData.bookingConfirmation;
  var temporaryFirstReservation = temporaryBookingConfirmation.reservationByIdList[0];
  var originalFirstReservation = bookingConfirmationData.reservationByIdList[0];
  var saveMealsAllAtOnce = variant === _api.Area.CCUI && (temporaryBookingConfirmation === null || temporaryBookingConfirmation === void 0 ? void 0 : temporaryBookingConfirmation.reservationByIdList.length) > 1;
  var privacyPolicyObj = {
    name: privacyPolicyPackData === null || privacyPolicyPackData === void 0 ? void 0 : privacyPolicyPackData.name,
    moreInfoLabel: privacyPolicyPackData === null || privacyPolicyPackData === void 0 ? void 0 : privacyPolicyPackData.moreInfoLabel,
    linkSrc: privacyPolicyPackData === null || privacyPolicyPackData === void 0 ? void 0 : privacyPolicyPackData.linkSrc,
    linkLabel: privacyPolicyPackData === null || privacyPolicyPackData === void 0 ? void 0 : privacyPolicyPackData.linkLabel,
    description: privacyPolicyPackData === null || privacyPolicyPackData === void 0 ? void 0 : privacyPolicyPackData.description,
    moreInfo: privacyPolicyPackData === null || privacyPolicyPackData === void 0 ? void 0 : privacyPolicyPackData.moreInfo
  };
  var privacyPolicyData = (0, _utils.securityNoticeMoreInfoDataSelector)(privacyPolicyObj);
  var hotelCountry = data === null || data === void 0 || (_data$hotelInformatio = data.hotelInformation) === null || _data$hotelInformatio === void 0 || (_data$hotelInformatio = _data$hotelInformatio.hotelInformation) === null || _data$hotelInformatio === void 0 || (_data$hotelInformatio = _data$hotelInformatio.address) === null || _data$hotelInformatio === void 0 ? void 0 : _data$hotelInformatio.country;
  var temporaryArrivalDate = temporaryFirstReservation.roomStay.arrivalDate;
  var temporaryDepartureDate = temporaryFirstReservation.roomStay.departureDate;
  var originalArrivalDate = originalFirstReservation.roomStay.arrivalDate;
  var originalDepartureDate = originalFirstReservation.roomStay.departureDate;
  var originalNumberOfNights = (0, _utils.getNightsNumber)(originalArrivalDate, originalDepartureDate);
  var temporaryNumberOfNights = (0, _utils.getNightsNumber)(temporaryArrivalDate, temporaryDepartureDate);
  var hasEmployeeOfferReservation = bookingConfirmationData.reservationByIdList.some(room => room.roomStay.ratePlanCode === _api.OfferEnum.EMPLOYEE_RATE_CODE);
  var getMaxRooms = (hasEmployeeOfferReservation, discountRateReservationData) => {
    var _employeeStayRulesDat;
    if (discountRateReservationData !== null && discountRateReservationData !== void 0 && discountRateReservationData.isDiscountRate) {
      return discountRateReservationData === null || discountRateReservationData === void 0 ? void 0 : discountRateReservationData.maxRooms;
    }
    if (hasEmployeeOfferReservation && (employeeStayRulesData === null || employeeStayRulesData === void 0 || (_employeeStayRulesDat = employeeStayRulesData.maxRoomsLimitation) === null || _employeeStayRulesDat === void 0 ? void 0 : _employeeStayRulesDat.maxRooms) !== undefined) {
      var _employeeStayRulesDat2, _employeeStayRulesDat3;
      return isAmendMaxRoomsEnabled ? employeeStayRulesData === null || employeeStayRulesData === void 0 || (_employeeStayRulesDat2 = employeeStayRulesData.maxRoomsLimitation) === null || _employeeStayRulesDat2 === void 0 ? void 0 : _employeeStayRulesDat2.maxRoomsAmend : employeeStayRulesData === null || employeeStayRulesData === void 0 || (_employeeStayRulesDat3 = employeeStayRulesData.maxRoomsLimitation) === null || _employeeStayRulesDat3 === void 0 ? void 0 : _employeeStayRulesDat3.maxRooms;
    }
    return isAmendMaxRoomsEnabled ? stayRulesData === null || stayRulesData === void 0 ? void 0 : stayRulesData.maxRoomsLimitation.maxRoomsAmend : stayRulesData === null || stayRulesData === void 0 ? void 0 : stayRulesData.maxRoomsLimitation.maxRooms;
  };
  var stayDatesData = {
    hotelName: temporaryBookingConfirmation.hotelName,
    arrivalDate: new Date(temporaryArrivalDate),
    departureDate: new Date(temporaryDepartureDate),
    maxNights: stayRulesData === null || stayRulesData === void 0 ? void 0 : stayRulesData.maxNightsLimitation.maxNights,
    maxRooms: getMaxRooms(hasEmployeeOfferReservation, discountRateReservationData),
    maxArrivalDate: stayRulesData === null || stayRulesData === void 0 ? void 0 : stayRulesData.maxArrivalDateLimitation.maxArrivalDate,
    originalArrivalDate: new Date(originalArrivalDate)
  };
  var roomAndGuestsData = {
    reservations: temporaryBookingConfirmation.reservationByIdList,
    currencyCode: temporaryBookingConfirmation.currencyCode
  };
  var hotelAvailabilityParams = {
    arrival: (0, _format.default)(stayDatesData.arrivalDate, _api.DATE_TYPE.YEAR_MONTH_DAY),
    bookingChannel: {
      channel: channel,
      language: language,
      subchannel: _constants.SUBCHANNEL
    },
    channel: temporaryBookingConfirmation === null || temporaryBookingConfirmation === void 0 ? void 0 : temporaryBookingConfirmation.channel,
    companyId: temporaryBookingConfirmation === null || temporaryBookingConfirmation === void 0 ? void 0 : temporaryBookingConfirmation.companyId,
    country: country,
    departure: (0, _format.default)(stayDatesData.departureDate, _api.DATE_TYPE.YEAR_MONTH_DAY),
    hotelId: temporaryBookingConfirmation === null || temporaryBookingConfirmation === void 0 ? void 0 : temporaryBookingConfirmation.hotelId,
    language: language,
    rooms: [],
    rateCode: temporaryBookingConfirmation === null || temporaryBookingConfirmation === void 0 ? void 0 : temporaryBookingConfirmation.reservationByIdList[0].roomStay.ratePlanCode,
    ratePlanCodes: hasEmployeeOfferReservation && variant === _api.Area.PI ? [_api.OfferEnum.EMPLOYEE] : [temporaryBookingConfirmation === null || temporaryBookingConfirmation === void 0 ? void 0 : temporaryBookingConfirmation.reservationByIdList[0].roomStay.ratePlanCode]
  };
  var selectedPaymentMethod = (_paymentProps$selecte = paymentProps === null || paymentProps === void 0 || (_paymentProps$selecte2 = paymentProps.selectedPaymentDetail) === null || _paymentProps$selecte2 === void 0 ? void 0 : _paymentProps$selecte2.type) !== null && _paymentProps$selecte !== void 0 ? _paymentProps$selecte : _constants.PAY_ON_ARRIVAL;
  var additionalAmountLabel = selectedPaymentMethod === _constants.PAY_ON_ARRIVAL ? t('amend.balance.poaNew') : t('amend.balance.pnNew');
  var stayDatesLabels = _objectSpread(_objectSpread({}, _stayDatesLabels), {}, {
    invalidNights: headerInformationData.headerInformation.form.invalidNights
  });
  var roomsAndGuestsLabels = {
    roomModalLabels: {
      roomDropdownLabels: {
        single: (_headerInformationDat2 = headerInformationData === null || headerInformationData === void 0 || (_headerInformationDat3 = headerInformationData.headerInformation) === null || _headerInformationDat3 === void 0 || (_headerInformationDat3 = _headerInformationDat3.content) === null || _headerInformationDat3 === void 0 || (_headerInformationDat3 = _headerInformationDat3.global) === null || _headerInformationDat3 === void 0 ? void 0 : _headerInformationDat3.single) !== null && _headerInformationDat2 !== void 0 ? _headerInformationDat2 : '',
        double: (_headerInformationDat4 = headerInformationData === null || headerInformationData === void 0 || (_headerInformationDat5 = headerInformationData.headerInformation) === null || _headerInformationDat5 === void 0 || (_headerInformationDat5 = _headerInformationDat5.content) === null || _headerInformationDat5 === void 0 || (_headerInformationDat5 = _headerInformationDat5.global) === null || _headerInformationDat5 === void 0 ? void 0 : _headerInformationDat5.double) !== null && _headerInformationDat4 !== void 0 ? _headerInformationDat4 : '',
        accessible: (_headerInformationDat6 = headerInformationData === null || headerInformationData === void 0 || (_headerInformationDat7 = headerInformationData.headerInformation) === null || _headerInformationDat7 === void 0 || (_headerInformationDat7 = _headerInformationDat7.content) === null || _headerInformationDat7 === void 0 || (_headerInformationDat7 = _headerInformationDat7.global) === null || _headerInformationDat7 === void 0 ? void 0 : _headerInformationDat7.accessible) !== null && _headerInformationDat6 !== void 0 ? _headerInformationDat6 : '',
        twin: (_headerInformationDat8 = headerInformationData === null || headerInformationData === void 0 || (_headerInformationDat9 = headerInformationData.headerInformation) === null || _headerInformationDat9 === void 0 || (_headerInformationDat9 = _headerInformationDat9.content) === null || _headerInformationDat9 === void 0 || (_headerInformationDat9 = _headerInformationDat9.global) === null || _headerInformationDat9 === void 0 ? void 0 : _headerInformationDat9.twin) !== null && _headerInformationDat8 !== void 0 ? _headerInformationDat8 : '',
        family: (_headerInformationDat10 = headerInformationData === null || headerInformationData === void 0 || (_headerInformationDat11 = headerInformationData.headerInformation) === null || _headerInformationDat11 === void 0 || (_headerInformationDat11 = _headerInformationDat11.content) === null || _headerInformationDat11 === void 0 || (_headerInformationDat11 = _headerInformationDat11.global) === null || _headerInformationDat11 === void 0 ? void 0 : _headerInformationDat11.family) !== null && _headerInformationDat10 !== void 0 ? _headerInformationDat10 : ''
      },
      roomDropdownRoomCodes: headerInformationData.headerInformation.config.roomCodes,
      roomAvailabilityLabels: _objectSpread({
        adult: (_headerInformationDat12 = headerInformationData === null || headerInformationData === void 0 || (_headerInformationDat13 = headerInformationData.headerInformation) === null || _headerInformationDat13 === void 0 || (_headerInformationDat13 = _headerInformationDat13.content) === null || _headerInformationDat13 === void 0 || (_headerInformationDat13 = _headerInformationDat13.global) === null || _headerInformationDat13 === void 0 ? void 0 : _headerInformationDat13.adult) !== null && _headerInformationDat12 !== void 0 ? _headerInformationDat12 : '',
        adults: (_headerInformationDat14 = headerInformationData === null || headerInformationData === void 0 || (_headerInformationDat15 = headerInformationData.headerInformation) === null || _headerInformationDat15 === void 0 || (_headerInformationDat15 = _headerInformationDat15.content) === null || _headerInformationDat15 === void 0 || (_headerInformationDat15 = _headerInformationDat15.global) === null || _headerInformationDat15 === void 0 ? void 0 : _headerInformationDat15.adults) !== null && _headerInformationDat14 !== void 0 ? _headerInformationDat14 : '',
        child: (_headerInformationDat16 = headerInformationData === null || headerInformationData === void 0 || (_headerInformationDat17 = headerInformationData.headerInformation) === null || _headerInformationDat17 === void 0 || (_headerInformationDat17 = _headerInformationDat17.content) === null || _headerInformationDat17 === void 0 || (_headerInformationDat17 = _headerInformationDat17.global) === null || _headerInformationDat17 === void 0 ? void 0 : _headerInformationDat17.child) !== null && _headerInformationDat16 !== void 0 ? _headerInformationDat16 : '',
        children: (_headerInformationDat18 = headerInformationData === null || headerInformationData === void 0 || (_headerInformationDat19 = headerInformationData.headerInformation) === null || _headerInformationDat19 === void 0 || (_headerInformationDat19 = _headerInformationDat19.content) === null || _headerInformationDat19 === void 0 || (_headerInformationDat19 = _headerInformationDat19.global) === null || _headerInformationDat19 === void 0 ? void 0 : _headerInformationDat19.children) !== null && _headerInformationDat18 !== void 0 ? _headerInformationDat18 : ''
      }, roomAvailabilityLabels),
      leadGuestLabels: _objectSpread({}, leadGuestLabels),
      leadGuestValidationLabels: _objectSpread({}, leadGuestValidationLabels),
      notificationLabels: _objectSpread({}, notificationLabels)
    },
    removeRoomModalLabels: _objectSpread({}, removeRoomModalLabels),
    roomLabel: (_headerInformationDat20 = headerInformationData === null || headerInformationData === void 0 || (_headerInformationDat21 = headerInformationData.headerInformation) === null || _headerInformationDat21 === void 0 || (_headerInformationDat21 = _headerInformationDat21.content) === null || _headerInformationDat21 === void 0 || (_headerInformationDat21 = _headerInformationDat21.global) === null || _headerInformationDat21 === void 0 ? void 0 : _headerInformationDat21.roomLabel) !== null && _headerInformationDat20 !== void 0 ? _headerInformationDat20 : '',
    edit: t('amend.edit'),
    remove: t('amend.removeRoom')
  };
  var summaryOfPaymentsLabels = _objectSpread(_objectSpread({}, _summaryOfPaymentsLabels), {}, {
    additionalAmount: additionalAmountLabel
  });
  var roomSuccessfullyAddedLabel = roomsAndGuestsLabels.roomModalLabels.roomAvailabilityLabels.roomSuccessfullyAdded.replace('[number]', "".concat((_temporaryBookingConf = temporaryBookingConfirmation.reservationByIdList) === null || _temporaryBookingConf === void 0 ? void 0 : _temporaryBookingConf.length));
  var roomSuccessfullyUpdatedLabel = roomsAndGuestsLabels.roomModalLabels.roomAvailabilityLabels.roomSuccessfullyUpdated.replace('[number]', "".concat(currentRoom.current + 1));
  var resetMealsAfterEditRoomLabel = roomsAndGuestsLabels.roomModalLabels.notificationLabels.description.replace('[number]', "".concat(currentRoom.current + 1));
  var roomSuccessfullyRemovedLabel = roomsAndGuestsLabels.removeRoomModalLabels.roomSuccessfullRemoved.replace('[number]', "".concat(removedRoomNumber.current));
  var roomsLimitDescriptionNotification = headerInformationData === null || headerInformationData === void 0 || (_headerInformationDat22 = headerInformationData.headerInformation) === null || _headerInformationDat22 === void 0 || (_headerInformationDat22 = _headerInformationDat22.results) === null || _headerInformationDat22 === void 0 || (_headerInformationDat22 = _headerInformationDat22.notifications) === null || _headerInformationDat22 === void 0 ? void 0 : _headerInformationDat22.groupBookingMessage;
  var ccuiRoomsLimitDescriptionNotification = headerInformationData === null || headerInformationData === void 0 || (_headerInformationDat23 = headerInformationData.headerInformation) === null || _headerInformationDat23 === void 0 || (_headerInformationDat23 = _headerInformationDat23.results) === null || _headerInformationDat23 === void 0 || (_headerInformationDat23 = _headerInformationDat23.notifications) === null || _headerInformationDat23 === void 0 ? void 0 : _headerInformationDat23.ccuiGroupBookingMessage;
  var roomsLimitDescriptionEmployeeOfferNotification = headerInformationData === null || headerInformationData === void 0 || (_headerInformationDat24 = headerInformationData.headerInformation) === null || _headerInformationDat24 === void 0 || (_headerInformationDat24 = _headerInformationDat24.results) === null || _headerInformationDat24 === void 0 || (_headerInformationDat24 = _headerInformationDat24.notifications) === null || _headerInformationDat24 === void 0 ? void 0 : _headerInformationDat24.emp01groupBookingMessage;
  var alertIcon = (0, _jsxRuntime.jsx)(_atoms.Alert, {
    color: "var(--chakra-colors-alert)"
  });
  var stayDatesSectionHasUpdates = (0, _helpers.isStayDatesSectionUpdated)(originalArrivalDate, originalDepartureDate, temporaryArrivalDate, temporaryDepartureDate);
  var isPaymentMethodSelected = !!([_api.Area.PI, _api.Area.BB].includes(variant) && paymentProps !== null && paymentProps !== void 0 && (_paymentProps$selecte3 = paymentProps.selectedPaymentDetail) !== null && _paymentProps$selecte3 !== void 0 && _paymentProps$selecte3.type || variant === _api.Area.CCUI);
  var adultsMeals = (0, _utils.adultsMealsSelector)(mealPackagesData === null || mealPackagesData === void 0 ? void 0 : mealPackagesData.meals, temporaryNumberOfNights);
  var showFreeFoodKidsNotification = adultsMeals.some(meal => meal.freeBreakfastOption) && (0, _utils.freeBreakfastMaxAllowance)(adultsMeals) > 0 && (temporaryBookingConfirmation === null || temporaryBookingConfirmation === void 0 ? void 0 : temporaryBookingConfirmation.reservationByIdList.some(room => room.roomStay.childrenNumber > 0));
  var {
    balancePaid,
    payOnArrival,
    previousTotal,
    paymentCardDetails,
    navigationOptions
  } = (summaryOfPaymentsData === null || summaryOfPaymentsData === void 0 ? void 0 : summaryOfPaymentsData.amendSummary) || {};
  var expirationDate = paymentCardDetails === null || paymentCardDetails === void 0 ? void 0 : paymentCardDetails.expirationDate;
  var isPayNow = balancePaid !== 0;
  var isCardAvailable = expirationDate && !(0, _isThisMonth.default)(new Date(expirationDate));
  var shouldDisplayPaymentSection = isAmendPayNowEnabled && isPayNow && isCardAvailable && payOnArrival > 0 && balancePaid + payOnArrival > previousTotal;
  var showEciLcoNotification = (_getRoomsPackages = getRoomsPackages()) === null || _getRoomsPackages === void 0 ? void 0 : _getRoomsPackages.some(room => {
    var _room$selectedExtrasL, _room$selectedExtrasL2;
    return (room === null || room === void 0 || (_room$selectedExtrasL = room.selectedExtrasList) === null || _room$selectedExtrasL === void 0 ? void 0 : _room$selectedExtrasL.packagesSelection) && ((_room$selectedExtrasL2 = room.selectedExtrasList.packagesSelection) === null || _room$selectedExtrasL2 === void 0 ? void 0 : _room$selectedExtrasL2.some(extrasItem => extrasItem.id === _api.ExtrasId.EARLY_CHECK_IN || extrasItem.id === _api.ExtrasId.LATE_CHECK_OUT));
  });
  var extrasItemsPrices = {
    eciPrice: mealPackagesData === null || mealPackagesData === void 0 || (_mealPackagesData$ext = mealPackagesData.extrasItems) === null || _mealPackagesData$ext === void 0 || (_mealPackagesData$ext = _mealPackagesData$ext.find(item => (item === null || item === void 0 ? void 0 : item.id) === _api.ExtrasId.EARLY_CHECK_IN)) === null || _mealPackagesData$ext === void 0 ? void 0 : _mealPackagesData$ext.price,
    lcoPrice: mealPackagesData === null || mealPackagesData === void 0 || (_mealPackagesData$ext2 = mealPackagesData.extrasItems) === null || _mealPackagesData$ext2 === void 0 || (_mealPackagesData$ext2 = _mealPackagesData$ext2.find(item => (item === null || item === void 0 ? void 0 : item.id) === _api.ExtrasId.LATE_CHECK_OUT)) === null || _mealPackagesData$ext2 === void 0 ? void 0 : _mealPackagesData$ext2.price,
    wifiPrice: mealPackagesData === null || mealPackagesData === void 0 || (_mealPackagesData$ext3 = mealPackagesData.extrasItems) === null || _mealPackagesData$ext3 === void 0 || (_mealPackagesData$ext3 = _mealPackagesData$ext3.find(item => (item === null || item === void 0 ? void 0 : item.id) === _api.ExtrasId.ULTIMATE_WIFI)) === null || _mealPackagesData$ext3 === void 0 ? void 0 : _mealPackagesData$ext3.price
  };
  var onIframeLoad = time => {
    _utils.analytics.update({
      paymentLoadTime: time
    });
  };
  var redirectToAmendPaymentCCUI = () => {
    router.push("/".concat(country, "/").concat(language, "/amend/payment?tempBasketReference=").concat(temporaryBasketReference, "&basketReference=").concat(basketReference, "&bookingReference=").concat(bookingReference));
  };
  var isRedirectToAmendPaymentPageEnabled = (navigationOptions === null || navigationOptions === void 0 ? void 0 : navigationOptions.amendPaymentPage) && isAmendPaymentPageEnabled;
  var canConfirmChanges = (userHasMadeChanges || stayDatesSectionHasUpdates || mealsSectionHasUpdates || amendVisited || isRedirectToAmendPaymentPageEnabled) && !isAccordionLoading && !summaryOfPaymentsIsFetching && isPaymentMethodSelected;
  if (iframeContent) {
    return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, amendContainerStyle), {}, {
      children: [(0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, detailsStyle), {}, {
        children: (0, _jsxRuntime.jsx)(_atoms.IframeEmbed, {
          iframeId: "paymentFrame",
          iframeContent: iframeContent,
          onIframeLoad: onIframeLoad
        })
      })), (0, _jsxRuntime.jsx)(_BookingSummary.BookingSummaryWrapper, {
        language: language,
        bookingInformation: temporaryBookingConfirmation,
        roomsPackages: getRoomsPackages(),
        originalArrivalDate: originalArrivalDate,
        originalDepartureDate: originalDepartureDate,
        stayDatesLabels: stayDatesLabels,
        roomsAndGuestsLabels: roomsAndGuestsLabels,
        bookingSummaryLabels: bookingSummaryLabels,
        summaryOfPayments: summaryOfPaymentsData === null || summaryOfPaymentsData === void 0 ? void 0 : summaryOfPaymentsData.amendSummary,
        summaryOfPaymentsLabels: summaryOfPaymentsLabels,
        isConfirmButtonEnabled: canConfirmChanges,
        onConfirmChanges: handleConfirmChanges,
        hideConfirmButton: true,
        isRedirectToAmendPaymentEnabled: isRedirectToAmendPaymentPageEnabled,
        handleRedirectToAmendPayment: redirectToAmendPaymentCCUI,
        variant: variant,
        px: "0",
        setEmailCallback: setEmail,
        showEciLcoNotification: showEciLcoNotification
      })]
    }));
  }
  return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, amendContainerStyle), {}, {
    children: [(0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, detailsStyle), {}, {
      children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, titleStyle), {}, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'page-title'),
        children: t('booking.management.bookingReview.amendYourBookingTitle')
      })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, notificationAlertEciLcoStyle), {}, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Notification-Alert-EciLco'),
        children: showEciLcoNotification && (0, _jsxRuntime.jsx)(_atoms.Notification, {
          status: "warning",
          "data-testid": "notification-alert-ecilco",
          description: (0, _utils.renderSanitizedHtml)(t('ancillaries.amend.notification')),
          variant: "alert",
          svg: (0, _jsxRuntime.jsx)(_atoms.Alert, {})
        })
      })), (0, _jsxRuntime.jsx)(_atoms.Accordion, {
        accordionItems: renderAccordionItems(),
        bgColor: "baseWhite",
        accordionOverwriteStyles: accordionOverwriteStyles,
        allowMultiple: false,
        allowToggle: true
      }), (0, _jsxRuntime.jsx)(_react.Box, {
        mt: "2xl",
        children: shouldDisplayPaymentSection && paymentProps && (0, _jsxRuntime.jsx)(_AmendPayment.default, _objectSpread({
          bookingConfirmation: bookingConfirmationData,
          amendSummary: summaryOfPaymentsData === null || summaryOfPaymentsData === void 0 ? void 0 : summaryOfPaymentsData.amendSummary,
          continueToNextStep: handleConfirmChanges,
          shouldRetryPayment: shouldRetryPayment,
          additionalAmountLabel: additionalAmountLabel,
          temporaryBasketReference: temporaryBasketReference
        }, paymentProps))
      }), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'BackToHomePageButton')
      }, returnButtonWrapper), {}, {
        onClick: handleBackToHomepage,
        children: [(0, _jsxRuntime.jsx)(_atoms.Icon, {
          svg: (0, _jsxRuntime.jsx)(_atoms.ChevronLeft24, {})
        }), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, returnButtonTextStyle), {}, {
          children: variant === _api.Area.CCUI ? t('ccui.amend.backToSearch') : t('amend.anonymousCancelLink')
        }))]
      })), [_api.Area.PI, _api.Area.BB].includes(variant) && (0, _jsxRuntime.jsx)(_molecules.DataSecuritySection, {
        privacyPolicy: privacyPolicyData,
        prefixDataTestId: baseDataTestId,
        containerStyle: containerStyle
      })]
    })), (0, _jsxRuntime.jsx)(_BookingSummary.BookingSummaryWrapper, {
      language: language,
      bookingInformation: temporaryBookingConfirmation,
      roomsPackages: getRoomsPackages(),
      originalArrivalDate: originalArrivalDate,
      originalDepartureDate: originalDepartureDate,
      stayDatesLabels: stayDatesLabels,
      roomsAndGuestsLabels: roomsAndGuestsLabels,
      bookingSummaryLabels: bookingSummaryLabels,
      summaryOfPayments: summaryOfPaymentsData === null || summaryOfPaymentsData === void 0 ? void 0 : summaryOfPaymentsData.amendSummary,
      summaryOfPaymentsLabels: summaryOfPaymentsLabels,
      isConfirmButtonEnabled: canConfirmChanges,
      onConfirmChanges: handleConfirmChanges,
      isRedirectToAmendPaymentEnabled: isRedirectToAmendPaymentPageEnabled,
      handleRedirectToAmendPayment: redirectToAmendPaymentCCUI,
      variant: variant,
      px: "0",
      setEmailCallback: setEmail,
      showEciLcoNotification: showEciLcoNotification,
      extrasItemsPrices: extrasItemsPrices
    })]
  }));
  function renderYourMealsContent() {
    var _data$hotelInformatio2, _firstReservation$roo5, _firstReservation$roo6, _mealPackagesData$mea4, _mealPackagesData$mea5, _restaurantData$logoS;
    if (isAddNewRoomLoading || isEditRoomLoading || isSaveReservationLoading || isAddOrRemoveMealLoading) {
      return (0, _jsxRuntime.jsx)(_atoms.LoadingSpinner, {
        wrapperStyle: loadingSpinnerStyle
      });
    }
    var ancillaryCloseoutData = data === null || data === void 0 || (_data$hotelInformatio2 = data.hotelInformation) === null || _data$hotelInformatio2 === void 0 || (_data$hotelInformatio2 = _data$hotelInformatio2.hotelInformation) === null || _data$hotelInformatio2 === void 0 ? void 0 : _data$hotelInformatio2.ancillaryCloseout;
    var isAncillaryCloseout = false;
    var ancillaryData = {
      arrivalDate: firstReservation === null || firstReservation === void 0 || (_firstReservation$roo5 = firstReservation.roomStay) === null || _firstReservation$roo5 === void 0 ? void 0 : _firstReservation$roo5.arrivalDate,
      departureDate: firstReservation === null || firstReservation === void 0 || (_firstReservation$roo6 = firstReservation.roomStay) === null || _firstReservation$roo6 === void 0 ? void 0 : _firstReservation$roo6.departureDate,
      ancillaryCloseoutData: ancillaryCloseoutData !== null && ancillaryCloseoutData !== void 0 ? ancillaryCloseoutData : {
        items: []
      },
      adultsMeals: (_mealPackagesData$mea4 = mealPackagesData === null || mealPackagesData === void 0 ? void 0 : mealPackagesData.meals) !== null && _mealPackagesData$mea4 !== void 0 ? _mealPackagesData$mea4 : [],
      childrenMeals: (_mealPackagesData$mea5 = mealPackagesData === null || mealPackagesData === void 0 ? void 0 : mealPackagesData.mealsKids) !== null && _mealPackagesData$mea5 !== void 0 ? _mealPackagesData$mea5 : []
    };
    var {
      filteredAdultsMeals,
      filteredChildrenMeals
    } = (0, _utils.filterPackagesByAncillariesCloseOut)(ancillaryData);
    mealPackagesData.meals = filteredAdultsMeals;
    mealPackagesData.mealsKids = filteredChildrenMeals;
    if ((filteredAdultsMeals === null || filteredAdultsMeals === void 0 ? void 0 : filteredAdultsMeals.length) === 0) {
      isAncillaryCloseout = true;
    }
    if (restaurantData !== null && restaurantData !== void 0 && restaurantData.noMealsFound || isAncillaryCloseout) {
      var _restaurantData$messa;
      return (0, _jsxRuntime.jsx)(_react.Box, {
        my: "2xl",
        mx: "lg",
        children: (0, _jsxRuntime.jsx)(_molecules.RestaurantMessage, {
          messageDescription: restaurantData !== null && restaurantData !== void 0 && restaurantData.noMealsFound ? restaurantData === null || restaurantData === void 0 ? void 0 : restaurantData.messageDescription : t('upsell.message.restaurant.closure'),
          messageTitle: restaurantData !== null && restaurantData !== void 0 && restaurantData.noMealsFound ? (_restaurantData$messa = restaurantData === null || restaurantData === void 0 ? void 0 : restaurantData.messageHeader) !== null && _restaurantData$messa !== void 0 ? _restaurantData$messa : '' : t('upsell.heading.restaurant.closure'),
          prefixDataTestId: baseDataTestId
        })
      });
    }
    return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [showFreeFoodKidsNotification && (0, _jsxRuntime.jsx)(_react.Box, {
        mt: "2xl",
        mx: "var(--chakra-space-lg)",
        children: (0, _jsxRuntime.jsx)(_molecules.FreeFoodKidsNotification, {
          prefixDataTestId: baseDataTestId
        })
      }), !amendSaveReservationIsLoading && shouldDisplaySaveReservationNotification && mealsNotification && (0, _jsxRuntime.jsx)(_molecules.RoomSuccessNotification, {
        description: mealsNotification,
        dataTestId: "RoomsMealSelection-notification",
        styles: {
          mt: showFreeFoodKidsNotification ? 'lg' : '2xl',
          mb: 0
        }
      }), (0, _jsxRuntime.jsx)(_RoomsMealSelection.default, {
        rooms: temporaryBookingConfirmation.reservationByIdList,
        mealPackagesData: _objectSpread(_objectSpread({}, mealPackagesData), {}, {
          roomSelection: sortedMeals
        }),
        restaurantLogo: (_restaurantData$logoS = restaurantData === null || restaurantData === void 0 ? void 0 : restaurantData.logoSrc) !== null && _restaurantData$logoS !== void 0 ? _restaurantData$logoS : '',
        onSaveReservation: onRoomsMealSelect,
        handleOnAddMeal: handleOnAddMeal,
        handleOnRemoveMeal: handleOnRemoveMeal,
        currentRoom: currentRoom.current,
        saveMealsAllAtOnce: saveMealsAllAtOnce
      })]
    });
  }
  function renderAccordionItems() {
    var _manageBookingData$ma, _manageBookingData$ma2, _headerInformationDat25, _mealPackagesData$roo3;
    var accordionItems = [{
      title: t('amend.stayDate'),
      content: (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
        children: [(0, _jsxRuntime.jsx)(_molecules.StayDates, {
          data: stayDatesData,
          labels: stayDatesLabels,
          baseDataTestId: baseDataTestId,
          onAmendStayDates: handleAmendStayDates,
          isLoading: isStayDatesLoading,
          language: language,
          isCancellable: manageBookingData && (manageBookingData === null || manageBookingData === void 0 || (_manageBookingData$ma = manageBookingData.manageBooking) === null || _manageBookingData$ma === void 0 ? void 0 : _manageBookingData$ma.isCancellable),
          variant: variant
        }), !isStayDatesLoading && amendStayDatesIsSuccess && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
          children: [(0, _jsxRuntime.jsx)(_atoms.Notification, {
            "data-testid": "".concat(baseDataTestId, "-available-dates-notification"),
            status: "success",
            variant: "success",
            title: "",
            description: t('amend.roomAvailable'),
            svg: (0, _jsxRuntime.jsx)(_atoms.Success, {}),
            isInnerHTML: false,
            wrapperStyles: _objectSpread(_objectSpread({}, notificationCommonStyles), {}, {
              mb: 'var(--chakra-space-lg)'
            })
          }), (0, _jsxRuntime.jsx)(_atoms.Notification, {
            "data-testid": "".concat(baseDataTestId, "-update-stay-dates"),
            status: "info",
            variant: "info",
            title: "",
            description: getStayDatesUpdate({
              originalArrival: originalArrivalDate,
              temporaryArrival: temporaryArrivalDate,
              originalNumberOfNights: originalNumberOfNights,
              temporaryNumberOfNights: temporaryNumberOfNights,
              temporaryNewTotal: Number(temporaryBookingConfirmation.newTotal),
              currency: temporaryBookingConfirmation.currencyCode,
              language,
              text: t('amend.amendNotification')
            }),
            svg: (0, _jsxRuntime.jsx)(_atoms.Info, {}),
            isInnerHTML: true,
            wrapperStyles: _objectSpread(_objectSpread({}, notificationCommonStyles), {}, {
              mb: 'var(--chakra-space-lg)'
            })
          })]
        }), !isStayDatesLoading && amendStayDatesIsError && (0, _jsxRuntime.jsx)(_atoms.Notification, {
          "data-testid": "".concat(baseDataTestId, "-available-dates-notification"),
          status: "error",
          variant: "error",
          title: t('amend.roomsUnavailable'),
          description: t('amend.roomsUnavailableDescription'),
          svg: (0, _jsxRuntime.jsx)(_atoms.Error, {}),
          isInnerHTML: false,
          wrapperStyles: _objectSpread(_objectSpread({}, notificationCommonStyles), {}, {
            mb: 'var(--chakra-space-lg)'
          })
        })]
      }),
      onToggleSection: () => amendStayDatesMutation.reset()
    }, {
      title: t('amend.roomAndGuests'),
      content: (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
        children: isRoomLoading ? (0, _jsxRuntime.jsx)(_atoms.LoadingSpinner, {
          wrapperStyle: loadingSpinnerStyle
        }) : (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
          children: [shouldDisplayAddRoomNotification && (0, _jsxRuntime.jsx)(_molecules.RoomSuccessNotification, {
            description: roomSuccessfullyAddedLabel,
            dataTestId: "add-room-success-notification"
          }), shouldDisplayEditRoomNotification && (0, _jsxRuntime.jsx)(_molecules.RoomSuccessNotification, {
            description: roomSuccessfullyUpdatedLabel,
            dataTestId: "edit-room-success-notification"
          }), shouldDisplayRemoveRoomNotification && (0, _jsxRuntime.jsx)(_molecules.RoomSuccessNotification, {
            description: roomSuccessfullyRemovedLabel,
            dataTestId: "remove-room-success-notification"
          }), shouldRenderRoomsSection && (0, _jsxRuntime.jsx)(_molecules.RoomsAndGuests, {
            data: roomAndGuestsData,
            baseDataTestId: baseDataTestId,
            roomRules: RoomOccupancyLimitationsData,
            labels: roomsAndGuestsLabels,
            language: language,
            hotelAvailabilityParams: hotelAvailabilityParams,
            onSaveNewRoom: handleSaveNewRoom,
            onUpdateRoom: handleUpdateRoom,
            onRemoveRoom: handleRemoveRoom,
            maxRooms: getMaxRooms(hasEmployeeOfferReservation, discountRateReservationData),
            variant: variant,
            isCancellable: manageBookingData && (manageBookingData === null || manageBookingData === void 0 || (_manageBookingData$ma2 = manageBookingData.manageBooking) === null || _manageBookingData$ma2 === void 0 ? void 0 : _manageBookingData$ma2.isCancellable),
            brand: brand,
            hotelCountry: hotelCountry
          }), shouldDisplayEditRoomNotification && isAdultsDecreased.current && (0, _jsxRuntime.jsx)(_atoms.Notification, {
            status: "warning",
            variant: "alert",
            svg: (0, _jsxRuntime.jsx)(_atoms.Alert, {}),
            title: t('amend.notification.reset.header'),
            description: resetMealsAfterEditRoomLabel,
            "data-testid": "edit-room-warning-notification",
            wrapperStyles: _objectSpread(_objectSpread({}, notificationCommonStyles), {}, {
              mb: 'var(--chakra-space-xl)'
            })
          }), (roomAndGuestsData === null || roomAndGuestsData === void 0 ? void 0 : roomAndGuestsData.reservations.length) >= stayDatesData.maxRooms && (0, _jsxRuntime.jsx)(_atoms.Notification, {
            "data-testid": "".concat(baseDataTestId, "-available-dates-notification"),
            status: "warning",
            variant: "alert",
            title: (discountRateReservationData === null || discountRateReservationData === void 0 ? void 0 : discountRateReservationData.unAvailableMessage) || (headerInformationData === null || headerInformationData === void 0 || (_headerInformationDat25 = headerInformationData.headerInformation) === null || _headerInformationDat25 === void 0 || (_headerInformationDat25 = _headerInformationDat25.results) === null || _headerInformationDat25 === void 0 || (_headerInformationDat25 = _headerInformationDat25.notifications) === null || _headerInformationDat25 === void 0 ? void 0 : _headerInformationDat25.groupBookingHeader),
            description: renderMaxRoomsMessage(hasEmployeeOfferReservation, discountRateReservationData),
            svg: alertIcon,
            isInnerHTML: false,
            wrapperStyles: _objectSpread(_objectSpread({}, notificationCommonStyles), {}, {
              mb: 'var(--chakra-space-2xl)'
            })
          })]
        })
      }),
      onToggleSection: () => {
        addNewRoomMutation.reset();
        amendEditRoomMutation.reset();
        removeRoomMutation.reset();
      }
    }];
    var noSelectedMeals = !(mealPackagesData !== null && mealPackagesData !== void 0 && (_mealPackagesData$roo3 = mealPackagesData.roomSelection) !== null && _mealPackagesData$roo3 !== void 0 && _mealPackagesData$roo3.find(room => {
      var _room$packagesSelecti;
      return (room === null || room === void 0 || (_room$packagesSelecti = room.packagesSelection) === null || _room$packagesSelecti === void 0 ? void 0 : _room$packagesSelecti.length) !== 0;
    }));
    if (!(restaurantData !== null && restaurantData !== void 0 && restaurantData.restaurantNotFound && noSelectedMeals)) {
      accordionItems.push({
        title: t('amend.yourMeals'),
        content: renderYourMealsContent(),
        onToggleSection: () => {
          amendSaveReservationMutation.reset();
        }
      });
    }
    if (hasAncillariesWifiSelected && mealPackagesData && temporaryBookingConfirmation !== null && temporaryBookingConfirmation !== void 0 && temporaryBookingConfirmation.reservationByIdList) {
      accordionItems.push({
        title: t('amend.extras.extras'),
        content: renderExtras(temporaryBookingConfirmation.reservationByIdList, mealPackagesData, currentRoom.current),
        onToggleSection: () => {
          amendSaveReservationMutation.reset();
        }
      });
    }
    return accordionItems;
  }
  function renderMaxRoomsMessage(hasEmployeeOfferReservation, discountRateReservationData) {
    if (discountRateReservationData !== null && discountRateReservationData !== void 0 && discountRateReservationData.isDiscountRate && [_api.Area.PI, _api.Area.CCUI].includes(variant)) {
      return discountRateReservationData === null || discountRateReservationData === void 0 ? void 0 : discountRateReservationData.roomLimitMessage;
    }
    if (variant === _api.Area.CCUI) {
      return ccuiRoomsLimitDescriptionNotification;
    }
    if (hasEmployeeOfferReservation && variant === _api.Area.PI) {
      return roomsLimitDescriptionEmployeeOfferNotification;
    }
    return roomsLimitDescriptionNotification;
  }
  function invalidateBookingConfirmation() {
    queryClient.invalidateQueries({
      queryKey: ['getBookingConfirmationAmend', temporaryBasketReference, country, language]
    }).catch(() => {
      console.log('invalidate fail');
    });
  }
  function invalidatePackages() {
    queryClient.invalidateQueries({
      queryKey: ['GetPackages']
    }).catch(() => {
      console.log('invalidate fail');
    });
  }
  function invalidateAmendSummary() {
    queryClient.invalidateQueries({
      queryKey: ['AmendSummary']
    }).catch(() => {
      console.log('invalidate fail');
    });
  }
  function editAmendPageAnalytics(changeValue, newAnalyticsParams) {
    var _window4;
    var analytics = (_window4 = window) === null || _window4 === void 0 ? void 0 : _window4.analyticsData;
    var paramsForAnalytics = _objectSpread(_objectSpread(_objectSpread({}, analytics), newAnalyticsParams), {}, {
      change: changeValue
    });
    window.__satelliteLoaded && window._satellite.track(_constants.ANALYTICS_TRACKING_AMEND_SEARCH);
    (0, _utils.updateAmendPageAnalytics)(paramsForAnalytics, bookingReference);
  }
  function handleAmendStayDates(newStartDate, newEndDate) {
    var paramsForQuery = {
      tempBookingRef: temporaryBasketReference,
      channel,
      subchannel: _constants.SUBCHANNEL,
      language: language === null || language === void 0 ? void 0 : language.toUpperCase(),
      newStartDate: (0, _format.default)(newStartDate, _api.DATE_TYPE.YEAR_MONTH_DAY),
      newEndDate: (0, _format.default)(newEndDate, _api.DATE_TYPE.YEAR_MONTH_DAY),
      token: (0, _utils.getFindBookingToken)().token
    };
    amendStayDatesMutation.mutateAsync(paramsForQuery).then((0, _asyncToGenerator2.default)(function* () {
      invalidateBookingConfirmation();
      invalidateAmendSummary();
      if (!isStayDatesLoading) {
        var newNumberOfNights = (0, _utils.getNightsNumber)((0, _format.default)(newStartDate, _api.DATE_TYPE.YEAR_MONTH_DAY), (0, _format.default)(newEndDate, _api.DATE_TYPE.YEAR_MONTH_DAY));
        var analyticsChange = "nights changed from ".concat(originalNumberOfNights, " to ").concat(newNumberOfNights);
        editAmendPageAnalytics(analyticsChange, {
          nightsChange: newNumberOfNights - originalNumberOfNights
        });
      }
    }));
  }
  function handleSaveNewRoom(newRoom) {
    var employeeRatePlanCode = _api.OfferEnum.EMPLOYEE_RATE_CODE;
    var params = _objectSpread(_objectSpread({}, newRoom), {}, {
      channel: channel,
      subchannel: _constants.SUBCHANNEL,
      language: language,
      tempBookingRef: temporaryBasketReference,
      token: (0, _utils.getFindBookingToken)().token,
      ratePlanCode: hasEmployeeOfferReservation && channel === _api.BOOKING_CHANNEL.PI ? employeeRatePlanCode : ''
    });
    addNewRoomMutation.mutateAsync(params).then(() => {
      invalidateBookingConfirmation();
      invalidateAmendSummary();
      invalidatePackages();
      setUserHasMadeChanges(true);
      if (!addNewRoomIsLoading) {
        var _window$analyticsData4, _window5;
        var analyticsAddRoom = ((_window$analyticsData4 = (_window5 = window) === null || _window5 === void 0 || (_window5 = _window5.analyticsData) === null || _window5 === void 0 ? void 0 : _window5.roomsChange) !== null && _window$analyticsData4 !== void 0 ? _window$analyticsData4 : 0) + 1;
        editAmendPageAnalytics('1 room added', {
          roomsChange: analyticsAddRoom
        });
      }
    });
    amendEditRoomMutation.reset();
    removeRoomMutation.reset();
  }
  function handleUpdateRoom(selectedRoom, reservationId, roomNumber, analyticsRoomDetails) {
    var params = _objectSpread(_objectSpread({}, selectedRoom), {}, {
      reservationId: reservationId,
      channel: channel,
      subchannel: _constants.SUBCHANNEL,
      language: language,
      tempBookingRef: temporaryBasketReference,
      token: (0, _utils.getFindBookingToken)().token
    });
    var {
      isChanged,
      roomType,
      changeAdults
    } = analyticsRoomDetails;
    amendEditRoomMutation.mutateAsync(params).then((0, _asyncToGenerator2.default)(function* () {
      invalidatePackages();
      invalidateBookingConfirmation();
      invalidateAmendSummary();
      setUserHasMadeChanges(true);
      addNewRoomMutation.reset();
      removeRoomMutation.reset();
      currentRoom.current = roomNumber - 1;
      if (analyticsRoomDetails && !amendEditRoomIsLoading) {
        var analyticsChange;
        if (isChanged && changeAdults) {
          var _changeAdults$include;
          analyticsChange = "Room type changed to ".concat(roomType, " and ").concat(changeAdults);
          isAdultsDecreased.current = (_changeAdults$include = changeAdults === null || changeAdults === void 0 ? void 0 : changeAdults.includes('removed')) !== null && _changeAdults$include !== void 0 ? _changeAdults$include : false;
        } else if (isChanged && !changeAdults) {
          analyticsChange = "Room type changed to ".concat(roomType);
        }
        editAmendPageAnalytics(analyticsChange, {
          roomTypeChange: isChanged
        });
      }
    }));
  }
  function handleRemoveRoom(reservationId, roomNumber) {
    var params = {
      reservationId,
      channel: channel,
      subchannel: _constants.SUBCHANNEL,
      language: language,
      tempBookingRef: temporaryBasketReference,
      token: (0, _utils.formatFindBookingToken)((0, _utils.getFindBookingToken)().token)
    };
    removeRoomMutation.mutateAsync(params).then(() => {
      invalidateBookingConfirmation();
      invalidateAmendSummary();
      invalidatePackages();
      setUserHasMadeChanges(true);
      if (!isRemoveRoomLoading) {
        var _window$analyticsData5, _window6;
        var analyticsRemoveRoom = ((_window$analyticsData5 = (_window6 = window) === null || _window6 === void 0 || (_window6 = _window6.analyticsData) === null || _window6 === void 0 ? void 0 : _window6.roomsChange) !== null && _window$analyticsData5 !== void 0 ? _window$analyticsData5 : 0) - 1;
        editAmendPageAnalytics('1 room removed', {
          roomsChange: analyticsRemoveRoom
        });
      }
    });
    addNewRoomMutation.reset();
    amendEditRoomMutation.reset();
    removedRoomNumber.current = roomNumber;
    currentRoom.current = 0;
  }
  function handleConfirmChanges() {
    var _paymentProps$selecte4, _paymentProps$selecte5;
    var params = {
      tempBookingRef: temporaryBasketReference,
      originalBookingRef: basketReference,
      channel,
      subchannel: _constants.SUBCHANNEL,
      language: language === null || language === void 0 ? void 0 : language.toUpperCase(),
      token: (0, _utils.getFindBookingToken)().token,
      environment: window.location.origin,
      emailAddress: email
    };
    var preCheckedInAmendedIds = !isDeleteRegCardEnabled ? [] : (0, _helpers.getChangedPreCheckedInReservations)(tempAmendData === null || tempAmendData === void 0 ? void 0 : tempAmendData.bookingConfirmation, temporaryBookingData === null || temporaryBookingData === void 0 ? void 0 : temporaryBookingData.bookingConfirmation);
    var confirmAmendLogicParams = _objectSpread(_objectSpread({}, params), {}, {
      paymentOptionSelected: (_paymentProps$selecte4 = paymentProps === null || paymentProps === void 0 || (_paymentProps$selecte5 = paymentProps.selectedPaymentDetail) === null || _paymentProps$selecte5 === void 0 ? void 0 : _paymentProps$selecte5.type) !== null && _paymentProps$selecte4 !== void 0 ? _paymentProps$selecte4 : _constants.PAY_ON_ARRIVAL,
      preCheckIn: preCheckedInAmendedIds
    });
    confirmAmendMutation.mutateAsync(confirmAmendLogicParams).then(function () {
      var _ref4 = (0, _asyncToGenerator2.default)(function* (response) {
        var _response$confirmAmen;
        queryClient.invalidateQueries({
          queryKey: ['getBookingConfirmationAmend', basketReference, country, language]
        });
        var paymentRedirect = response === null || response === void 0 || (_response$confirmAmen = response.confirmAmendLogic) === null || _response$confirmAmen === void 0 || (_response$confirmAmen = _response$confirmAmen.payment) === null || _response$confirmAmen === void 0 || (_response$confirmAmen = _response$confirmAmen.paymentRequiredDetails) === null || _response$confirmAmen === void 0 ? void 0 : _response$confirmAmen.paymentRedirect;
        if (paymentRedirect) {
          setIframeContent(paymentRedirect);
          setAmend3cpVisited(bookingReference);
          return;
        }
        intervalRef.current = setInterval((0, _asyncToGenerator2.default)(function* () {
          var {
            basketStatus,
            basketError
          } = yield (0, _helpers.getBasketStatus)(queryClient, temporaryBasketReference);
          var isAmendingOrFailed = basketStatus === _api.BASKET_STATUS.AMENDING && basketError || basketStatus === _api.BASKET_STATUS.AMEND_FAILED && basketError;
          if (basketStatus === _api.BASKET_STATUS.AMENDED) {
            var _intervalRef$current2;
            clearInterval((_intervalRef$current2 = intervalRef.current) !== null && _intervalRef$current2 !== void 0 ? _intervalRef$current2 : '');
            yield router.push("".concat(amendConfirmationURL, "&status=").concat(_api.CONFIRM_AMEND_STATUS.success, "&basketReference=").concat(basketReference, "&tempBookingReference=").concat(temporaryBasketReference));
          } else if (isAmendingOrFailed) {
            var _intervalRef$current3;
            clearInterval((_intervalRef$current3 = intervalRef.current) !== null && _intervalRef$current3 !== void 0 ? _intervalRef$current3 : '');
            setConfirmAmendErrorValue(basketError.code);
            yield router.push("".concat(amendConfirmationURL, "&status=").concat(_api.CONFIRM_AMEND_STATUS.error, "&tempBookingReference=").concat(temporaryBasketReference, "&basketReference=").concat(basketReference));
          }
        }), 1 * 1000);
        window.__satelliteLoaded && window._satellite.track(_constants.ANALYTICS_TRACKING_AMEND_CONFIRMATION);
      });
      return function (_x) {
        return _ref4.apply(this, arguments);
      };
    }()).catch(function () {
      var _ref6 = (0, _asyncToGenerator2.default)(function* (error) {
        var errorMessage = '';
        try {
          var message = JSON.parse(error.response.errors[0].message);
          errorMessage = message.globalErrTextTemplate;
        } catch (e) {
          console.log(e);
        }
        setConfirmAmendErrorValue(errorMessage);
        yield router.push("".concat(amendConfirmationURL, "&status=").concat(_api.CONFIRM_AMEND_STATUS.error, "&tempBookingReference=").concat(temporaryBasketReference, "&basketReference=").concat(basketReference));
      });
      return function (_x2) {
        return _ref6.apply(this, arguments);
      };
    }());
  }
  function handleBackToHomepage() {
    (0, _utils.cleanupFindBookingToken)();
    var prefixURL = "".concat(origin, "/").concat(country, "/").concat(language, "/");
    window.location.href = "".concat(prefixURL).concat(getBackRedirectUrl(variant));
  }
  function getStayDatesUpdate(_ref7) {
    var {
      originalArrival,
      temporaryArrival,
      originalNumberOfNights,
      temporaryNumberOfNights,
      temporaryNewTotal,
      currency,
      language,
      text
    } = _ref7;
    var getNightsLabel = numberOfNights => {
      var label = numberOfNights === 1 ? t('amend.night') : t('amend.nights');
      return "".concat(numberOfNights, " ").concat(label);
    };
    var mapObject = {
      '{arrivalDateOld}': originalArrival,
      '{numNightOld}': getNightsLabel(originalNumberOfNights),
      '{arrivalDateNew}': temporaryArrival,
      '{numNightNew}': getNightsLabel(temporaryNumberOfNights),
      '{newTotal}': (0, _utils.formatPriceWithDecimal)(language, (0, _utils.formatCurrency)(currency), temporaryNewTotal, true)
    };
    var regex = new RegExp(Object.keys(mapObject).join('|'), 'g');
    return text.replace(regex, matched => "<span style=\"font-weight: 600\">".concat(mapObject[matched], "</span>"));
  }
  function saveRoomsMeals() {
    var roomsSelectionsMeal = newMeals && (0, _utils.numberOfSelectionsPerRoomSelector)(newMeals);
    var params = {
      basketReferenceId: temporaryBasketReference,
      hotelId: temporaryBookingConfirmation.hotelId,
      arrivalDate: temporaryArrivalDate,
      departureDate: temporaryDepartureDate,
      roomsSelections: roomsSelectionsMeal,
      previousRoomsSelections: (0, _utils.numberOfSelectionsPerRoomSelector)(prevMeals)
    };
    amendSaveReservationMutation.mutateAsync(params).then(() => {
      invalidateBookingConfirmation();
      invalidatePackages();
      invalidateAmendSummary();
      var hasMealsUpdated = (params === null || params === void 0 ? void 0 : params.roomsSelections) && !(0, _isEqual.default)(params.previousRoomsSelections, params === null || params === void 0 ? void 0 : params.roomsSelections);
      hasMealsUpdated && setMealsSectionHasUpdates(hasMealsUpdated);
    });
  }
  function getRoomsPackages() {
    var adultsMeals = (0, _utils.adultsMealsSelector)(mealPackagesData === null || mealPackagesData === void 0 ? void 0 : mealPackagesData.meals, temporaryNumberOfNights);
    var childrenMeals = (0, _utils.childrenMealsSelector)(mealPackagesData === null || mealPackagesData === void 0 ? void 0 : mealPackagesData.mealsKids);
    return (0, _utils.roomInformationSelector)(temporaryBookingConfirmation === null || temporaryBookingConfirmation === void 0 ? void 0 : temporaryBookingConfirmation.reservationByIdList, selectedMeals, adultsMeals, childrenMeals, (0, _utils.roomPackageSelection)((0, _utils.extrasPackagesMapperSelector)(mealPackagesData === null || mealPackagesData === void 0 ? void 0 : mealPackagesData.roomSelectionAmendExtras)));
  }
}
function getBackRedirectUrl(variant) {
  switch (variant) {
    case _api.Area.PI:
      return 'home.html';
    case _api.Area.BB:
      return 'business-booker/home.html';
    case _api.Area.CCUI:
      return 'bookings';
    default:
      return 'home.html';
  }
}
function renderExtras(rooms, mealPackagesData, currentRoom) {
  return (0, _jsxRuntime.jsx)(_RoomsExtrasSelection.default, {
    rooms: rooms,
    mealPackagesData: mealPackagesData,
    currentRoom: currentRoom
  });
}
var titleStyle = {
  as: 'h1',
  fontWeight: 'bold',
  color: 'darkGrey1',
  fontSize: {
    mobile: '3xxl',
    sm: '4xl'
  },
  lineHeight: {
    mobile: '5',
    sm: '5'
  },
  mb: {
    mobile: 'xl',
    xs: '3xl',
    md: '3xl'
  }
};
var detailsStyle = {
  mt: {
    mobile: 'lg',
    sm: '2xl',
    lg: '5xl'
  },
  mb: {
    mobile: '3xl',
    lg: '5xl'
  },
  w: 'full',
  px: {
    mobile: '1rem',
    sm: '1.125rem',
    md: '1.5rem'
  }
};
var amendContainerStyle = {
  flexDir: {
    mobile: 'column',
    md: 'column',
    lg: 'row'
  },
  justifyContent: 'space-between'
};
var accordionItemButtonStyle = {
  pr: 'md',
  pl: 'md'
};
var accordionItemTextStyle = {
  fontSize: {
    mobile: '2xl'
  },
  lineHeight: '4',
  color: 'darkGrey2'
};
var accordionContainerStyle = {
  borderX: '1px solid var(--chakra-colors-lightGrey4)',
  borderXColor: 'lightGrey3',
  w: {
    base: 'full',
    lg: '50.5rem',
    xl: '54rem'
  },
  borderRadius: 'var(--chakra-radii-xs)'
};
var accordionItemPanelStyle = {
  borderTop: '1px solid var(--chakra-colors-lightGrey4)',
  padding: 0
};
var accordionItemStyle = {
  _notLast: {
    borderBottom: 0
  },
  borderColor: 'lightGrey3'
};
var accordionOverwriteStyles = {
  container: accordionContainerStyle,
  button: accordionItemButtonStyle,
  text: accordionItemTextStyle,
  panel: accordionItemPanelStyle,
  item: accordionItemStyle
};
var containerStyle = {
  border: '1px solid var(--chakra-colors-lightGrey3)',
  w: {
    lg: '50.5rem',
    xl: '54rem'
  }
};
var returnButtonWrapper = {
  mt: '3xl',
  alignItems: 'center',
  w: 'fit-content',
  justifyContent: 'flex-start',
  cursor: 'pointer'
};
var returnButtonTextStyle = {
  pl: 'sm',
  fontWeight: 'semibold',
  fontSize: 'md',
  color: 'darkGrey1',
  lineHeight: '3'
};
var loadingSpinnerStyle = {
  margin: {
    sm: 'lg'
  }
};
var notificationAlertEciLcoStyle = {
  mb: {
    mobile: '3xl',
    lg: '5xl'
  },
  w: {
    base: 'full',
    lg: '50.5rem',
    xl: '54rem'
  }
};
var notificationCommonStyles = {
  width: 'auto',
  m: 'var(--chakra-space-lg)',
  mt: '0',
  mb: 0
};