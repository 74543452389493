"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SearchContainer;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _add = _interopRequireDefault(require("date-fns/add"));
var _isValid = _interopRequireDefault(require("date-fns/isValid"));
var _de = _interopRequireDefault(require("date-fns/locale/de"));
var _enGB = _interopRequireDefault(require("date-fns/locale/en-GB"));
var _debounce = _interopRequireDefault(require("lodash/debounce"));
var _nextI18next = require("next-i18next");
var _config = _interopRequireDefault(require("next/config"));
var _router = require("next/router");
var _react2 = require("react");
var _pi = require("../styles/pi.style");
var _utilities = require("../utilities");
var _searchContainerHelpers = require("../utilities/searchContainerHelpers");
var _searchValidation = require("../validations/searchValidation");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var MIN_LENGTH_SEARCH_TERM = 3;
function SearchContainer(_ref) {
  var _maxNightsLimitation, _dataTranslations$hea, _partialTranslations$, _maxArrivalDateLimita, _maxNightsLimitation2, _maxRoomsLimitation, _partialTranslations$2, _dataTranslations$hea2, _dataTranslations$hea3, _dataTranslations$hea4, _dataTranslations$hea5, _dataTranslations$hea6, _partialTranslations$3, _partialTranslations$6, _partialTranslations$7, _partialTranslations$8, _partialTranslations$9, _partialTranslations$10, _partialTranslations$11;
  var {
    searchLocation,
    defaultLocation,
    defaultRooms,
    ARRdd,
    ARRmm,
    ROOMS,
    ARRyyyy,
    NIGHTS,
    CORPID,
    isSummaryActive = Boolean(ARRyyyy && ARRmm && ARRdd),
    variant,
    marginBottom
  } = _ref;
  var initialData = {
    managedPlaces: [],
    places: [],
    properties: []
  };
  var {
    [_api.FT_PI_GROUP_BOOKING_FORM]: isPIGroupBookingFormEnabled,
    [_api.FT_PI_DISCOUNT_RATE]: isDiscountRateEnabled
  } = (0, _utils.useFeatureToggle)();
  var {
    publicRuntimeConfig = {}
  } = (0, _config.default)() || {};
  var [items, setItems] = (0, _react2.useState)(null);
  var [errorCode, setErrorCode] = (0, _react2.useState)(['', '']);
  var [searchConsoleIsActive, setSearchConsoleIsActive] = (0, _react2.useState)(false);
  var [searchSummaryActive, setSearchSummaryActive] = (0, _react2.useState)(true);
  var [stayDetailsState, setStayDetailsState] = (0, _utils.useLocalStorage)('StayDetailsState', _molecules.STAY_DETAILS_STATE_INITIAL_VALUE);
  var [, setDistanceFromSearch] = (0, _utils.useLocalStorage)('DistanceFromSearch', _molecules.DISTANCE_FROM_SEARCH_INITIAL_VALUE);
  var [, setSearchReferrer] = (0, _utils.useLocalStorage)('SearchReferrer', _molecules.SEARCH_REFERRER_INITIAL_VALUE);
  var router = (0, _router.useRouter)();
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var screenSize = (0, _utils.useScreenSize)();
  var {
    isLessThanSm,
    isLessThanMd,
    isLessThanLg
  } = screenSize;
  var placeholderDate = ARRyyyy && ARRmm && ARRdd && new Date(ARRyyyy, ARRmm - 1, ARRdd);
  var firstDefaultEndDate = placeholderDate && (0, _add.default)(placeholderDate, {
    days: NIGHTS > 0 ? NIGHTS : 1
  }) || null;
  var firstDefaultStartDate = placeholderDate || null;
  var today = new Date();
  var tomorrow = (0, _add.default)(today, {
    days: 1
  });
  var [startDate, setStartDate] = (0, _react2.useState)(firstDefaultStartDate);
  var [endDate, setEndDate] = (0, _react2.useState)(firstDefaultEndDate);
  var [isNumberOfNightsError, setIsNumberOfNightsError] = (0, _react2.useState)(false);
  var errorKey = ['', ''];
  var URL = router.asPath;
  var [validationKey, validationField] = errorCode;
  var isDatepickerError = validationField === _searchContainerHelpers.FIELDS.datepicker;
  var {
    isLoading: isLoadingTranslations,
    isError: isErrorTranslations,
    data: dataTranslations,
    error: errorTranslations
  } = (0, _utils.useQueryRequest)(['GetStaticContent', language, country], _api.GET_STATIC_CONTENT, {
    country,
    language,
    site: _api.SITE_LEISURE,
    businessBooker: false
  });
  var {
    isLoading: isLoadingSearchRules,
    isError: isErrorSearchRules,
    data: dataSearchRules,
    error: errorSearchRules
  } = (0, _utils.useQueryRequest)(['getSearchRules', _api.Channel.Pi], _api.GET_SEARCH_RULES_QUERY, {
    channel: _api.Channel.Pi
  });
  var isNumberOfNightsUrlError = Number(NIGHTS) < 1 || !NIGHTS || NIGHTS > (dataSearchRules === null || dataSearchRules === void 0 || (_maxNightsLimitation = dataSearchRules.maxNightsLimitation) === null || _maxNightsLimitation === void 0 ? void 0 : _maxNightsLimitation.maxNights);
  (0, _react2.useEffect)(() => {
    if (defaultLocation) {
      handleLocationInputChange(defaultLocation);
    }
  }, []);
  (0, _react2.useEffect)(() => {
    if (typeof isSummaryActive === 'boolean') {
      setSearchSummaryActive(isSummaryActive);
    }
  }, [isSummaryActive, defaultLocation, defaultLocation, searchLocation]);
  (0, _react2.useEffect)(() => {
    if (router.asPath !== URL) {
      router.push("/".concat(country).concat(URL), undefined, {
        shallow: true
      });
    }
    if (errorKey) {
      var [_key, field] = errorKey;
      var [currentKey] = errorCode;
      if (_key !== currentKey && _key !== undefined && field !== undefined) {
        setErrorCode([_key, field]);
      }
    }
  });
  (0, _react2.useEffect)(() => {
    var [key, field] = errorCode;
    if (key && field) {
      setSearchSummaryActive(false);
      setSearchConsoleIsActive(true);
    }
  }, [errorCode]);
  (0, _react2.useEffect)(() => {
    if (isDatepickerError) {
      setStartDate(null);
      setEndDate(null);
    }
    if (isNumberOfNightsUrlError) {
      setIsNumberOfNightsError(true);
      setStartDate(today);
      setEndDate(tomorrow);
    }
  }, [isDatepickerError]);
  var isSearchLoading = isLoadingSearchRules || isLoadingTranslations;
  if (isSearchLoading) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      "data-testid": "loading-message",
      style: {
        display: 'none'
      },
      children: t('searchresults.list.hotel.loading')
    });
  }
  if (isErrorSearchRules) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      "data-testid": "error-search-rules-message",
      children: errorSearchRules.message
    });
  }
  if (isErrorTranslations) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      "data-testid": "error-translations-message",
      children: errorTranslations.message
    });
  }
  var partialTranslations = (dataTranslations === null || dataTranslations === void 0 ? void 0 : dataTranslations.headerInformation) || {};
  var roomCodes = (dataTranslations === null || dataTranslations === void 0 || (_dataTranslations$hea = dataTranslations.headerInformation) === null || _dataTranslations$hea === void 0 || (_dataTranslations$hea = _dataTranslations$hea.config) === null || _dataTranslations$hea === void 0 ? void 0 : _dataTranslations$hea.roomCodes) || {};
  var globalTranslations = (partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$ = partialTranslations.content) === null || _partialTranslations$ === void 0 ? void 0 : _partialTranslations$.global) || {};
  var offers = (globalTranslations === null || globalTranslations === void 0 ? void 0 : globalTranslations.offers) || [];
  var {
    CELLCODES
  } = router.query;
  var findOffer = offers => {
    return offers === null || offers === void 0 ? void 0 : offers.find(offer => (offer === null || offer === void 0 ? void 0 : offer.cellCode) === CELLCODES || isDiscountRateEnabled && CORPID && +(offer === null || offer === void 0 ? void 0 : offer.corpId) === +CORPID);
  };
  var matchedOffer = findOffer(offers);
  var isActiveOffer = matchedOffer => {
    return matchedOffer !== undefined && Object.keys(matchedOffer).length > 0;
  };
  var isActiveMatchedOffer = isActiveOffer(matchedOffer);
  var validateSearchDataResults = (0, _searchValidation.validateSearchData)(Number(ARRdd), Number(ARRmm), Number(ARRyyyy), Number(NIGHTS), items, dataTranslations, router, dataSearchRules === null || dataSearchRules === void 0 || (_maxArrivalDateLimita = dataSearchRules.maxArrivalDateLimitation) === null || _maxArrivalDateLimita === void 0 ? void 0 : _maxArrivalDateLimita.maxArrivalDate, dataSearchRules === null || dataSearchRules === void 0 || (_maxNightsLimitation2 = dataSearchRules.maxNightsLimitation) === null || _maxNightsLimitation2 === void 0 ? void 0 : _maxNightsLimitation2.maxNights, defaultRooms, false, isActiveMatchedOffer ? matchedOffer === null || matchedOffer === void 0 ? void 0 : matchedOffer.maxRooms : dataSearchRules === null || dataSearchRules === void 0 || (_maxRoomsLimitation = dataSearchRules.maxRoomsLimitation) === null || _maxRoomsLimitation === void 0 ? void 0 : _maxRoomsLimitation.maxRooms);
  errorKey = validateSearchDataResults.errorKey;
  URL = validateSearchDataResults.url;
  var swappedRoomCodes = (0, _utils.swapKeysAndValues)(roomCodes);
  var AEMTranslations = {
    adultsLabel: t('dashboard.bookings.adults'),
    childrenLabel: t('dashboard.bookings.children'),
    roomTypeLabel: t('search.roomType'),
    locationPlaceholder: t('hoteldetails.location'),
    submitButtonLabel: t('search.submit.search'),
    datepickerCheckinLabel: t('account.dashboard.checkin.text'),
    datepickerCheckoutLabel: t('account.dashboard.checkout.text'),
    datepickerInvalidDates: partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$2 = partialTranslations.datePicker) === null || _partialTranslations$2 === void 0 ? void 0 : _partialTranslations$2.invalidDate
  };
  var errorMessages = {
    ["".concat(_searchContainerHelpers.ERROR_KEYS.invalidLocation)]: t('error.blank.location'),
    ["".concat(_searchContainerHelpers.ERROR_KEYS.invalidDate)]: isNumberOfNightsError ? dataTranslations === null || dataTranslations === void 0 || (_dataTranslations$hea2 = dataTranslations.headerInformation) === null || _dataTranslations$hea2 === void 0 || (_dataTranslations$hea2 = _dataTranslations$hea2.form) === null || _dataTranslations$hea2 === void 0 ? void 0 : _dataTranslations$hea2.invalidNights : dataTranslations === null || dataTranslations === void 0 || (_dataTranslations$hea3 = dataTranslations.headerInformation) === null || _dataTranslations$hea3 === void 0 || (_dataTranslations$hea3 = _dataTranslations$hea3.form) === null || _dataTranslations$hea3 === void 0 ? void 0 : _dataTranslations$hea3.invalidDate,
    ["".concat(_searchContainerHelpers.ERROR_KEYS.arrivalDateInThePast)]: dataTranslations === null || dataTranslations === void 0 || (_dataTranslations$hea4 = dataTranslations.headerInformation) === null || _dataTranslations$hea4 === void 0 || (_dataTranslations$hea4 = _dataTranslations$hea4.form) === null || _dataTranslations$hea4 === void 0 ? void 0 : _dataTranslations$hea4.invalidPastDate,
    ["".concat(_searchContainerHelpers.ERROR_KEYS.arrivalDateInTheFuture)]: dataTranslations === null || dataTranslations === void 0 || (_dataTranslations$hea5 = dataTranslations.headerInformation) === null || _dataTranslations$hea5 === void 0 || (_dataTranslations$hea5 = _dataTranslations$hea5.form) === null || _dataTranslations$hea5 === void 0 ? void 0 : _dataTranslations$hea5.invalidFutureDate,
    ["".concat(_searchContainerHelpers.ERROR_KEYS.invalidNights)]: dataTranslations === null || dataTranslations === void 0 || (_dataTranslations$hea6 = dataTranslations.headerInformation) === null || _dataTranslations$hea6 === void 0 || (_dataTranslations$hea6 = _dataTranslations$hea6.form) === null || _dataTranslations$hea6 === void 0 ? void 0 : _dataTranslations$hea6.invalidNights,
    ["".concat(_searchContainerHelpers.ERROR_KEYS.invalidOccupancy)]: partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$3 = partialTranslations.form) === null || _partialTranslations$3 === void 0 ? void 0 : _partialTranslations$3.invalidRooms
  };
  var mappedRoomLabels = (0, _utils.getMappedRooms)(roomCodes, globalTranslations);
  var message = validationKey && errorMessages[validationKey];
  var searchStyles = (0, _pi.getSearchStyles)(isLessThanSm, (0, _utils.logicalAndOperator)(validationKey !== '', validationKey !== undefined), language);
  var searchConsoleActiveHandler = value => {
    setSearchConsoleIsActive(value);
  };
  var translationDateLanguage = {
    locale: (0, _utils.ternaryCondition)(language === 'en', _enGB.default, _de.default)
  };
  var isMissingDate = !ARRdd || !ARRmm || !ARRyyyy || !NIGHTS;
  function getSummaryDateInterval() {
    if (isMissingDate) {
      var _partialTranslations$4, _partialTranslations$5;
      return "".concat(partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$4 = partialTranslations.content) === null || _partialTranslations$4 === void 0 || (_partialTranslations$4 = _partialTranslations$4.global) === null || _partialTranslations$4 === void 0 ? void 0 : _partialTranslations$4.today, " | ").concat(partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$5 = partialTranslations.content) === null || _partialTranslations$5 === void 0 || (_partialTranslations$5 = _partialTranslations$5.global) === null || _partialTranslations$5 === void 0 ? void 0 : _partialTranslations$5.tomorrow);
    }
    if ((0, _isValid.default)(startDate)) {
      if (!isLessThanSm) {
        return (0, _searchContainerHelpers.formatSummaryDateRange)('dd MMM', startDate, endDate, translationDateLanguage);
      }
      return (0, _searchContainerHelpers.formatSummaryDateRange)('EEE dd MMM', startDate, endDate, translationDateLanguage);
    }
  }
  var searchSummaryProps = searchSummaryActive ? {
    handleEdit: setSearchSummaryActive,
    isSummaryActive: searchSummaryActive,
    location: defaultLocation,
    dateInterval: getSummaryDateInterval(),
    roomSummary: (0, _utilities.getRoomsPlaceholder)(defaultRooms, {
      adult: partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$6 = partialTranslations.content) === null || _partialTranslations$6 === void 0 || (_partialTranslations$6 = _partialTranslations$6.global) === null || _partialTranslations$6 === void 0 ? void 0 : _partialTranslations$6.adult,
      adults: partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$7 = partialTranslations.content) === null || _partialTranslations$7 === void 0 || (_partialTranslations$7 = _partialTranslations$7.global) === null || _partialTranslations$7 === void 0 ? void 0 : _partialTranslations$7.adults,
      child: partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$8 = partialTranslations.content) === null || _partialTranslations$8 === void 0 || (_partialTranslations$8 = _partialTranslations$8.global) === null || _partialTranslations$8 === void 0 ? void 0 : _partialTranslations$8.child,
      children: partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$9 = partialTranslations.content) === null || _partialTranslations$9 === void 0 || (_partialTranslations$9 = _partialTranslations$9.global) === null || _partialTranslations$9 === void 0 ? void 0 : _partialTranslations$9.children,
      room: partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$10 = partialTranslations.content) === null || _partialTranslations$10 === void 0 || (_partialTranslations$10 = _partialTranslations$10.global) === null || _partialTranslations$10 === void 0 ? void 0 : _partialTranslations$10.room,
      rooms: partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$11 = partialTranslations.content) === null || _partialTranslations$11 === void 0 || (_partialTranslations$11 = _partialTranslations$11.global) === null || _partialTranslations$11 === void 0 ? void 0 : _partialTranslations$11.rooms
    }),
    editText: t('amend.edit'),
    isLessThanSm,
    isLessThanMd,
    isLessThanLg
  } : {};
  var suggestions = items || initialData;
  return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
    children: !searchSummaryActive ? (0, _jsxRuntime.jsx)(_molecules.Search, {
      locale: language,
      onLocationInputChange: (0, _debounce.default)(handleLocationInputChange, 300),
      onLocationInputClear: handleLocationInputClear,
      onLocationInputFocus: handleLocationInputFocus,
      onIsSearchActive: searchConsoleActiveHandler,
      onSelectDates: handleSelectDates,
      onOccupancyChange: handleRoomOccupancyChange,
      suggestions: suggestions,
      roomCodes: swappedRoomCodes,
      errorField: validationField,
      errorMessage: message,
      isSearchActive: searchConsoleIsActive,
      mappedRoomLabels: (0, _utils.swapKeysAndValues)(mappedRoomLabels),
      isPIGroupBookingFormEnabled: isPIGroupBookingFormEnabled,
      isDiscountRateEnabled: isDiscountRateEnabled,
      searchLocation,
      ARRdd,
      ARRmm,
      ARRyyyy,
      ROOMS,
      NIGHTS,
      dataStayRules: dataSearchRules,
      dataRoomOccupancyLimitations: dataSearchRules,
      partialTranslations,
      AEMTranslations,
      screenSize,
      handleButtonClick,
      searchStyles,
      defaultLocation,
      defaultRooms,
      startDate,
      endDate,
      isDatepickerError,
      isActiveMatchedOffer,
      matchedOffer,
      marginBottom
    }, router.asPath) : (0, _jsxRuntime.jsx)(_atoms.SearchSummary, _objectSpread({}, searchSummaryProps))
  });
  function getSuggestions(_x) {
    return _getSuggestions.apply(this, arguments);
  }
  function _getSuggestions() {
    _getSuggestions = (0, _asyncToGenerator2.default)(function* (value) {
      var response = yield fetch("".concat(publicRuntimeConfig.NEXT_PUBLIC_SNOWDROP_BASE_URL, "v1/autocomplete?input=/").concat(value, "&gplaces[components]=country:uk|country:de"));
      return response.json();
    });
    return _getSuggestions.apply(this, arguments);
  }
  function handleLocationInputChange(value) {
    if (value && value.trim().length >= MIN_LENGTH_SEARCH_TERM) {
      getSuggestions(value).then(data => {
        setItems(data);
        if (validationField === _searchContainerHelpers.FIELDS.location) {
          setErrorCode([undefined, undefined]);
        }
      });
    } else {
      setItems(null);
    }
  }
  function handleLocationInputClear() {
    setItems(null);
    if (validationField === _searchContainerHelpers.FIELDS.location) {
      setErrorCode([undefined, undefined]);
    }
  }
  function handleLocationInputFocus(searchTerm) {
    if ((searchTerm === null || searchTerm === void 0 ? void 0 : searchTerm.length) === 0) {
      if (validationField === _searchContainerHelpers.FIELDS.location) {
        setErrorCode([undefined, undefined]);
      }
      setItems(null);
    }
  }
  function handleButtonClick(_x2) {
    return _handleButtonClick.apply(this, arguments);
  }
  function _handleButtonClick() {
    _handleButtonClick = (0, _asyncToGenerator2.default)(function* (queryParams) {
      var _queryParams$rooms;
      if (!(queryParams !== null && queryParams !== void 0 && queryParams.searchTerm)) {
        setErrorCode([_searchContainerHelpers.ERROR_KEYS.invalidLocation, _searchContainerHelpers.ERROR_FIELDS.invalidLocation]);
        return;
      }
      var stayDetailsInfo = (0, _searchContainerHelpers.setSearchLocationInLocalStorage)(queryParams, stayDetailsState);
      setStayDetailsState(stayDetailsState => {
        stayDetailsState.data.info = stayDetailsInfo;
        return stayDetailsState;
      });
      var referrer = {
        data: {
          referrer: router.asPath
        }
      };
      setSearchReferrer(referrer);
      setDistanceFromSearch(_molecules.DISTANCE_FROM_SEARCH_INITIAL_VALUE);
      var paramsMappedForURL = (0, _searchContainerHelpers.mapSearchParamsForURL)(queryParams);
      var roomOccupancyParamsForURL = queryParams === null || queryParams === void 0 || (_queryParams$rooms = queryParams.rooms) === null || _queryParams$rooms === void 0 ? void 0 : _queryParams$rooms.filter(room => room.roomType).map((room, idx) => "ADULT".concat(idx + 1, "=").concat(room.adults, "&CHILD").concat(idx + 1, "=").concat(room.children, "&COT").concat(idx + 1, "=").concat(room.shouldIncludeCot ? 1 : 0, "&INTTYP").concat(idx + 1, "=").concat(mappedRoomLabels[room.roomType])).join('&');
      var URLToRedirect = "ARRdd=".concat(paramsMappedForURL.ARRdd, "&ARRmm=").concat(paramsMappedForURL.ARRmm, "&ARRyyyy=").concat(paramsMappedForURL.ARRyyyy, "&NIGHTS=").concat(paramsMappedForURL.nights, "&ROOMS=").concat(paramsMappedForURL.roomsNumber, "&").concat(roomOccupancyParamsForURL);
      if (CELLCODES) {
        URLToRedirect += "&CELLCODES=".concat(CELLCODES);
      }
      if (CORPID) {
        URLToRedirect += "&CORPID=".concat(CORPID);
      }
      var singleHotelSearchLabels = {
        bookingChannel: partialTranslations.config.api.bookingChannel.leisure,
        mappedRoomLabels: mappedRoomLabels
      };
      window.location = yield (0, _utilities.singleHotelSearchBB)(stayDetailsState, paramsMappedForURL, singleHotelSearchLabels, URLToRedirect, queryParams, country, language, _api.BOOKING_CHANNEL.PI, variant);
    });
    return _handleButtonClick.apply(this, arguments);
  }
  function handleSelectDates(dates) {
    var [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (validationField === _searchContainerHelpers.FIELDS.datepicker || validationField === _searchContainerHelpers.FIELDS.numberOfNights) {
      setErrorCode([undefined, undefined]);
    }
  }
  function handleRoomOccupancyChange() {
    if (validationField === _searchContainerHelpers.FIELDS.occupancy) {
      setErrorCode([undefined, undefined]);
    }
  }
}