"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LanguageLocaleCode = exports.CountryEnum = exports.CountryCode = void 0;
var CountryEnum = exports.CountryEnum = function (CountryEnum) {
  CountryEnum["GB"] = "United Kingdom (the)";
  CountryEnum["DE"] = "Germany";
  return CountryEnum;
}({});
var CountryCode = exports.CountryCode = function (CountryCode) {
  CountryCode["GB"] = "gb";
  CountryCode["DE"] = "de";
  CountryCode["EN"] = "en";
  return CountryCode;
}({});
var LanguageLocaleCode = exports.LanguageLocaleCode = function (LanguageLocaleCode) {
  LanguageLocaleCode["GB"] = "en-GB";
  LanguageLocaleCode["DE"] = "de-DE";
  return LanguageLocaleCode;
}({});