"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ScriptsEmbed;
var _config = _interopRequireDefault(require("next/config"));
var _script = _interopRequireDefault(require("next/script"));
var _jsxRuntime = require("react/jsx-runtime");
function ScriptsEmbed(_ref) {
  var {
    isAmazonChatBoxEnabled
  } = _ref;
  var {
    publicRuntimeConfig
  } = (0, _config.default)();
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_script.default, {
      src: "//assets.adobedtm.com/launch-EN1f330bc46c5949b29c22bbf3f0573f75.min.js",
      strategy: "beforeInteractive",
      "data-testid": "adobe-script"
    }), isAmazonChatBoxEnabled && (0, _jsxRuntime.jsx)(_script.default, {
      src: publicRuntimeConfig.NEXT_PUBLIC_AMAZON_CHAT_URL,
      id: publicRuntimeConfig.NEXT_PUBLIC_AMAZON_CHAT_ID,
      async: true,
      defer: false
    })]
  });
}