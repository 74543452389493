"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RateSelector;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _react = require("react");
var _RateSelector = _interopRequireDefault(require("./RateSelector.component"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function RateSelector(_ref) {
  var _bookingInformation, _previousBookingData$, _previousBookingData$2, _previousBookingData$3, _previousBookingData$4, _previousBookingData$5, _previousBookingData$6, _previousBookingData$7, _previousBookingData$8, _pcksQueryInput$hotel, _pcksQueryInput$booki, _pcksQueryInput$start, _pcksQueryInput$endDa, _pcksQueryInput$night, _pcksQueryInput$adult, _pcksQueryInput$child;
  var {
    channel,
    variant,
    queryClient,
    hotelAvailabilityResponse,
    isParentAnalytics,
    isHotelOpeningSoon,
    arrival,
    departure,
    numberOfUnits,
    numberOfNights,
    isLessThanSm,
    isLessThanMd,
    isLessThanLg,
    prevReservationId,
    mappedRoomLabels,
    isSilentFeatureFlagEnabled,
    thirdParties,
    companyData,
    isPrePopulateBillingAddressEnabled,
    targetRatePlanCode
  } = _ref;
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var {
    brand,
    hotelId,
    bookingFlow,
    contactDetails,
    accessibilityInfo
  } = (0, _utils.useStaticHotelInformation)();
  var {
    [_api.FT_PI_RATE_CARD_ORDERING]: isRateCardOrderingEnabled
  } = (0, _utils.useFeatureToggle)();
  var biQueryInput = {
    basketReference: prevReservationId !== null && prevReservationId !== void 0 ? prevReservationId : '',
    country,
    language,
    bookingChannelCriteria: {
      channel: _api.Channel.Ccui,
      subchannel: 'WEB',
      language: language === 'en' ? 'EN' : 'DE'
    }
  };
  var {
    data: previousBookingData
  } = (0, _utils.useQueryRequest)(['GetBookingInformation', language, country, prevReservationId !== null && prevReservationId !== void 0 ? prevReservationId : ''], _api.GET_BOOKING_INFORMATION, biQueryInput, {
    enabled: !!prevReservationId
  });
  var shouldSavePreviousData = !!prevReservationId && !!(previousBookingData !== null && previousBookingData !== void 0 && (_bookingInformation = previousBookingData.bookingInformation) !== null && _bookingInformation !== void 0 && (_bookingInformation = _bookingInformation.reservationByIdList) !== null && _bookingInformation !== void 0 && _bookingInformation.length) && variant === 'CCUI';
  var pcksQueryInput = shouldSavePreviousData ? {
    country,
    language,
    hotelId: previousBookingData === null || previousBookingData === void 0 || (_previousBookingData$ = previousBookingData.bookingInformation) === null || _previousBookingData$ === void 0 ? void 0 : _previousBookingData$.hotelId,
    adultsNumber: (0, _utils.getMaxValueFromRoomStays)(previousBookingData === null || previousBookingData === void 0 || (_previousBookingData$2 = previousBookingData.bookingInformation) === null || _previousBookingData$2 === void 0 ? void 0 : _previousBookingData$2.reservationByIdList, 'adultsNumber'),
    childrenNumber: (0, _utils.getMaxValueFromRoomStays)(previousBookingData === null || previousBookingData === void 0 || (_previousBookingData$3 = previousBookingData.bookingInformation) === null || _previousBookingData$3 === void 0 ? void 0 : _previousBookingData$3.reservationByIdList, 'childrenNumber'),
    startDate: previousBookingData === null || previousBookingData === void 0 || (_previousBookingData$4 = previousBookingData.bookingInformation) === null || _previousBookingData$4 === void 0 || (_previousBookingData$4 = _previousBookingData$4.reservationByIdList[0]) === null || _previousBookingData$4 === void 0 || (_previousBookingData$4 = _previousBookingData$4.roomStay) === null || _previousBookingData$4 === void 0 ? void 0 : _previousBookingData$4.arrivalDate,
    endDate: previousBookingData === null || previousBookingData === void 0 || (_previousBookingData$5 = previousBookingData.bookingInformation) === null || _previousBookingData$5 === void 0 || (_previousBookingData$5 = _previousBookingData$5.reservationByIdList[0]) === null || _previousBookingData$5 === void 0 || (_previousBookingData$5 = _previousBookingData$5.roomStay) === null || _previousBookingData$5 === void 0 ? void 0 : _previousBookingData$5.departureDate,
    bookingFlowId: previousBookingData === null || previousBookingData === void 0 || (_previousBookingData$6 = previousBookingData.bookingInformation) === null || _previousBookingData$6 === void 0 ? void 0 : _previousBookingData$6.bookingFlowId,
    nightsNumber: (0, _utils.getNightsNumber)(previousBookingData === null || previousBookingData === void 0 || (_previousBookingData$7 = previousBookingData.bookingInformation) === null || _previousBookingData$7 === void 0 ? void 0 : _previousBookingData$7.reservationByIdList[0].roomStay.arrivalDate, previousBookingData === null || previousBookingData === void 0 || (_previousBookingData$8 = previousBookingData.bookingInformation) === null || _previousBookingData$8 === void 0 ? void 0 : _previousBookingData$8.reservationByIdList[0].roomStay.departureDate),
    basketReferenceId: prevReservationId
  } : undefined;
  var {
    data: dataPcks
  } = (0, _utils.useQueryRequest)(['GetPackages', language, country, (_pcksQueryInput$hotel = pcksQueryInput === null || pcksQueryInput === void 0 ? void 0 : pcksQueryInput.hotelId) !== null && _pcksQueryInput$hotel !== void 0 ? _pcksQueryInput$hotel : '', (_pcksQueryInput$booki = pcksQueryInput === null || pcksQueryInput === void 0 ? void 0 : pcksQueryInput.bookingFlowId) !== null && _pcksQueryInput$booki !== void 0 ? _pcksQueryInput$booki : '', (_pcksQueryInput$start = pcksQueryInput === null || pcksQueryInput === void 0 ? void 0 : pcksQueryInput.startDate) !== null && _pcksQueryInput$start !== void 0 ? _pcksQueryInput$start : '', (_pcksQueryInput$endDa = pcksQueryInput === null || pcksQueryInput === void 0 ? void 0 : pcksQueryInput.endDate) !== null && _pcksQueryInput$endDa !== void 0 ? _pcksQueryInput$endDa : '', (_pcksQueryInput$night = pcksQueryInput === null || pcksQueryInput === void 0 ? void 0 : pcksQueryInput.nightsNumber) !== null && _pcksQueryInput$night !== void 0 ? _pcksQueryInput$night : '', (_pcksQueryInput$adult = pcksQueryInput === null || pcksQueryInput === void 0 ? void 0 : pcksQueryInput.adultsNumber) !== null && _pcksQueryInput$adult !== void 0 ? _pcksQueryInput$adult : '', (_pcksQueryInput$child = pcksQueryInput === null || pcksQueryInput === void 0 ? void 0 : pcksQueryInput.childrenNumber) !== null && _pcksQueryInput$child !== void 0 ? _pcksQueryInput$child : '', prevReservationId !== null && prevReservationId !== void 0 ? prevReservationId : ''], _api.GET_PACKAGES, pcksQueryInput, {
    enabled: shouldSavePreviousData && !!(pcksQueryInput !== null && pcksQueryInput !== void 0 && pcksQueryInput.hotelId)
  });
  var {
    isLoading: isLoadingRoomTypeInformation,
    isError: isErrorRoomTypeInformation,
    data: dataRoomTypeInformation,
    error: errorRoomTypeInformation
  } = (0, _utils.useQueryRequest)(['getRoomTypeInformation', language, country, brand, hotelId], _api.GET_ROOM_TYPE_INFORMATION_QUERY, {
    language,
    country,
    brand,
    hotelId
  });
  var {
    isLoading: isLoadingRoomClassConfig,
    isError: isErrorRoomClassConfig,
    data: dataRoomClassConfig,
    error: errorRoomClassConfig
  } = (0, _utils.useQueryRequest)(['getRoomClassConfig', brand, channel, country, language], _api.GET_ROOM_CLASS_CONFIG_QUERY, {
    brand,
    channel,
    country,
    language
  }, {
    enabled: isRateCardOrderingEnabled && variant.toLowerCase() === _api.Area.PI
  });
  var idTokenCookie = (0, _utils.getAuthCookie)();
  var {
    mutation: bookRsvMutation,
    isLoading: bookRsvIsLoading,
    isError: bookRsvIsError,
    data: bookRsvData,
    error: bookRsvError,
    isSuccess: bookRsvIsSuccess
  } = (0, _utils.useMutationRequest)(_api.BOOK_MUTATION, false, ['PI', 'BB'].includes(variant) ? idTokenCookie : undefined);
  var {
    mutation: updateAncillariesMutation,
    isLoading: updateAncillariesIsLoading,
    isError: updateAncillariesIsError,
    error: updateAncillariesError,
    isSuccess: updateAncillariesIsSuccess
  } = (0, _utils.useMutationRequest)(_api.SAVE_RESERVATION);
  var roomTypeInformationResponse = {
    isLoadingRoomTypeInformation,
    isErrorRoomTypeInformation,
    dataRoomTypeInformation,
    errorRoomTypeInformation
  };
  var roomClassConfigResponse = {
    isLoadingRoomClassConfig,
    isErrorRoomClassConfig,
    dataRoomClassConfig,
    errorRoomClassConfig
  };
  var {
    mutation: updateGuestsMutation,
    isLoading: updateGuestsIsLoading,
    isError: updateGuestsIsError,
    error: updateGuestsError,
    isSuccess: updateGuestsSuccess
  } = (0, _utils.useMutationRequest)(_api.CREATE_RESERVATION_GUEST_CCUI);
  var [isDisabledContinueBtn, setIsDisabledContinueBtn] = (0, _react.useState)(false);
  (0, _react.useEffect)(() => {
    setIsDisabledContinueBtn(false);
  }, []);
  (0, _react.useEffect)(() => {
    setIsDisabledContinueBtn(false);
  }, [bookRsvIsError]);
  return (0, _jsxRuntime.jsx)(_RateSelector.default, {
    channel,
    variant,
    isLoading: hotelAvailabilityResponse.isLoadingHotelAvailability,
    isError: hotelAvailabilityResponse.isErrorHotelAvailability,
    data: hotelAvailabilityResponse.dataHotelAvailability,
    error: hotelAvailabilityResponse.errorHotelAvailability,
    queryClient,
    roomTypeInformationResponse: roomTypeInformationResponse,
    roomClassConfigResponse: roomClassConfigResponse,
    isParentAnalytics,
    brand,
    accessibilityInfo,
    bookingFlow,
    bookRsvIsLoading: shouldSavePreviousData ? bookRsvIsLoading && updateAncillariesIsLoading && updateGuestsIsLoading : bookRsvIsLoading,
    bookRsvIsError: shouldSavePreviousData ? bookRsvIsError || updateAncillariesIsError || updateGuestsIsError : bookRsvIsError,
    bookRsvData: bookRsvData,
    bookRsvIsSuccess: shouldSavePreviousData ? bookRsvIsSuccess && updateAncillariesIsSuccess && updateGuestsSuccess : bookRsvIsSuccess,
    bookRsvError: bookRsvError || updateAncillariesError || updateGuestsError,
    handleBooking,
    phoneNumber: contactDetails === null || contactDetails === void 0 ? void 0 : contactDetails.hotelNationalPhone,
    isHotelOpeningSoon,
    isLessThanSm,
    isLessThanMd,
    isLessThanLg,
    isDisabledContinueBtn,
    mappedRoomLabels,
    isSilentFeatureFlagEnabled,
    thirdParties,
    companyData,
    isPrePopulateBillingAddressEnabled,
    basketData: _objectSpread({}, {
      hotelId,
      arrival: arrival,
      departure: departure,
      numberOfUnits: numberOfUnits,
      numberOfNights: numberOfNights
    }),
    targetRatePlanCode: targetRatePlanCode
  });
  function handleGuests(_x, _x2) {
    return _handleGuests.apply(this, arguments);
  }
  function _handleGuests() {
    _handleGuests = (0, _asyncToGenerator2.default)(function* (params, bookingInformation) {
      var _previousBookingData$9, _bookingInformation$r4, _bookingInformation$r5, _bookingInformation2, _previousBookingData$10, _previousBilling$addr, _previousBilling$addr2, _previousBilling$addr3, _previousBilling$addr4, _previousBilling$addr5, _params$createReserva4;
      var previousBilling = previousBookingData === null || previousBookingData === void 0 || (_previousBookingData$9 = previousBookingData.bookingInformation) === null || _previousBookingData$9 === void 0 ? void 0 : _previousBookingData$9.reservationByIdList[0].billing;
      var numberOfRooms = (_bookingInformation$r4 = bookingInformation === null || bookingInformation === void 0 || (_bookingInformation$r5 = bookingInformation.reservationByIdList) === null || _bookingInformation$r5 === void 0 ? void 0 : _bookingInformation$r5.length) !== null && _bookingInformation$r4 !== void 0 ? _bookingInformation$r4 : 0;
      var guests = previousBookingData === null || previousBookingData === void 0 || (_bookingInformation2 = previousBookingData.bookingInformation) === null || _bookingInformation2 === void 0 || (_bookingInformation2 = _bookingInformation2.reservationByIdList) === null || _bookingInformation2 === void 0 ? void 0 : _bookingInformation2.flatMap(reservation => reservation === null || reservation === void 0 ? void 0 : reservation.reservationGuestList);
      if (guests && guests.length > numberOfRooms) {
        guests === null || guests === void 0 || guests.splice(numberOfRooms, guests.length - 1);
      }
      updateGuestsMutation.mutate({
        hotelId: bookingInformation === null || bookingInformation === void 0 ? void 0 : bookingInformation.hotelId,
        reasonForStay: previousBookingData === null || previousBookingData === void 0 || (_previousBookingData$10 = previousBookingData.bookingInformation) === null || _previousBookingData$10 === void 0 || (_previousBookingData$10 = _previousBookingData$10.reservationByIdList[0]) === null || _previousBookingData$10 === void 0 || (_previousBookingData$10 = _previousBookingData$10.additionalGuestInfo) === null || _previousBookingData$10 === void 0 ? void 0 : _previousBookingData$10.purposeOfStay,
        companyName: (_previousBilling$addr = (_previousBilling$addr2 = previousBilling.address) === null || _previousBilling$addr2 === void 0 ? void 0 : _previousBilling$addr2.companyName) !== null && _previousBilling$addr !== void 0 ? _previousBilling$addr : '',
        addressLine1: previousBilling.address.addressLine1,
        addressLine2: previousBilling.address.addressLine2,
        addressLine3: previousBilling.address.addressLine3,
        addressLine4: previousBilling.address.addressLine4,
        addressType: previousBilling !== null && previousBilling !== void 0 && (_previousBilling$addr3 = previousBilling.address) !== null && _previousBilling$addr3 !== void 0 && _previousBilling$addr3.companyName ? 'BUSINESS' : 'HOME',
        cityName: previousBilling === null || previousBilling === void 0 || (_previousBilling$addr4 = previousBilling.address) === null || _previousBilling$addr4 === void 0 ? void 0 : _previousBilling$addr4.cityName,
        countryCode: previousBilling === null || previousBilling === void 0 || (_previousBilling$addr5 = previousBilling.address) === null || _previousBilling$addr5 === void 0 ? void 0 : _previousBilling$addr5.country,
        postalCode: previousBilling.address.postalCode,
        title: previousBilling.title,
        firstName: previousBilling.firstName,
        lastName: previousBilling.lastName,
        emailAddress: previousBilling.email,
        mobile: previousBilling.telephone,
        landline: previousBilling.landline,
        acceptFutureMailing: false,
        basketReference: params === null || params === void 0 || (_params$createReserva4 = params.createReservation) === null || _params$createReserva4 === void 0 ? void 0 : _params$createReserva4.basketReference,
        stayingGuests: guests === null || guests === void 0 ? void 0 : guests.map(guest => {
          var sameAsBooker = guest.givenName === (previousBilling === null || previousBilling === void 0 ? void 0 : previousBilling.firstName) && guest.surName === (previousBilling === null || previousBilling === void 0 ? void 0 : previousBilling.lastName) && guest.nameTitle === (previousBilling === null || previousBilling === void 0 ? void 0 : previousBilling.title);
          return {
            sameAsBooker: sameAsBooker,
            stayingGuestDetails: {
              title: guest === null || guest === void 0 ? void 0 : guest.nameTitle,
              firstName: guest === null || guest === void 0 ? void 0 : guest.givenName,
              lastName: guest === null || guest === void 0 ? void 0 : guest.surName
            }
          };
        })
      });
    });
    return _handleGuests.apply(this, arguments);
  }
  function handleAncillaries(params, bookingInformation) {
    var _bookingInformation$r, _params$createReserva, _bookingInformation$r2, _bookingInformation$r3;
    var previousSelection = bookingInformation === null || bookingInformation === void 0 || (_bookingInformation$r = bookingInformation.reservationByIdList) === null || _bookingInformation$r === void 0 ? void 0 : _bookingInformation$r.map((reservation, index) => {
      var _dataPcks$packages$pa, _dataPcks$packages;
      return {
        reservationId: reservation === null || reservation === void 0 ? void 0 : reservation.reservationId,
        packagesSelection: (_dataPcks$packages$pa = dataPcks === null || dataPcks === void 0 || (_dataPcks$packages = dataPcks.packages) === null || _dataPcks$packages === void 0 || (_dataPcks$packages = _dataPcks$packages.packages) === null || _dataPcks$packages === void 0 || (_dataPcks$packages = _dataPcks$packages.roomSelection[index]) === null || _dataPcks$packages === void 0 ? void 0 : _dataPcks$packages.packagesSelection) !== null && _dataPcks$packages$pa !== void 0 ? _dataPcks$packages$pa : []
      };
    });
    var selectionInfo = {
      basketReferenceId: params === null || params === void 0 || (_params$createReserva = params.createReservation) === null || _params$createReserva === void 0 ? void 0 : _params$createReserva.basketReference,
      hotelId: bookingInformation === null || bookingInformation === void 0 ? void 0 : bookingInformation.hotelId,
      arrivalDate: bookingInformation === null || bookingInformation === void 0 || (_bookingInformation$r2 = bookingInformation.reservationByIdList) === null || _bookingInformation$r2 === void 0 || (_bookingInformation$r2 = _bookingInformation$r2[0]) === null || _bookingInformation$r2 === void 0 || (_bookingInformation$r2 = _bookingInformation$r2.roomStay) === null || _bookingInformation$r2 === void 0 ? void 0 : _bookingInformation$r2.arrivalDate,
      departureDate: bookingInformation === null || bookingInformation === void 0 || (_bookingInformation$r3 = bookingInformation.reservationByIdList) === null || _bookingInformation$r3 === void 0 || (_bookingInformation$r3 = _bookingInformation$r3[0]) === null || _bookingInformation$r3 === void 0 || (_bookingInformation$r3 = _bookingInformation$r3.roomStay) === null || _bookingInformation$r3 === void 0 ? void 0 : _bookingInformation$r3.departureDate,
      roomsSelections: previousSelection,
      previousRoomsSelections: previousSelection
    };
    updateAncillariesMutation.mutate(selectionInfo, {
      onSuccess: () => {
        handleGuests(params, bookingInformation);
      }
    });
  }
  function handleBooking(reservations, bookingChannel) {
    setIsDisabledContinueBtn(true);
    bookRsvMutation.mutate({
      reservations,
      bookingChannel
    }, {
      onSuccess: function () {
        var _onSuccess = (0, _asyncToGenerator2.default)(function* (params) {
          var _params$createReserva2;
          if (params !== null && params !== void 0 && (_params$createReserva2 = params.createReservation) !== null && _params$createReserva2 !== void 0 && _params$createReserva2.basketReference && shouldSavePreviousData) {
            var _params$createReserva3;
            var _biQueryInput = {
              basketReference: params === null || params === void 0 || (_params$createReserva3 = params.createReservation) === null || _params$createReserva3 === void 0 ? void 0 : _params$createReserva3.basketReference,
              country,
              language,
              bookingChannelCriteria: {
                channel: _api.Channel.Ccui,
                subchannel: 'WEB',
                language: language === 'en' ? 'EN' : 'DE'
              }
            };
            var {
              bookingInformation
            } = yield queryClient.fetchQuery({
              queryKey: ['GetBookingInformation', language, country, _biQueryInput.basketReference],
              queryFn: () => (0, _utils.graphQLRequest)(_api.GET_BOOKING_INFORMATION, _biQueryInput)
            });
            handleAncillaries(params, bookingInformation);
          }
        });
        function onSuccess(_x3) {
          return _onSuccess.apply(this, arguments);
        }
        return onSuccess;
      }()
    });
  }
}