"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.dispatchFormSubmitEvent = dispatchFormSubmitEvent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _nextI18next = require("next-i18next");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var SUBMIT_TYPE = function (SUBMIT_TYPE) {
  SUBMIT_TYPE["SAVE"] = "save";
  SUBMIT_TYPE["SUBMIT"] = "submit";
  return SUBMIT_TYPE;
}(SUBMIT_TYPE || {});
var Footer = _ref => {
  var {
    setSubmitType,
    isGerman
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var handleFormSubmit = type => {
    setSubmitType(type);
    dispatchFormSubmitEvent();
  };
  return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, wraperBoxStyles), {}, {
    children: [(0, _jsxRuntime.jsx)(_react.Button, _objectSpread(_objectSpread({}, btnStyles), {}, {
      "data-testid": "submit-reg-form",
      id: "reg-form-save-btn",
      onClick: () => handleFormSubmit(SUBMIT_TYPE.SAVE),
      children: t('precheckin.guestdetails.savebutton')
    })), (0, _jsxRuntime.jsx)(_react.Button, _objectSpread(_objectSpread({}, btnStyles), {}, {
      "data-testid": "reg-form-submit-btn",
      id: "reg-form-submit-btn",
      onClick: () => handleFormSubmit(SUBMIT_TYPE.SUBMIT),
      children: t(isGerman ? 'precheckin.details.submit' : 'precheckin.guestregistration.completebutton')
    }))]
  }));
};
var _default = exports.default = Footer;
var wraperBoxStyles = {
  direction: {
    base: 'column',
    md: 'row'
  },
  alignItems: {
    base: 'center',
    md: 'flex-end'
  },
  justifyContent: {
    base: 'center',
    md: 'flex-end'
  },
  gap: {
    base: '2em',
    md: '2.3em'
  },
  mt: '3xl',
  mb: '0',
  w: {
    base: '100%',
    md: '100%'
  }
};
var btnStyles = {
  w: {
    base: '100%',
    md: '17.72rem'
  }
};
function dispatchFormSubmitEvent() {
  var form = document.getElementById('preCheckInRegistrationForm');
  setTimeout(() => {
    form === null || form === void 0 || form.dispatchEvent(new Event('submit', {
      cancelable: true,
      bubbles: true
    }));
  }, 100);
}