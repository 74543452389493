"use strict";
'use client';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FormPhone = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _utils = require("@whitbread-eos/utils");
var _server = require("@whitbread-eos/utils/server");
var _image = _interopRequireDefault(require("next/image"));
var _react = _interopRequireWildcard(require("react"));
var React = _react;
var _index = require("../../Popover/index");
var _index2 = require("../../Skeleton/index");
var _index3 = require("../../Tooltip/index");
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var FormPhone = exports.FormPhone = React.forwardRef((_ref, ref) => {
  var _errors$name, _errors$name2, _value$phoneNumber;
  var {
    language,
    className,
    name,
    placeholder,
    id,
    disabled = false,
    value,
    onChange = () => {
      return;
    },
    onBlur = () => {
      return;
    },
    onFocus = () => {
      return;
    },
    errors,
    errorIcon,
    arrowIcon
  } = _ref;
  var [options, setOptions] = (0, _react.useState)([]);
  var [selectedOption, setSelectedOption] = (0, _react.useState)();
  var [isLoading, setIsLoading] = (0, _react.useState)(true);
  var [isOpen, setIsOpen] = (0, _react.useState)(false);
  var error = (errors === null || errors === void 0 || (_errors$name = errors[name]) === null || _errors$name === void 0 ? void 0 : _errors$name.phoneNumber) || (errors === null || errors === void 0 || (_errors$name2 = errors[name]) === null || _errors$name2 === void 0 ? void 0 : _errors$name2.prefix);
  var hasError = !!(error !== null && error !== void 0 && error.type);
  var defaultCountry = language === _utils.GLOBALS.language.EN ? _utils.GLOBALS.localeUpper.GB : _utils.GLOBALS.localeUpper.DE;
  (0, _react.useEffect)(() => {
    var fetchListOfCountries = function () {
      var _ref2 = (0, _asyncToGenerator2.default)(function* () {
        var _defaultOption$dialin;
        var countriesList = yield (0, _server.getCountriesList)(language);
        if (!countriesList) {
          return;
        }
        var sortedCountriesList = sortOptions(countriesList);
        setIsLoading(false);
        setOptions(sortedCountriesList);
        var defaultOption = sortedCountriesList.find(option => option.countryCode === defaultCountry);
        onChange(_objectSpread(_objectSpread({}, value), {}, {
          prefix: (_defaultOption$dialin = defaultOption === null || defaultOption === void 0 ? void 0 : defaultOption.dialingCode) !== null && _defaultOption$dialin !== void 0 ? _defaultOption$dialin : ''
        }));
        setSelectedOption(defaultOption);
      });
      return function fetchListOfCountries() {
        return _ref2.apply(this, arguments);
      };
    }();
    if (!disabled) {
      fetchListOfCountries();
    }
  }, []);
  var sortOptions = options => {
    return options.sort((a, b) => {
      if (a.countryCode === defaultCountry) return -1;
      if (b.countryCode === defaultCountry) return 1;
      return a.countryName.localeCompare(b.countryName);
    });
  };
  var handleNumberChange = e => {
    onChange(_objectSpread(_objectSpread({}, value), {}, {
      phoneNumber: e.target.value
    }));
  };
  var handleOptionChange = option => {
    onChange(_objectSpread(_objectSpread({}, value), {}, {
      prefix: option.dialingCode
    }));
    setSelectedOption(option);
    setIsOpen(!isOpen);
  };
  var toggleDropdown = () => {
    if (disabled) return;
    setIsOpen(!isOpen);
  };
  var renderCountrySelect = () => {
    var _selectedOption$flagS, _selectedOption$diali;
    return isLoading ? (0, _jsxRuntime.jsx)(_index2.Skeleton, {
      className: countrySkeletonStyle
    }) : (0, _jsxRuntime.jsx)(_index.PopoverTrigger, {
      asChild: true,
      children: (0, _jsxRuntime.jsxs)("div", {
        className: countrySelectStyle,
        onClick: toggleDropdown,
        children: [(0, _jsxRuntime.jsx)(_image.default, {
          className: (0, _server.cn)(inputIconStyle, disabled ? disabledStyle : ''),
          src: (0, _server.formatIBAssetsUrl)((_selectedOption$flagS = selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.flagSrc) !== null && _selectedOption$flagS !== void 0 ? _selectedOption$flagS : ''),
          alt: 'Country Flag',
          width: 24,
          height: 24,
          unoptimized: true
        }), (0, _jsxRuntime.jsx)("span", {
          className: (0, _server.cn)(selectButtonStyle, disabled ? disabledStyle : ''),
          "data-testid": "".concat(id, "-IB-Form-Select-Button"),
          children: (_selectedOption$diali = selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.dialingCode) !== null && _selectedOption$diali !== void 0 ? _selectedOption$diali : ''
        }), (0, _jsxRuntime.jsx)(_image.default, {
          className: (0, _server.cn)(arrowIconStyle, isOpen ? 'transform -scale-y-100' : '', disabled ? disabledStyle : ''),
          src: arrowIcon,
          alt: 'Iron icon',
          width: 24,
          height: 24
        })]
      })
    });
  };
  var renderButton = (0, _jsxRuntime.jsxs)("div", {
    className: "relative flex justify-center items-center",
    children: [!disabled && renderCountrySelect(), (0, _jsxRuntime.jsx)("input", {
      name: name,
      "data-testid": "".concat(id, "-Form-Input"),
      id: id,
      className: (0, _server.cn)(inputStyle, hasError ? '!outline !outline-2 !outline-error !-outline-offset-2' : '', isOpen ? 'outline outline-primaryColor outline-2 -outline-offset-2' : '', disabled ? 'pl-4' : '', className),
      ref: ref,
      value: (_value$phoneNumber = value === null || value === void 0 ? void 0 : value.phoneNumber) !== null && _value$phoneNumber !== void 0 ? _value$phoneNumber : '',
      disabled: disabled,
      placeholder: placeholder,
      type: "text",
      onChange: handleNumberChange,
      onBlur: onBlur,
      onFocus: onFocus
    }), (0, _jsxRuntime.jsx)("label", {
      htmlFor: id,
      className: (0, _server.cn)(labelStyle, hasError ? '!text-error' : '', value !== null && value !== void 0 && value.phoneNumber || hasError ? '!block' : '', isOpen ? 'text-primaryColor' : ''),
      "data-testid": "".concat(id, "-Label"),
      children: placeholder
    })]
  });
  var renderOptions = () => {
    return options === null || options === void 0 ? void 0 : options.map(option => (0, _jsxRuntime.jsxs)("button", {
      "data-testid": "".concat(id, "-").concat(option.countryCode, "-Option"),
      type: "button",
      className: optionStyle,
      onClick: () => handleOptionChange(option),
      children: [(0, _jsxRuntime.jsx)(_image.default, {
        className: "w-6 h-6",
        src: (0, _server.formatIBAssetsUrl)(option.flagSrc),
        alt: "".concat(option.countryName, " Flag"),
        width: 24,
        height: 24,
        unoptimized: true
      }), (0, _jsxRuntime.jsx)("span", {
        className: "w-[2.85rem]",
        children: option.dialingCode
      }), (0, _jsxRuntime.jsx)("span", {
        children: option.countryName
      })]
    }, option.countryName));
  };
  return (0, _jsxRuntime.jsx)("div", {
    "data-testid": "".concat(id, "-IB-Form-Select"),
    className: "relative",
    children: (0, _jsxRuntime.jsxs)(_index.Popover, {
      open: isOpen,
      onOpenChange: toggleDropdown,
      children: [renderButton, (0, _jsxRuntime.jsx)(_index3.ErrorTooltip, {
        icon: errorIcon,
        content: error === null || error === void 0 ? void 0 : error.message,
        open: hasError,
        testId: "".concat(id, "-Error-Tooltip"),
        className: "!flex",
        mobile: true
      }), (0, _jsxRuntime.jsx)(_index.PopoverContent, {
        align: "start",
        avoidCollisions: false,
        "data-testid": "".concat(id, "-IB-Form-Select-Dropdown"),
        className: popoverStyle,
        children: renderOptions()
      })]
    })
  });
});
FormPhone.displayName = 'Form Select';
var inputIconStyle = 'w-6 h-6 text-transparent z-28';
var labelStyle = 'hidden absolute peer-focus:!block text-darkGrey1 peer-focus:text-primaryColor -top-2.5 left-0 ml-3 text-sm px-1 bg-baseWhite';
var selectButtonStyle = 'flex text-darkGrey1 bg-transparent text-base transition-colors w-[3.2rem]';
var popoverStyle = 'w-full flex flex-col max-h-60 overflow-scroll p-0';
var optionStyle = 'flex items-center gap-4 w-full hover:bg-lightGrey5 text-left text-sm py-2.5 px-4 focus-visible:outline-none';
var arrowIconStyle = 'w-6 h-6 cursor-pointer';
var disabledStyle = 'opacity-50 cursor-not-allowed hover:border-lightGrey1';
var inputStyle = 'peer flex h-14 text-darkGrey1 border-lightGrey1 focus:outline-primaryColor focus:outline-2 hover:border-darkGrey1 w-full rounded border bg-transparent p-4 pl-[8.45rem] text-base transition-colors placeholder:text-darkGrey2 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:border-lightGrey1 bg-baseWhite';
var countrySelectStyle = 'absolute left-0 flex justify-center items-center w-[8.45rem] h-full p-4 gap-[2px] cursor-pointer';
var countrySkeletonStyle = 'absolute left-0 w-[6.375rem] h-6 m-4';