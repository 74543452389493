"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = WhyUs;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _image = _interopRequireDefault(require("next/image"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var baseDataTestId = 'WhyUs';
function WhyUs(_ref) {
  var {
    data
  } = _ref;
  var {
    title,
    description,
    picture,
    whyItems
  } = data;
  var itemsStyle = whyItems.length === 4 ? fourStyle : sixStyle;
  return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, sectionStyle), {}, {
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Wrapper'),
    children: [(0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, leftStyle), {}, {
      children: [(0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
        as: "h2"
      }, headingStyle), {}, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Title'),
        children: title
      })), (0, _jsxRuntime.jsx)(_react.Text, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Description'),
        children: description
      }), whyItems.length > 0 && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, itemsStyle), {}, {
        children: whyItems.map((item, index) => (0, _jsxRuntime.jsx)(WhyUsBox, {
          item: item
        }, "why-us-box-".concat(index)))
      }))]
    })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, pictureStyle), {}, {
      children: (0, _jsxRuntime.jsx)(_image.default, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Image'),
        src: picture,
        alt: title,
        style: imageStyle,
        width: 700,
        height: 600,
        objectFit: "contain"
      })
    }))]
  }));
}
function WhyUsBox(_ref2) {
  var {
    item
  } = _ref2;
  return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, itemStyle), {}, {
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Item'),
    children: [(0, _jsxRuntime.jsx)(_atoms.Icon, {
      src: (0, _utils.formatAssetsUrl)(item === null || item === void 0 ? void 0 : item.itemIcon),
      w: "8"
    }), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, itemTitleStyle), {}, {
      children: item.itemTitle
    })), (0, _jsxRuntime.jsx)(_react.Text, {
      children: item.itemDescription
    })]
  }));
}
var sectionStyle = {
  justifyContent: 'space-between',
  flexDirection: {
    mobile: 'column',
    md: 'row'
  },
  align: 'flex-end',
  p: 'var(--chakra-space-xl) 0',
  alignItems: 'flex-start',
  gap: '1rem'
};
var itemStyle = {
  display: 'flex',
  flexDirection: 'column'
};
var itemTitleStyle = {
  fontSize: '1.1rem',
  fontWeight: '700',
  lineHeight: '1.3rem',
  color: 'tertiary',
  m: 'var(--chakra-space-sm) 0'
};
var leftStyle = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1
};
var sixStyle = {
  display: 'grid',
  gridTemplateColumns: {
    mobile: 'repeat(1, minmax(0, 1fr))',
    sm: 'repeat(3, minmax(0, 1fr))'
  },
  gap: 6,
  mt: 'var(--chakra-space-md)'
};
var fourStyle = {
  display: 'grid',
  gridTemplateColumns: {
    mobile: 'repeat(1, minmax(0, 1fr))',
    sm: 'repeat(2, minmax(0, 1fr))'
  },
  gap: 6,
  mt: 'var(--chakra-space-md)'
};
var pictureStyle = {
  width: '100%',
  flex: 1,
  borderRadius: 'md',
  overflow: 'hidden',
  position: 'relative'
};
var imageStyle = {
  width: '100%',
  height: '100%'
};
var headingStyle = {
  fontSize: '1.8rem',
  fontWeight: '900',
  lineHeight: '2rem',
  color: 'tertiary',
  marginBottom: 'var(--chakra-space-md)'
};