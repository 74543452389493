"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = DestinationFaq;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var baseDataTestId = 'DestinationFaq';
function DestinationFaq(_ref) {
  var {
    data
  } = _ref;
  if (data.length === 0) {
    return null;
  }
  return (0, _jsxRuntime.jsxs)("div", {
    style: sectionStyle,
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Wrapper'),
    children: [(0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
      as: "h2"
    }, headingStyle), {}, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Title'),
      children: "FAQs"
    })), (0, _jsxRuntime.jsx)(_atoms.Tabs, {
      prefixDataTestId: baseDataTestId,
      styles: {
        tab: tabStyles,
        tabList: {}
      },
      options: data.map((faqTab, index) => ({
        index,
        label: faqTab.title,
        content: (0, _jsxRuntime.jsx)(DestinationFaqTab, {
          questions: faqTab.faqItems
        })
      }))
    })]
  });
}
function DestinationFaqTab(_ref2) {
  var {
    questions
  } = _ref2;
  var hasTwoColumns = questions.length > 3;
  var allFaqs = questions.map(q => {
    return {
      title: q.question,
      content: (0, _jsxRuntime.jsx)(_react.Text, {
        children: (0, _utils.renderSanitizedHtml)(q.acceptedAnswer)
      })
    };
  });
  return (0, _jsxRuntime.jsxs)("div", {
    style: accordionStyle,
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Accordions'),
    children: [(0, _jsxRuntime.jsx)(_atoms.Accordion, {
      allowMultiple: false,
      accordionItems: hasTwoColumns ? allFaqs.slice(0, Math.round(allFaqs.length / 2)) : allFaqs,
      bgColor: "var(--chakra-colors-lightGrey5)",
      accordionOverwriteStyles: {
        container: {
          flex: hasTwoColumns ? 1 : 0.66
        }
      }
    }), hasTwoColumns && (0, _jsxRuntime.jsx)(_atoms.Accordion, {
      allowMultiple: false,
      accordionItems: allFaqs.slice(Math.round(allFaqs.length / 2), allFaqs.length),
      bgColor: "var(--chakra-colors-lightGrey5)",
      accordionOverwriteStyles: {
        container: {
          flex: 1
        }
      }
    })]
  });
}
var accordionStyle = {
  display: 'flex',
  flexDirection: 'row',
  gap: 'var(--chakra-space-lg)'
};
var tabStyles = {
  flex: 1,
  padding: 'var(--chakra-space-lg) 0'
};
var sectionStyle = {
  display: 'flex',
  flexDirection: 'column',
  padding: 'var(--chakra-space-xl) 0'
};
var headingStyle = {
  fontSize: '1.8rem',
  fontWeight: '900',
  lineHeight: '2rem',
  color: 'tertiary',
  marginBottom: 'var(--chakra-space-md)'
};