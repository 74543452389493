"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "DestinationFaq", {
  enumerable: true,
  get: function get() {
    return _DestinationFaq.default;
  }
});
Object.defineProperty(exports, "HeroSection", {
  enumerable: true,
  get: function get() {
    return _HeroSection.default;
  }
});
Object.defineProperty(exports, "WhyUs", {
  enumerable: true,
  get: function get() {
    return _WhyUs.default;
  }
});
var _DestinationFaq = _interopRequireDefault(require("./DestinationFaq"));
var _HeroSection = _interopRequireDefault(require("./HeroSection"));
var _WhyUs = _interopRequireDefault(require("./WhyUs"));