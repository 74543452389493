"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = PaymentDetails;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var PAYMENT_DETAILS = {
  PAY_NOW: 'PAY_NOW',
  PAY_ON_ARRIVAL: 'PAY_ON_ARRIVAL',
  RESERVE_WITHOUT_CARD: 'RESERVE_WITHOUT_CARD',
  RESERVE_WITH_CARD: 'RESERVED_WITH_CARD'
};
function PaymentDetails(_ref) {
  var _paymentOptions2;
  var {
    isCCUI,
    hideHeader,
    selectedPaymentType,
    selectedPaymentDetail,
    setSelectedPaymentDetail,
    errorMessagePayment,
    hotelBrand,
    t,
    styles,
    isA2cPaymentPage,
    isLedDesign,
    disablePaymentOptions
  } = _ref;
  var {
    paymentOptions
  } = selectedPaymentType;
  if (isCCUI && !isA2cPaymentPage) {
    var _selectedPaymentType$;
    paymentOptions = (_selectedPaymentType$ = selectedPaymentType === null || selectedPaymentType === void 0 ? void 0 : selectedPaymentType.paymentOptionsAvailable) !== null && _selectedPaymentType$ !== void 0 ? _selectedPaymentType$ : paymentOptions;
  }
  var baseDataTestId = 'CardDetails';
  if (isLedDesign) {
    var _paymentOptions;
    paymentOptions = (_paymentOptions = paymentOptions) === null || _paymentOptions === void 0 ? void 0 : _paymentOptions.filter(option => option.enabled && option.type !== PAYMENT_DETAILS.RESERVE_WITHOUT_CARD);
  }
  var findAndSelectFirstOptionAvailableOfPaymentDetail = () => {
    var _selectedPaymentType$2, _selectedPaymentType$3;
    if (selectedPaymentDetail.type === 'default' || selectedPaymentType !== null && selectedPaymentType !== void 0 && (_selectedPaymentType$2 = selectedPaymentType.paymentOptions) !== null && _selectedPaymentType$2 !== void 0 && _selectedPaymentType$2.some(m => (m === null || m === void 0 ? void 0 : m.type) === selectedPaymentDetail.type && (m === null || m === void 0 ? void 0 : m.enabled) === selectedPaymentDetail.enabled)) {
      return;
    }
    var firstOptionAvailable = selectedPaymentType === null || selectedPaymentType === void 0 || (_selectedPaymentType$3 = selectedPaymentType.paymentOptions) === null || _selectedPaymentType$3 === void 0 ? void 0 : _selectedPaymentType$3.find(m => m === null || m === void 0 ? void 0 : m.enabled);
    if (firstOptionAvailable) {
      setSelectedPaymentDetail(firstOptionAvailable);
    }
  };
  (0, _react2.useEffect)(() => {
    findAndSelectFirstOptionAvailableOfPaymentDetail();
  }, [selectedPaymentType]);
  var isGermanHotel = hotelBrand === _api.HotelBrand.PID;
  return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, _objectSpread(_objectSpread({}, paymentDetailsWrapperStyle), {}, {
    display: !disablePaymentOptions && hotelBrand === _api.HotelBrand.HUB || isLedDesign && paymentOptions && paymentOptions.length < 2 ? 'none' : 'block'
  })), {}, {
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Container'),
    children: [!hideHeader && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Title')
    }, titleStyle), {}, {
      children: isLedDesign ? t('paymentOptions.header') : t('booking.payment.saverHeading')
    })), errorMessagePayment && (0, _jsxRuntime.jsx)(_atoms.Notification, {
      prefixDataTestId: "Payment-Error",
      variant: "error",
      status: "error",
      description: (0, _jsxRuntime.jsx)(_react.Box, {
        children: (0, _utils.renderSanitizedHtml)(errorMessagePayment)
      }),
      svg: (0, _jsxRuntime.jsx)(_atoms.Info, {
        color: "var(--chakra-colors-error)"
      }),
      wrapperStyles: errorNotificationStyle
    }), (selectedPaymentType.name !== 'PIBA' && !isCCUI || selectedPaymentType.type !== _api.PiCardType.NEW_PIBA && !isCCUI) && (0, _jsxRuntime.jsx)(_react.Text, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Disclaimer1'),
      mb: "xl",
      children: isLedDesign ? t('paymentOptions.subtitle') : t('paymentOptions.title')
    }), disablePaymentOptions && (0, _jsxRuntime.jsx)(_atoms.Notification, {
      description: t('paymentOptions.RESERVE_WITHOUT_CARD_NOTIFICATION'),
      maxWidth: "full",
      status: "warning",
      variant: "alert",
      svg: (0, _jsxRuntime.jsx)(_atoms.Alert, {}),
      wrapperStyles: {
        mb: 'var(--chakra-space-lg)'
      }
    }), selectedPaymentType.name !== '' && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread(_objectSpread({}, radioWrapperStyle), styles === null || styles === void 0 ? void 0 : styles.containerStyles), {}, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'RadioContainer'),
      children: (_paymentOptions2 = paymentOptions) === null || _paymentOptions2 === void 0 ? void 0 : _paymentOptions2.map((option, index) => {
        var isRadioChecked = () => {
          var _paymentOptions3, _paymentOptions$find, _paymentOptions4, _paymentOptions5;
          return (selectedPaymentDetail === null || selectedPaymentDetail === void 0 ? void 0 : selectedPaymentDetail.type) === 'default' ? isCCUI && ((_paymentOptions3 = paymentOptions) === null || _paymentOptions3 === void 0 || (_paymentOptions3 = _paymentOptions3.filter(el => el === null || el === void 0 ? void 0 : el.enabled)) === null || _paymentOptions3 === void 0 ? void 0 : _paymentOptions3.length) === 2 ? false : option === ((_paymentOptions$find = (_paymentOptions4 = paymentOptions) === null || _paymentOptions4 === void 0 ? void 0 : _paymentOptions4.find(el => (el === null || el === void 0 ? void 0 : el.type) === PAYMENT_DETAILS.PAY_ON_ARRIVAL && (el === null || el === void 0 ? void 0 : el.enabled))) !== null && _paymentOptions$find !== void 0 ? _paymentOptions$find : (_paymentOptions5 = paymentOptions) === null || _paymentOptions5 === void 0 ? void 0 : _paymentOptions5.find(el => el === null || el === void 0 ? void 0 : el.enabled)) : option && (selectedPaymentDetail === null || selectedPaymentDetail === void 0 ? void 0 : selectedPaymentDetail.type) === PAYMENT_DETAILS[option.type];
        };
        if (isRadioChecked() && option !== null && option !== void 0 && option.type && selectedPaymentDetail !== option) {
          setSelectedPaymentDetail(option);
        }
        return (0, _jsxRuntime.jsx)(_atoms.RadioButton, {
          listIndex: paymentOptions && index === paymentOptions.length - 1 ? 'last' : index,
          onChange: () => setSelectedPaymentDetail(option),
          isChecked: isRadioChecked(),
          isDisabled: !(option !== null && option !== void 0 && option.enabled),
          display: "flex",
          type: option === null || option === void 0 ? void 0 : option.type,
          spacing: "1rem",
          children: (0, _jsxRuntime.jsxs)(_react.Flex, {
            direction: "column",
            children: [(0, _jsxRuntime.jsxs)(_react.Text, {
              color: "darkGrey1",
              fontWeight: isRadioChecked() ? 'semibold' : 'normal',
              "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, "payment-option-".concat(option === null || option === void 0 ? void 0 : option.type)),
              children: [isCCUI && ((option === null || option === void 0 ? void 0 : option.type) === PAYMENT_DETAILS.PAY_NOW ? t("ccui.paymentOption.payNow") : t("ccui.paymentOption.payOnArrival")), !isCCUI && t("paymentOptions.".concat(option === null || option === void 0 ? void 0 : option.type))]
            }), (option === null || option === void 0 ? void 0 : option.type) && !isCCUI && (0, _jsxRuntime.jsx)(_react.Text, {
              "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, "payment-option-".concat(option === null || option === void 0 ? void 0 : option.type, "-desc")),
              children: (option === null || option === void 0 ? void 0 : option.type) === PAYMENT_DETAILS.RESERVE_WITHOUT_CARD && !isGermanHotel ? t("paymentOptions.".concat(option === null || option === void 0 ? void 0 : option.type, "_DESC_GB")) : t("paymentOptions.".concat(option === null || option === void 0 ? void 0 : option.type, "_DESC"))
            })]
          })
        }, option === null || option === void 0 ? void 0 : option.type);
      })
    }))]
  }));
}
var titleStyle = {
  display: 'inline-block',
  fontSize: '2xl',
  fontWeight: 'semibold',
  mb: 'md'
};
var paymentDetailsWrapperStyle = {
  w: {
    mobile: 'full',
    md: '45rem',
    lg: '50.5rem',
    xl: '54rem'
  },
  mb: '5xl'
};
var radioWrapperStyle = {
  w: {
    mobile: 'full',
    sm: '25.063rem',
    md: '27.563rem',
    lg: '24.5rem',
    xl: '26.25rem'
  }
};
var errorNotificationStyle = {
  mt: 'sm',
  mb: 'lg',
  sx: {
    a: {
      fontWeight: 'bold'
    }
  }
};