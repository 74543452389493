"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelCard;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _router = require("next/router");
var _react2 = require("react");
var _constants = require("../constants");
var _utilities = require("../utilities");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var CCUI_VARIANT = 'ccui';
function HotelCard(_ref) {
  var _hotelInformation$lin, _hotelInformation$lin2, _hotelInformation$bra, _hotelInformation$thu, _hotelInformation$thu2, _hotelInformation$thu3, _hotelInformation$thu4, _partialTranslations$, _partialTranslations$2, _partialTranslations$3, _partialTranslations$4, _hotelInformation$hot, _partialTranslations$5, _partialTranslations$6, _partialTranslations$7, _partialTranslations$8, _partialTranslations$9, _partialTranslations$10, _partialTranslations$11, _partialTranslations$12, _partialTranslations$13, _partialTranslations$14, _partialTranslations$15, _partialTranslations$16, _partialTranslations$17, _partialTranslations$18, _partialTranslations$19, _partialTranslations$20, _partialTranslations$21, _partialTranslations$22, _hotelAvailability$di, _hotelInformation$hot2, _hotelAvailability$di2, _hotelAvailability$un, _hotelInformation$hot3, _hotelInformation$mes, _hotelInformation$hot4, _partialTranslations$23, _partialTranslations$24;
  var {
    data,
    multiSearchParams,
    locale,
    partialTranslations,
    roomTypes,
    variant
  } = _ref;
  var router = (0, _router.useRouter)();
  var {
    isLessThanMd,
    isLessThanSm
  } = (0, _utils.useScreenSize)();
  var {
    reservationId
  } = router.query;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var [, setDistanceFromSearch] = (0, _utils.useLocalStorage)('DistanceFromSearch', _molecules.DISTANCE_FROM_SEARCH_INITIAL_VALUE);
  var [, setSearchReferrer] = (0, _utils.useLocalStorage)('SearchReferrer', _molecules.SEARCH_REFERRER_INITIAL_VALUE);
  var baseDataTestId = 'SRP';
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var hotelInformation = data === null || data === void 0 ? void 0 : data.hotelInformation;
  var hotelAvailability = data === null || data === void 0 ? void 0 : data.hotelAvailability;
  var hotelSlug = (_hotelInformation$lin = hotelInformation === null || hotelInformation === void 0 || (_hotelInformation$lin2 = hotelInformation.links) === null || _hotelInformation$lin2 === void 0 ? void 0 : _hotelInformation$lin2.detailsPage) !== null && _hotelInformation$lin !== void 0 ? _hotelInformation$lin : '';
  var hotelBrand = (_hotelInformation$bra = hotelInformation === null || hotelInformation === void 0 ? void 0 : hotelInformation.brand) !== null && _hotelInformation$bra !== void 0 ? _hotelInformation$bra : '';
  var searchQueryUrl = (0, _utilities.getSearchQueryUrl)(router, _constants.IGNORED_KEYS_FOR_HDP_URL, _utilities.formatDateSearchQueryUrl);
  var URLToRedirect = "/".concat(country, "/").concat(language).concat(variant === 'bb' ? '/business-booker' : '', "/hotels").concat(hotelSlug, ".html?").concat(searchQueryUrl).concat(reservationId && variant === CCUI_VARIANT ? "&reservationId=".concat(reservationId) : '', "&BRAND=").concat(hotelBrand);
  var imageURLHotelExterior = "".concat((_hotelInformation$thu = hotelInformation === null || hotelInformation === void 0 || (_hotelInformation$thu2 = hotelInformation.thumbnailImages) === null || _hotelInformation$thu2 === void 0 || (_hotelInformation$thu2 = _hotelInformation$thu2[0]) === null || _hotelInformation$thu2 === void 0 ? void 0 : _hotelInformation$thu2.imageSrc) !== null && _hotelInformation$thu !== void 0 ? _hotelInformation$thu : '/');
  var imageAltText = "".concat(data === null || data === void 0 ? void 0 : data.name, " ").concat((_hotelInformation$thu3 = hotelInformation === null || hotelInformation === void 0 || (_hotelInformation$thu4 = hotelInformation.thumbnailImages) === null || _hotelInformation$thu4 === void 0 || (_hotelInformation$thu4 = _hotelInformation$thu4[0]) === null || _hotelInformation$thu4 === void 0 ? void 0 : _hotelInformation$thu4.tags[0]) !== null && _hotelInformation$thu3 !== void 0 ? _hotelInformation$thu3 : 'exterior');
  var hotelBrandLogos = {
    hubLogo: (_partialTranslations$ = partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$2 = partialTranslations.searchInformation) === null || _partialTranslations$2 === void 0 || (_partialTranslations$2 = _partialTranslations$2.content) === null || _partialTranslations$2 === void 0 || (_partialTranslations$2 = _partialTranslations$2.global) === null || _partialTranslations$2 === void 0 || (_partialTranslations$2 = _partialTranslations$2.brand) === null || _partialTranslations$2 === void 0 ? void 0 : _partialTranslations$2.hubBadge) !== null && _partialTranslations$ !== void 0 ? _partialTranslations$ : '',
    zipLogo: (_partialTranslations$3 = partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$4 = partialTranslations.searchInformation) === null || _partialTranslations$4 === void 0 || (_partialTranslations$4 = _partialTranslations$4.content) === null || _partialTranslations$4 === void 0 || (_partialTranslations$4 = _partialTranslations$4.global) === null || _partialTranslations$4 === void 0 || (_partialTranslations$4 = _partialTranslations$4.brand) === null || _partialTranslations$4 === void 0 ? void 0 : _partialTranslations$4.zipBadge) !== null && _partialTranslations$3 !== void 0 ? _partialTranslations$3 : ''
  };
  var hotelOpeningDate = (_hotelInformation$hot = hotelInformation === null || hotelInformation === void 0 ? void 0 : hotelInformation.hotelOpeningDate) !== null && _hotelInformation$hot !== void 0 ? _hotelInformation$hot : '';
  var isHotelOpeningSoon = (0, _react2.useMemo)(() => (0, _utilities.hotelOpensSoon)(hotelOpeningDate, multiSearchParams), [hotelOpeningDate, multiSearchParams]);
  var isHotelSoldOut = !(hotelAvailability !== null && hotelAvailability !== void 0 && hotelAvailability.available) && !isHotelOpeningSoon;
  var hasMlosRestriction = hotelAvailability === null || hotelAvailability === void 0 ? void 0 : hotelAvailability.hasMlosRestriction;
  var lowestRoomRate = hotelAvailability === null || hotelAvailability === void 0 ? void 0 : hotelAvailability.lowestRoomRate;
  var hotelLimitedAvailability = hotelAvailability === null || hotelAvailability === void 0 ? void 0 : hotelAvailability.limitedAvailability;
  var cellCodesArr = multiSearchParams === null || multiSearchParams === void 0 ? void 0 : multiSearchParams.cellCodes;
  var isCellCodeMatched = cellCodesArr === null || cellCodesArr === void 0 ? void 0 : cellCodesArr.some(item => {
    var _data$hotelAvailabili;
    return item === (data === null || data === void 0 || (_data$hotelAvailabili = data.hotelAvailability) === null || _data$hotelAvailabili === void 0 ? void 0 : _data$hotelAvailabili.cellCode);
  });
  var isDiscountApplied = variant === _api.Area.PI && isCellCodeMatched && (hotelAvailability === null || hotelAvailability === void 0 ? void 0 : hotelAvailability.available) && !isHotelOpeningSoon;
  var hotelLastFewRoomsLabel = (_partialTranslations$5 = partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$6 = partialTranslations.searchInformation) === null || _partialTranslations$6 === void 0 || (_partialTranslations$6 = _partialTranslations$6.content) === null || _partialTranslations$6 === void 0 || (_partialTranslations$6 = _partialTranslations$6.results) === null || _partialTranslations$6 === void 0 || (_partialTranslations$6 = _partialTranslations$6.result) === null || _partialTranslations$6 === void 0 ? void 0 : _partialTranslations$6.availabilityWarning) !== null && _partialTranslations$5 !== void 0 ? _partialTranslations$5 : '';
  var hotelDistanceLabels = {
    distanceUnitPlural: (_partialTranslations$7 = partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$8 = partialTranslations.searchInformation) === null || _partialTranslations$8 === void 0 || (_partialTranslations$8 = _partialTranslations$8.content) === null || _partialTranslations$8 === void 0 || (_partialTranslations$8 = _partialTranslations$8.results) === null || _partialTranslations$8 === void 0 || (_partialTranslations$8 = _partialTranslations$8.result) === null || _partialTranslations$8 === void 0 ? void 0 : _partialTranslations$8.distanceUnitPlural) !== null && _partialTranslations$7 !== void 0 ? _partialTranslations$7 : '',
    fromLocation: (_partialTranslations$9 = partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$10 = partialTranslations.searchInformation) === null || _partialTranslations$10 === void 0 || (_partialTranslations$10 = _partialTranslations$10.content) === null || _partialTranslations$10 === void 0 || (_partialTranslations$10 = _partialTranslations$10.results) === null || _partialTranslations$10 === void 0 || (_partialTranslations$10 = _partialTranslations$10.result) === null || _partialTranslations$10 === void 0 ? void 0 : _partialTranslations$10.fromLocation) !== null && _partialTranslations$9 !== void 0 ? _partialTranslations$9 : ''
  };
  var lowestRoomLabels = {
    priceFrom: (_partialTranslations$11 = partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$12 = partialTranslations.searchInformation) === null || _partialTranslations$12 === void 0 || (_partialTranslations$12 = _partialTranslations$12.content) === null || _partialTranslations$12 === void 0 || (_partialTranslations$12 = _partialTranslations$12.results) === null || _partialTranslations$12 === void 0 || (_partialTranslations$12 = _partialTranslations$12.result) === null || _partialTranslations$12 === void 0 ? void 0 : _partialTranslations$12.priceFrom) !== null && _partialTranslations$11 !== void 0 ? _partialTranslations$11 : ''
  };
  var hotelBadgesLabels = {
    premierPlus: (_partialTranslations$13 = partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$14 = partialTranslations.searchInformation) === null || _partialTranslations$14 === void 0 || (_partialTranslations$14 = _partialTranslations$14.content) === null || _partialTranslations$14 === void 0 || (_partialTranslations$14 = _partialTranslations$14.results) === null || _partialTranslations$14 === void 0 || (_partialTranslations$14 = _partialTranslations$14.result) === null || _partialTranslations$14 === void 0 || (_partialTranslations$14 = _partialTranslations$14.facilities) === null || _partialTranslations$14 === void 0 ? void 0 : _partialTranslations$14.premierPlusRoom) !== null && _partialTranslations$13 !== void 0 ? _partialTranslations$13 : '',
    openingSoon: (_partialTranslations$15 = partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$16 = partialTranslations.searchInformation) === null || _partialTranslations$16 === void 0 || (_partialTranslations$16 = _partialTranslations$16.content) === null || _partialTranslations$16 === void 0 || (_partialTranslations$16 = _partialTranslations$16.results) === null || _partialTranslations$16 === void 0 || (_partialTranslations$16 = _partialTranslations$16.result) === null || _partialTranslations$16 === void 0 ? void 0 : _partialTranslations$16.openingSoon) !== null && _partialTranslations$15 !== void 0 ? _partialTranslations$15 : '',
    mlos: t('search.mlos.tag')
  };
  var hotelSoldOutLabel = (_partialTranslations$17 = partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$18 = partialTranslations.searchInformation) === null || _partialTranslations$18 === void 0 || (_partialTranslations$18 = _partialTranslations$18.content) === null || _partialTranslations$18 === void 0 || (_partialTranslations$18 = _partialTranslations$18.results) === null || _partialTranslations$18 === void 0 || (_partialTranslations$18 = _partialTranslations$18.result) === null || _partialTranslations$18 === void 0 ? void 0 : _partialTranslations$18.fullyBooked) !== null && _partialTranslations$17 !== void 0 ? _partialTranslations$17 : '';
  var hotelButtonlabel = (_partialTranslations$19 = partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$20 = partialTranslations.searchInformation) === null || _partialTranslations$20 === void 0 || (_partialTranslations$20 = _partialTranslations$20.content) === null || _partialTranslations$20 === void 0 || (_partialTranslations$20 = _partialTranslations$20.results) === null || _partialTranslations$20 === void 0 || (_partialTranslations$20 = _partialTranslations$20.result) === null || _partialTranslations$20 === void 0 ? void 0 : _partialTranslations$20.viewDetails) !== null && _partialTranslations$19 !== void 0 ? _partialTranslations$19 : '';
  var hotelOfferLabel = (_partialTranslations$21 = partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$22 = partialTranslations.searchInformation) === null || _partialTranslations$22 === void 0 || (_partialTranslations$22 = _partialTranslations$22.content) === null || _partialTranslations$22 === void 0 || (_partialTranslations$22 = _partialTranslations$22.results) === null || _partialTranslations$22 === void 0 || (_partialTranslations$22 = _partialTranslations$22.result) === null || _partialTranslations$22 === void 0 || (_partialTranslations$22 = _partialTranslations$22.promotions) === null || _partialTranslations$22 === void 0 ? void 0 : _partialTranslations$22.discountApplied) !== null && _partialTranslations$21 !== void 0 ? _partialTranslations$21 : '';
  var distance = (_hotelAvailability$di = hotelAvailability === null || hotelAvailability === void 0 ? void 0 : hotelAvailability.distance) !== null && _hotelAvailability$di !== void 0 ? _hotelAvailability$di : 0;
  var srchReferrer = router === null || router === void 0 ? void 0 : router.asPath;
  return (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, wrapperStyles), {}, {
    children: (0, _jsxRuntime.jsx)(_react.Link, _objectSpread(_objectSpread({
      href: URLToRedirect
    }, linkStyles), {}, {
      onClick: () => handleCardClick(distance, srchReferrer),
      children: (0, _jsxRuntime.jsxs)(_atoms.Card, _objectSpread(_objectSpread({}, cardContainerStyles), {}, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'hotel-card'),
        children: [(0, _jsxRuntime.jsxs)(_react.Flex, {
          direction: "column",
          children: [(0, _jsxRuntime.jsx)(_molecules.SRHotelThumbnail, {
            imageData: {
              imageSrc: imageURLHotelExterior,
              imageAlt: imageAltText
            },
            brand: hotelBrand,
            brandLogos: hotelBrandLogos,
            testId: (0, _utils.formatDataTestId)(baseDataTestId, 'hotel-thumbnail'),
            styles: hotelThumbnailWrapperStyles
          }), isLessThanMd && !isLessThanSm && (0, _jsxRuntime.jsx)(_molecules.SRHotelFacilities, {
            facilities: (_hotelInformation$hot2 = hotelInformation === null || hotelInformation === void 0 ? void 0 : hotelInformation.hotelFacilities) !== null && _hotelInformation$hot2 !== void 0 ? _hotelInformation$hot2 : [],
            roomTypes: roomTypes
          })]
        }), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, hotelDetailsBoxStyles), {}, {
          children: [(0, _jsxRuntime.jsxs)(_react.Flex, {
            alignItems: "center",
            children: [(0, _jsxRuntime.jsx)(_molecules.SRHotelTitle, {
              title: data.name,
              testId: (0, _utils.formatDataTestId)(baseDataTestId, 'hotel-title'),
              styles: titleStyles
            }), variant === CCUI_VARIANT && (0, _jsxRuntime.jsx)(_molecules.SRHotelBadges, {
              testId: (0, _utils.formatDataTestId)(baseDataTestId, 'hotel-badge'),
              styles: hotelBadgeStyles
            })]
          }), (0, _jsxRuntime.jsx)(_molecules.SRHotelDistance, {
            distance: (_hotelAvailability$di2 = hotelAvailability === null || hotelAvailability === void 0 ? void 0 : hotelAvailability.distance) !== null && _hotelAvailability$di2 !== void 0 ? _hotelAvailability$di2 : 0,
            unit: (_hotelAvailability$un = hotelAvailability === null || hotelAvailability === void 0 ? void 0 : hotelAvailability.unit) !== null && _hotelAvailability$un !== void 0 ? _hotelAvailability$un : '',
            labels: hotelDistanceLabels,
            testId: (0, _utils.formatDataTestId)(baseDataTestId, 'hotel-distance'),
            styles: isLessThanMd ? hotelDistanceTextStylesSmall : hotelDistanceTextStyles
          }), (0, _jsxRuntime.jsx)(_molecules.SRHotelBadges, {
            hasMlosRestriction: hasMlosRestriction,
            hotelOpeningDate: hotelOpeningDate,
            labels: hotelBadgesLabels,
            testId: (0, _utils.formatDataTestId)(baseDataTestId, 'hotel-badges'),
            isHotelOpeningSoon: isHotelOpeningSoon,
            hotelFacilities: (_hotelInformation$hot3 = hotelInformation === null || hotelInformation === void 0 ? void 0 : hotelInformation.hotelFacilities) !== null && _hotelInformation$hot3 !== void 0 ? _hotelInformation$hot3 : [],
            messagingFlag: (_hotelInformation$mes = hotelInformation === null || hotelInformation === void 0 ? void 0 : hotelInformation.messagingFlag) !== null && _hotelInformation$mes !== void 0 ? _hotelInformation$mes : {},
            styles: hotelBadgesStyles
          }), (!isLessThanMd || isLessThanSm) && (0, _jsxRuntime.jsx)(_molecules.SRHotelFacilities, {
            facilities: (_hotelInformation$hot4 = hotelInformation === null || hotelInformation === void 0 ? void 0 : hotelInformation.hotelFacilities) !== null && _hotelInformation$hot4 !== void 0 ? _hotelInformation$hot4 : [],
            roomTypes: roomTypes
          })]
        })), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, hotelLabelsPriceBoxStyles), {}, {
          children: [(0, _jsxRuntime.jsxs)(_react.Box, {
            children: [(partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$23 = partialTranslations.searchInformation) === null || _partialTranslations$23 === void 0 || (_partialTranslations$23 = _partialTranslations$23.content) === null || _partialTranslations$23 === void 0 || (_partialTranslations$23 = _partialTranslations$23.results) === null || _partialTranslations$23 === void 0 ? void 0 : _partialTranslations$23.result) && isHotelOpeningSoon && (0, _jsxRuntime.jsx)(_molecules.SRHotelOpeningInformation, {
              hotelOpeningDate: hotelOpeningDate,
              labels: partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$24 = partialTranslations.searchInformation) === null || _partialTranslations$24 === void 0 || (_partialTranslations$24 = _partialTranslations$24.content) === null || _partialTranslations$24 === void 0 || (_partialTranslations$24 = _partialTranslations$24.results) === null || _partialTranslations$24 === void 0 ? void 0 : _partialTranslations$24.result,
              testId: (0, _utils.formatDataTestId)(baseDataTestId, 'hotel-opening-information')
            }), hotelLimitedAvailability && (0, _jsxRuntime.jsx)(_molecules.SRHotelLastFewRooms, {
              label: hotelLastFewRoomsLabel,
              testId: (0, _utils.formatDataTestId)(baseDataTestId, 'hotel-last-few-rooms')
            }), isDiscountApplied && (0, _jsxRuntime.jsx)(_molecules.SRHotelDiscountApplied, {
              label: hotelOfferLabel,
              testId: (0, _utils.formatDataTestId)(baseDataTestId, 'hotel-offer-discount')
            }), isHotelSoldOut && (0, _jsxRuntime.jsx)(_molecules.SRHotelSoldOut, {
              label: hotelSoldOutLabel,
              testId: (0, _utils.formatDataTestId)(baseDataTestId, 'hotel-soldout'),
              styles: hotelSoldOutStyles
            })]
          }), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, hotelPriceButtonBoxStyles), {}, {
            children: [(0, _jsxRuntime.jsx)(_react.Box, {
              children: lowestRoomRate && !isHotelOpeningSoon && (0, _jsxRuntime.jsx)(_molecules.SRHotelLowestRate, {
                data: lowestRoomRate,
                locale: locale,
                labels: lowestRoomLabels,
                testId: (0, _utils.formatDataTestId)(baseDataTestId, 'lowest-rate'),
                styles: {
                  containerStyles: hotelLowestRateContainerStyles,
                  labelStyles: hotelLowestRateLabelStyles,
                  priceStyles: hotelLowestRatePriceStyles
                }
              })
            }), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, hotelButtonBoxStyles), {}, {
              "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'hotel-button'),
              children: (0, _jsxRuntime.jsxs)(_atoms.Button, _objectSpread(_objectSpread({
                size: isLessThanMd ? 'xxs' : 'xsm',
                variant: isLessThanMd ? 'circle' : 'primary'
              }, buttonStyles), {}, {
                children: [isLessThanMd && (0, _jsxRuntime.jsx)(_atoms.Icon, {
                  svg: (0, _jsxRuntime.jsx)(_atoms.ChevronRight, {
                    color: "var(--chakra-colors-baseWhite)"
                  })
                }), !isLessThanMd && hotelButtonlabel]
              }))
            }))]
          }))]
        }))]
      }))
    }))
  }));
  function handleCardClick(distance, srchReferrer) {
    var distanceFromSearch = {
      data: {
        distance: distance
      }
    };
    setDistanceFromSearch(distanceFromSearch);
    var referrer = {
      data: {
        referrer: srchReferrer
      }
    };
    setSearchReferrer(referrer);
  }
}
var wrapperStyles = {
  m: 'var(--chakra-space-lg) auto',
  w: {
    mobile: 'full',
    md: '45rem',
    lg: '50.5rem',
    xl: '54rem'
  }
};
var linkStyles = {
  cursor: 'default',
  _hover: {
    textDecoration: 'none'
  },
  _focus: {
    textDecoration: 'none',
    boxShadow: 'none'
  },
  _visited: {
    textDecoration: 'none'
  },
  _link: {
    textDecoration: 'none'
  },
  _active: {
    textDecoration: 'none'
  }
};
var cardContainerStyles = {
  bgColor: 'baseWhite',
  flexDirection: {
    mobile: 'column',
    sm: 'row'
  },
  w: 'full',
  h: 'auto',
  minH: '8.125rem',
  border: '1px solid var(--chakra-colors-lightGrey4)',
  borderRadius: 'var(--chakra-space-radiusSmall)',
  boxShadow: 'none',
  margin: 'var(--chakra-space-sm) auto 0',
  padding: {
    mobile: 0,
    sm: 'md'
  },
  _hover: {
    cursor: 'pointer',
    boxShadow: '0 0 var(--chakra-space-sm) 0 var(--chakra-colors-lightGrey2)',
    transitionProperty: 'border, box-shadow',
    transitionDuration: '0s, 0s',
    transitionTimingFunction: 'ease, ease',
    transitionDelay: '0s, 0s'
  }
};
var titleStyles = {
  fontSize: 'md',
  lineHeight: 'var(--chakra-lineHeights-3)',
  fontWeight: 'semibold',
  color: 'darkGrey1'
};
var hotelThumbnailWrapperStyles = {
  w: {
    mobile: 'full',
    sm: '7.5rem',
    md: '11.75rem'
  },
  h: {
    mobile: '10.188rem',
    xs: '12.375rem',
    sm: '4.313rem',
    md: '7.25rem'
  },
  mb: {
    base: '0',
    sm: '0.875rem',
    md: '0'
  }
};
var hotelDistanceTextStyles = {
  fontSize: 'sm',
  fontWeight: 'normal',
  lineHeight: '2',
  paddingTop: {
    mobile: 'sm',
    sm: 'xs'
  },
  paddingBottom: 'sm'
};
var hotelDistanceTextStylesSmall = _objectSpread(_objectSpread({}, hotelDistanceTextStyles), {}, {
  flexGrow: 2
});
var hotelLowestRateContainerStyles = {
  pt: {
    sm: 'sm'
  },
  pb: {
    md: 'md'
  },
  flexDirection: {
    mobile: 'column',
    md: 'row'
  }
};
var hotelLowestRateLabelStyles = {
  fontSize: 'sm',
  lineHeight: '2',
  fontWeight: 'normal',
  color: 'darkGrey1',
  alignSelf: {
    mobile: 'flex-start',
    md: 'flex-end'
  },
  pr: 'xs'
};
var hotelLowestRatePriceStyles = {
  color: 'darkGrey1',
  fontWeight: 'bold',
  lineHeight: '3',
  fontSize: {
    mobile: 'lg',
    sm: 'xl'
  }
};
var hotelDetailsBoxStyles = {
  px: {
    mobile: 'sm',
    sm: 'md'
  },
  py: {
    mobile: 'sm',
    sm: 0
  },
  w: {
    mobile: '100%',
    sm: '16.3125rem',
    md: '19.6875rem',
    lg: '25.6875rem',
    xl: '29.1875rem'
  },
  flexDirection: 'column'
};
var hotelLabelsPriceBoxStyles = {
  marginLeft: 'auto',
  borderLeft: {
    sm: '0.0625rem solid var(--chakra-colors-lightGrey4)'
  },
  w: {
    mobile: '100%',
    sm: '8.875rem',
    md: '12.5rem',
    lg: '12rem'
  },
  px: {
    mobile: 'sm',
    sm: 0
  },
  pl: {
    sm: 'md'
  },
  mr: {
    lg: '-md'
  },
  pb: {
    mobile: 'md',
    sm: '0'
  },
  flexDirection: {
    mobile: 'row',
    sm: 'column'
  },
  justifyContent: {
    mobile: 'space-between',
    sm: 'flex-end'
  },
  alignItems: {
    mobile: 'center',
    sm: 'initial'
  }
};
var hotelPriceButtonBoxStyles = {
  flexDirection: {
    mobile: 'row',
    md: 'column'
  },
  justifyContent: {
    mobile: 'space-between'
  }
};
var hotelButtonBoxStyles = {
  pl: {
    mobile: 'sm',
    md: '0'
  },
  pr: {
    mobile: '0'
  },
  alignSelf: {
    mobile: 'end',
    md: 'start'
  }
};
var buttonStyles = {
  bgColor: 'primary',
  _hover: {
    bg: 'var(--chakra-colors-btnPrimaryHoverBg) radial-gradient(circle, transparent 1%, var(--chakra-colors-btnPrimaryHoverBg) 1%) center/15000%',
    boxShadow: '0 4px 8px var(--chakra-colors-lightGrey1)',
    _disabled: {
      bg: 'lightGrey3'
    }
  }
};
var hotelBadgesStyles = {
  mb: {
    mobile: 'sm',
    sm: '0',
    md: 'sm'
  }
};
var hotelBadgeStyles = {
  ml: 'sm'
};
var hotelSoldOutStyles = {
  mb: {
    mobile: '0',
    sm: 'md'
  }
};