"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RateCard;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _image = _interopRequireDefault(require("next/image"));
var _link = _interopRequireDefault(require("next/link"));
var _react2 = require("react");
var _SilentSubstitutionNotification = _interopRequireDefault(require("../../hotel-details/Notifications/SilentSubstitutionNotification"));
var _RateItem = _interopRequireDefault(require("../RateItem"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function RateCard(_ref) {
  var {
    brand,
    channel,
    roomClassCode,
    roomClass,
    pmsRoomType,
    roomRates,
    numberOfNights,
    numberOfUnits,
    roomTypes,
    showAdditionalInfo,
    rateClassifications,
    selectedRoomClassAndRate,
    setSelectedRoomClassAndRate,
    roomTypeInformationResponse,
    isLessThanSm,
    isLessThanMd,
    roomRatesThatMatchRoomClassifications
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var {
    [_api.FT_PI_BB_CCUI_NONSILENT_SUBSTITUTION_PER_ROOMCLASS]: isNonSilentSubstituNotificPerRoomClassEnabled
  } = (0, _utils.useFeatureToggle)();
  var roomRatesWithRateInformation = (0, _react2.useMemo)(() => getRoomRatesWithRateInformation(roomRatesThatMatchRoomClassifications, rateClassifications), [roomRatesThatMatchRoomClassifications, rateClassifications]);
  var isBB = (channel === null || channel === void 0 ? void 0 : channel.toLowerCase()) === _api.Area.BB;
  var MIN_SHOWN_ROOM_RATES = isBB ? 4 : roomRatesWithRateInformation.length;
  var [showRates, setShowRates] = (0, _react2.useState)(MIN_SHOWN_ROOM_RATES);
  if (roomTypeInformationResponse.isLoadingRoomTypeInformation) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: t('searchresults.list.hotel.loading')
    });
  }
  if (roomTypeInformationResponse.isErrorRoomTypeInformation) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: roomTypeInformationResponse.errorRoomTypeInformation.message
    });
  }
  var roomClassInformation = roomTypeInformationResponse.dataRoomTypeInformation.roomTypeInformation.roomTypes.find(roomClassInfo => roomClassInfo.roomTypeCode === pmsRoomType || roomClassInfo.roomTypeCode.includes(pmsRoomType));
  var descriptionNoOfLinesBigScreens = isLessThanMd ? 3 : undefined;
  var handleShowMoreRates = () => {
    if (showRates < roomRatesWithRateInformation.length) {
      setShowRates(roomRatesWithRateInformation.length);
    } else {
      setShowRates(MIN_SHOWN_ROOM_RATES);
    }
  };
  var showMoreRatesMsg = "".concat(t('pihotelinfo.show'), " ").concat((roomRatesWithRateInformation === null || roomRatesWithRateInformation === void 0 ? void 0 : roomRatesWithRateInformation.length) - MIN_SHOWN_ROOM_RATES, " ").concat(t('pihotelinfo.moreRates'));
  var getFirstRoomRateDataPerRoomClass = () => {
    var _roomRates$0$roomType;
    if (!(roomRates !== null && roomRates !== void 0 && roomRates.length)) return [];
    return ((_roomRates$0$roomType = roomRates[0].roomTypes) === null || _roomRates$0$roomType === void 0 ? void 0 : _roomRates$0$roomType.flatMap(roomType => {
      var _roomType$rooms;
      return (_roomType$rooms = roomType.rooms) === null || _roomType$rooms === void 0 ? void 0 : _roomType$rooms.filter(room => room.roomClass === roomClassCode && !room.silentSubstitution).map(filteredRoom => ({
        silentSubstitution: filteredRoom.silentSubstitution,
        roomLabelCode: filteredRoom.pmsRoomType
      }));
    })) || [];
  };
  return (0, _jsxRuntime.jsxs)(_react.Box, {
    mb: "2xl",
    children: [isNonSilentSubstituNotificPerRoomClassEnabled && renderNonSilentSubstitutionNotificationPerRoomClass(), (0, _jsxRuntime.jsx)(_atoms.Card, {
      children: (0, _jsxRuntime.jsxs)(_react.VStack, {
        w: "full",
        py: {
          base: '0',
          sm: 'lg'
        },
        px: {
          base: '0',
          sm: 'xl'
        },
        "data-testid": "hdp_rateCard",
        children: [renderRateCardDescription(), (0, _jsxRuntime.jsxs)(_react.Box, {
          w: "full",
          children: [(0, _jsxRuntime.jsx)(_react.VStack, {
            children: roomRatesWithRateInformation.slice(0, showRates).map((roomRate, index) => {
              var _roomRate$roomTypes;
              var rateIndexFromAvailability = roomRates.findIndex(rate => rate.ratePlanCode === roomRate.ratePlanCode);
              var totalReservationAmount = roomRate === null || roomRate === void 0 || (_roomRate$roomTypes = roomRate.roomTypes) === null || _roomRate$roomTypes === void 0 || (_roomRate$roomTypes = _roomRate$roomTypes.map(roomType => {
                var _roomType$rooms$filte;
                return (_roomType$rooms$filte = roomType.rooms.filter(room => room.roomClass === roomClassCode)[0]) === null || _roomType$rooms$filte === void 0 || (_roomType$rooms$filte = _roomType$rooms$filte.roomPriceBreakdown) === null || _roomType$rooms$filte === void 0 ? void 0 : _roomType$rooms$filte.totalNetAmount;
              })) === null || _roomRate$roomTypes === void 0 ? void 0 : _roomRate$roomTypes.reduce((sum, pricePerRoomType) => sum + pricePerRoomType, 0);
              return (0, _jsxRuntime.jsxs)(_react.Box, {
                w: "full",
                children: [(0, _jsxRuntime.jsx)(_RateItem.default, {
                  brand: brand,
                  value: "".concat(roomClassCode, "-").concat(rateIndexFromAvailability),
                  roomRate: roomRate,
                  rateClassification: rateClassifications.find(el => (el === null || el === void 0 ? void 0 : el.rateClassification) === (roomRate === null || roomRate === void 0 ? void 0 : roomRate.ratePlanCode) || (el === null || el === void 0 ? void 0 : el.ratePlanCode) === (roomRate === null || roomRate === void 0 ? void 0 : roomRate.ratePlanCode)),
                  selectedRoomClassAndRate: selectedRoomClassAndRate,
                  setSelectedRoomClassAndRate: setSelectedRoomClassAndRate,
                  totalReservationAmount: totalReservationAmount,
                  numberOfNights: numberOfNights,
                  numberOfUnits: numberOfUnits
                }), index !== (roomRatesWithRateInformation === null || roomRatesWithRateInformation === void 0 ? void 0 : roomRatesWithRateInformation.length) - 1 && (0, _jsxRuntime.jsx)(_react.Divider, {
                  my: "md"
                })]
              }, roomRate.cellCode);
            })
          }), (roomRatesWithRateInformation === null || roomRatesWithRateInformation === void 0 ? void 0 : roomRatesWithRateInformation.length) > MIN_SHOWN_ROOM_RATES && isBB ? (0, _jsxRuntime.jsx)(_react.Box, {
            textAlign: "center",
            mt: {
              base: '0',
              sm: 'md'
            },
            children: (0, _jsxRuntime.jsx)(_react.Link, {
              onClick: handleShowMoreRates,
              children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, seeDetailsLinkStyles), {}, {
                "data-testid": "hdp_roomTypeShowMoreRatesLink",
                children: showRates < (roomRatesWithRateInformation === null || roomRatesWithRateInformation === void 0 ? void 0 : roomRatesWithRateInformation.length) ? showMoreRatesMsg : t('content.showLess')
              }))
            })
          }) : null]
        })]
      })
    })]
  });
  function renderRateCardDescription() {
    return !(roomTypes && roomTypes.length > 1) && (0, _jsxRuntime.jsxs)(_react.HStack, {
      alignItems: "flex-start",
      mb: "lg",
      w: "full",
      children: [(0, _jsxRuntime.jsxs)(_react.Box, {
        flex: "4",
        mr: "lg",
        children: [(0, _jsxRuntime.jsx)(_react.Box, {
          w: "full",
          mb: {
            base: '0',
            sm: 'md'
          },
          children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, roomTypeTitleStyles), {}, {
            as: "b",
            "data-testid": "hdp_roomTypeTitle",
            children: roomClass
          }))
        }), showAdditionalInfo && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, roomTypeDescriptionStyles), {}, {
          noOfLines: isLessThanSm ? 2 : descriptionNoOfLinesBigScreens,
          "data-testid": "hdp_roomTypeDescription",
          children: roomClassInformation === null || roomClassInformation === void 0 ? void 0 : roomClassInformation.roomDescription
        })), (0, _jsxRuntime.jsx)(_react.Box, {
          mt: {
            base: '0',
            sm: 'md'
          },
          children: (0, _jsxRuntime.jsx)(_link.default, {
            href: "#hotel-details-our-rooms",
            passHref: true,
            legacyBehavior: true,
            children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, seeDetailsLinkStyles), {}, {
              "data-testid": "hdp_roomTypeSeeDetailsLink",
              children: t('pihotelinfo.seeDetails')
            }))
          })
        })]
      }), showAdditionalInfo && (roomClassInformation === null || roomClassInformation === void 0 ? void 0 : roomClassInformation.roomImage) && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, imageContainerStyle), {}, {
        "data-testid": "hdp_roomTypeImage",
        children: (0, _jsxRuntime.jsx)(_image.default, {
          src: (0, _utils.formatAssetsUrl)(roomClassInformation.roomImage),
          alt: "Room type Image",
          fill: true,
          style: {
            objectFit: 'cover'
          },
          priority: true,
          loader: (0, _utils.isIVMEnabled)() ? _utils.akamaiImageLoader : undefined
        })
      }))]
    });
  }
  function getRoomRatesWithRateInformation(roomRates, rateClassifications) {
    var _window;
    if (typeof window === 'undefined') {
      return roomRates;
    }
    var {
      hostname
    } = ((_window = window) === null || _window === void 0 ? void 0 : _window.location) || {};
    var roomRatesWithRateInformation = [];
    var _loop = function _loop(roomRate) {
      var rateClassification = rateClassifications.find(el => (el === null || el === void 0 ? void 0 : el.rateClassification) === (roomRate === null || roomRate === void 0 ? void 0 : roomRate.ratePlanCode) || (el === null || el === void 0 ? void 0 : el.ratePlanCode) === (roomRate === null || roomRate === void 0 ? void 0 : roomRate.ratePlanCode));
      if (!(rateClassification !== null && rateClassification !== void 0 && rateClassification.rateName) || !(rateClassification !== null && rateClassification !== void 0 && rateClassification.rateDescription)) {
        if (/localhost|www\.((qa\w+)|dev|dit|sit)\.premierinn\.digital/.test(hostname)) {
          console.log("[HDP] No rate name or description for rate with rate plan code: ".concat(roomRate === null || roomRate === void 0 ? void 0 : roomRate.ratePlanCode));
        }
        return 1;
      }
      roomRatesWithRateInformation.push(roomRate);
    };
    for (var roomRate of roomRates) {
      if (_loop(roomRate)) continue;
    }
    return roomRatesWithRateInformation;
  }
  function getNonSilentSubstitutedRooms(firstRoomRateDataPerRoomClass) {
    var nonSilentSubstitutedRoom = [];
    firstRoomRateDataPerRoomClass === null || firstRoomRateDataPerRoomClass === void 0 || firstRoomRateDataPerRoomClass.forEach(room => {
      if (!(room !== null && room !== void 0 && room.silentSubstitution) && !nonSilentSubstitutedRoom.includes(room === null || room === void 0 ? void 0 : room.roomLabelCode)) {
        nonSilentSubstitutedRoom.push(room === null || room === void 0 ? void 0 : room.roomLabelCode);
      }
    });
    return nonSilentSubstitutedRoom;
  }
  function renderNonSilentSubstitutionNotificationPerRoomClass() {
    var firstRoomRateDataPerRoomClass = getFirstRoomRateDataPerRoomClass();
    if (!(firstRoomRateDataPerRoomClass !== null && firstRoomRateDataPerRoomClass !== void 0 && firstRoomRateDataPerRoomClass.length)) {
      return null;
    }
    var nonSilentSubstitutedRooms = getNonSilentSubstitutedRooms(firstRoomRateDataPerRoomClass);
    if (!nonSilentSubstitutedRooms.length) {
      return null;
    }
    return (0, _jsxRuntime.jsx)(_SilentSubstitutionNotification.default, {
      brand: brand,
      roomTypeInformationResponse: roomTypeInformationResponse,
      substitutedRooms: nonSilentSubstitutedRooms
    });
  }
}
var imageContainerStyle = {
  pos: 'relative',
  w: {
    base: '28',
    sm: '19rem',
    md: '23rem'
  },
  h: {
    base: '24',
    sm: '52'
  },
  ml: 'lg',
  roundedTopRight: 'xs'
};
var seeDetailsLinkStyles = {
  display: 'inline-block',
  fontSize: 'sm',
  lineHeight: '2',
  textDecoration: 'underline',
  color: 'btnSecondaryEnabled',
  _hover: {
    cursor: 'pointer'
  }
};
var roomTypeTitleStyles = {
  fontSize: {
    base: 'md',
    sm: 'lg',
    lg: 'xl'
  },
  lineHeight: '3'
};
var roomTypeDescriptionStyles = {
  fontSize: {
    base: 'sm',
    lg: 'md'
  },
  lineHeight: {
    base: '2',
    lg: '3'
  }
};