"use strict";
'use client';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FormCountries = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _utils = require("@whitbread-eos/utils");
var _server = require("@whitbread-eos/utils/server");
var _react = _interopRequireWildcard(require("react"));
var React = _react;
var _index = require("../FormSelect/index");
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["language", "className", "placeholder", "id", "disabled", "onChange", "arrowIcon", "initialCountry", "name"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var FormCountries = exports.FormCountries = React.forwardRef((_ref, ref) => {
  var {
      language,
      className,
      placeholder,
      id,
      disabled = false,
      onChange = () => {
        return;
      },
      arrowIcon,
      initialCountry,
      name = ''
    } = _ref,
    rest = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  var [options, setOptions] = (0, _react.useState)([]);
  var [selectedOption, setSelectedOption] = (0, _react.useState)({
    value: '',
    displayValue: ''
  });
  var [isLoading, setIsLoading] = (0, _react.useState)(true);
  var countryByLanguage = language === _utils.GLOBALS.language.EN ? _utils.GLOBALS.localeUpper.GB : _utils.GLOBALS.localeUpper.DE;
  var [defaultCountry, setDefaultCountry] = (0, _react.useState)(initialCountry || countryByLanguage);
  (0, _react.useEffect)(() => {
    if (initialCountry) {
      setDefaultCountry(initialCountry);
    }
  }, [initialCountry]);
  (0, _react.useEffect)(() => {
    var fetchListOfCountries = function () {
      var _ref2 = (0, _asyncToGenerator2.default)(function* () {
        var countriesList = yield (0, _server.getCountriesList)(language);
        if (!countriesList) {
          return;
        }
        var sortedCountriesList = sortOptions(countriesList);
        var formattedOptions = sortedCountriesList.map(option => ({
          displayValue: option.countryName,
          value: option.countryCode,
          icon: (0, _server.formatIBAssetsUrl)(option.flagSrc)
        }));
        setIsLoading(false);
        setOptions(formattedOptions);
      });
      return function fetchListOfCountries() {
        return _ref2.apply(this, arguments);
      };
    }();
    if (!disabled) {
      fetchListOfCountries();
    }
  }, []);
  (0, _react.useEffect)(() => {
    if (options.length !== 0) {
      var defaultOption = options.find(option => option.value === defaultCountry);
      if (defaultOption) {
        onChange(defaultOption.value);
        setSelectedOption(defaultOption);
      }
    }
  }, [options, defaultCountry]);
  var handleChange = country => {
    onChange(country.value);
    setSelectedOption(country);
  };
  var sortOptions = options => {
    return options.sort((a, b) => {
      if (a.countryCode === defaultCountry) return -1;
      if (b.countryCode === defaultCountry) return 1;
      return a.countryName.localeCompare(b.countryName);
    });
  };
  return (0, _jsxRuntime.jsx)(_index.FormSelect, _objectSpread(_objectSpread({}, rest), {}, {
    ref: ref,
    id: id,
    placeholder: placeholder,
    arrowIcon: arrowIcon,
    className: className,
    options: options,
    onChange: handleChange,
    disabled: disabled,
    name: name,
    value: selectedOption,
    errorIcon: '',
    isLoading: isLoading
  }));
});
FormCountries.displayName = 'FormCountries';