"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SearchContainer;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _dateFns = require("date-fns");
var _add = _interopRequireDefault(require("date-fns/add"));
var _isValid = _interopRequireDefault(require("date-fns/isValid"));
var _de = _interopRequireDefault(require("date-fns/locale/de"));
var _enGB = _interopRequireDefault(require("date-fns/locale/en-GB"));
var _debounce = _interopRequireDefault(require("lodash/debounce"));
var _nextI18next = require("next-i18next");
var _config = _interopRequireDefault(require("next/config"));
var _router = require("next/router");
var _react2 = require("react");
var _ccui = require("../styles/ccui.style");
var _utilities = require("../utilities");
var _searchValidation = require("../validations/searchValidation");
var _Search = _interopRequireDefault(require("./Search.component"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var MIN_LENGTH_SEARCH_TERM = 3;
function SearchContainer(_ref) {
  var _errorCode$, _dataTranslations$hea, _maxArrivalDateLimita, _maxNightsLimitation, _maxRoomsLimitation, _partialTranslations$, _dataTranslations$hea2, _dataTranslations$hea3, _partialTranslations$2, _contractRateCompany$, _dataTranslations$hea4;
  var {
    searchLocation,
    defaultLocation,
    defaultRooms,
    ARRdd,
    ARRmm,
    ROOMS,
    ARRyyyy,
    NIGHTS,
    CORPID,
    COMPID,
    isSummaryActive = true,
    setIsSearchError,
    isDatePickerFocus,
    hideErrorForMinNights,
    displayDatesNotification,
    prevReservationId,
    deleteSessionStorageValues
  } = _ref;
  var initialData = {
    managedPlaces: [],
    places: [],
    properties: []
  };
  var placeholderDate = ARRyyyy && ARRmm && ARRdd && new Date(ARRyyyy, ARRmm - 1, ARRdd);
  var firstDefaultEndDate = placeholderDate && (0, _add.default)(placeholderDate, {
    days: NIGHTS
  }) || null;
  var firstDefaultStartDate = placeholderDate || null;
  var initialSearchState = {
    items: null,
    searchConsoleIsActive: false,
    errorCode: ['', ''],
    searchSummaryActive: true,
    startDate: firstDefaultStartDate,
    endDate: firstDefaultEndDate,
    savedNights: NIGHTS === 0 ? 0 : NIGHTS || 1,
    locationInputError: false
  };
  var [{
    items,
    searchConsoleIsActive,
    errorCode,
    searchSummaryActive,
    startDate,
    endDate,
    savedNights,
    locationInputError
  }, updateSearchEvent] = (0, _react2.useReducer)((prev, next) => {
    return _objectSpread(_objectSpread({}, prev), next);
  }, initialSearchState);
  var [contractRateCompany, setContractRateCompany] = (0, _react2.useState)(null);
  var [stayDetailsState, setStayDetailsState] = (0, _utils.useLocalStorage)('StayDetailsState', _molecules.STAY_DETAILS_STATE_INITIAL_VALUE);
  var [, setDistanceFromSearch] = (0, _utils.useLocalStorage)('DistanceFromSearch', _molecules.DISTANCE_FROM_SEARCH_INITIAL_VALUE);
  var [, setSearchReferrer] = (0, _utils.useLocalStorage)('SearchReferrer', _molecules.SEARCH_REFERRER_INITIAL_VALUE);
  var router = (0, _router.useRouter)();
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var screenSize = (0, _utils.useScreenSize)();
  var {
    isLessThanSm,
    isLessThanMd
  } = screenSize;
  var channel = _api.BOOKING_CHANNEL.CCUI;
  var {
    publicRuntimeConfig = {}
  } = (0, _config.default)() || {};
  var {
    [_api.FT_CCUI_SEARCH_NEGOTIATED_RATES_BY_CORP_ID]: isNegotiatedRatesByCompIdEnabled,
    [_api.FT_PI_DISCOUNT_RATE]: isDiscountRateEnabled
  } = (0, _utils.useFeatureToggle)();
  var {
    isLoading: isLoadingTranslations,
    isError: isErrorTranslations,
    data: dataTranslations,
    error: errorTranslations
  } = (0, _utils.useQueryRequest)(['GetStaticContent', language, country], _api.GET_STATIC_CONTENT, {
    country,
    language,
    site: _api.SITE_LEISURE,
    businessBooker: false
  });
  var {
    isLoading: isLoadingSearchRules,
    isError: isErrorSearchRules,
    data: dataSearchRules,
    error: errorSearchRules
  } = (0, _utils.useQueryRequest)(['getSearchRules', channel], _api.GET_SEARCH_RULES_QUERY, {
    channel
  });
  var queryKey = isNegotiatedRatesByCompIdEnabled ? 'searchCompany' : 'searchCompanyById';
  var gqlTemplate = isNegotiatedRatesByCompIdEnabled ? _api.SEARCH_COMPANY_BY_ID_OR_CORP_ID : _api.SEARCH_COMPANY_BY_ID;
  var {
    isLoading: isLoadingCompanyData,
    isError: isErrorCompanyData,
    data: companyData
  } = (0, _utils.useQueryRequest)([queryKey, CORPID], gqlTemplate, {
    id: CORPID
  }, {
    enabled: !!CORPID,
    retry: false
  }, undefined, true);
  var companyProfileData = isNegotiatedRatesByCompIdEnabled ? companyData === null || companyData === void 0 ? void 0 : companyData.companyProfileById : companyData === null || companyData === void 0 ? void 0 : companyData.companyProfile;
  var errorKey = ['', ''];
  var [validationKey, validationField] = errorCode;
  var isNumberOfNightsError = validationField === _utilities.FIELDS.numberOfNights;
  var isDatepickerError = validationField === _utilities.FIELDS.datepicker;
  var URL = router.asPath;
  var isListViewShow = !!(errorCode !== null && errorCode !== void 0 && (_errorCode$ = errorCode[0]) !== null && _errorCode$ !== void 0 && _errorCode$.length) && locationInputError;
  (0, _react2.useEffect)(() => {
    if (router.asPath !== URL) {
      router.push("/".concat(country).concat(URL));
    }
    if (errorKey) {
      var [key, field] = errorKey;
      var [currentKey] = errorCode;
      if (key !== currentKey && key !== undefined && field !== undefined) {
        updateSearchEvent({
          errorCode: [key, field]
        });
      }
      isListViewShow && (setIsSearchError === null || setIsSearchError === void 0 ? void 0 : setIsSearchError(false));
    }
  }, [errorKey]);
  (0, _react2.useEffect)(() => {
    var [key, field] = errorCode;
    if (key && field) {
      updateSearchEvent({
        searchSummaryActive: false
      });
    }
    updateSearchEvent({
      searchConsoleIsActive: true
    });
  }, [errorCode]);
  (0, _react2.useEffect)(() => {
    handleLocationFromUrl(defaultLocation);
  }, []);
  (0, _react2.useEffect)(() => {
    if (isDatepickerError) {
      updateSearchEvent({
        startDate: null
      });
      updateSearchEvent({
        endDate: null
      });
      updateSearchEvent({
        savedNights: 1
      });
    }
  }, [isDatepickerError]);
  (0, _react2.useEffect)(() => {
    handleNrOfNightsURLError();
  }, [isNumberOfNightsError, NIGHTS]);
  (0, _react2.useEffect)(() => {
    if (typeof isSummaryActive === 'boolean') {
      updateSearchEvent({
        searchSummaryActive: isSummaryActive
      });
    }
  }, [isSummaryActive, defaultLocation, searchLocation]);
  (0, _react2.useEffect)(() => {
    if (!isLoadingCompanyData && !isErrorCompanyData && companyProfileData) {
      setContractRateCompany(_objectSpread(_objectSpread({}, companyProfileData), {}, {
        searchCriteriaId: COMPID || CORPID
      }));
    }
  }, [isLoadingCompanyData, isErrorCompanyData, companyData, companyProfileData, CORPID, COMPID]);
  var isSearchLoading = isLoadingSearchRules || isLoadingTranslations;
  if (isSearchLoading) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      "data-testid": "loading-message",
      children: t('searchresults.list.hotel.loading')
    });
  }
  if (isErrorSearchRules) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      "data-testid": "error-search-rules-message",
      children: errorSearchRules.message
    });
  }
  if (isErrorTranslations) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      "data-testid": "error-translations",
      children: errorTranslations.message
    });
  }
  var offers = (dataTranslations === null || dataTranslations === void 0 || (_dataTranslations$hea = dataTranslations.headerInformation) === null || _dataTranslations$hea === void 0 || (_dataTranslations$hea = _dataTranslations$hea.content) === null || _dataTranslations$hea === void 0 || (_dataTranslations$hea = _dataTranslations$hea.global) === null || _dataTranslations$hea === void 0 ? void 0 : _dataTranslations$hea.offers) || [];
  var findOffer = offers => {
    var corpId = CORPID || (contractRateCompany === null || contractRateCompany === void 0 ? void 0 : contractRateCompany.corpId);
    return isDiscountRateEnabled && corpId ? offers.find(offer => offer.corpId === corpId) : undefined;
  };
  var matchedOffer = findOffer(offers);
  var isActiveOffer = matchedOffer => matchedOffer !== undefined && Object.keys(matchedOffer).length > 0;
  var isActiveMatchedOffer = isActiveOffer(matchedOffer);
  var validateSearchDataResults = (0, _searchValidation.validateSearchData)(Number(ARRdd), Number(ARRmm), Number(ARRyyyy), Number(NIGHTS), items, dataTranslations, router, dataSearchRules === null || dataSearchRules === void 0 || (_maxArrivalDateLimita = dataSearchRules.maxArrivalDateLimitation) === null || _maxArrivalDateLimita === void 0 ? void 0 : _maxArrivalDateLimita.maxArrivalDate, dataSearchRules === null || dataSearchRules === void 0 || (_maxNightsLimitation = dataSearchRules.maxNightsLimitation) === null || _maxNightsLimitation === void 0 ? void 0 : _maxNightsLimitation.maxNights, defaultRooms, false, isActiveMatchedOffer ? matchedOffer === null || matchedOffer === void 0 ? void 0 : matchedOffer.maxRooms : dataSearchRules === null || dataSearchRules === void 0 || (_maxRoomsLimitation = dataSearchRules.maxRoomsLimitation) === null || _maxRoomsLimitation === void 0 ? void 0 : _maxRoomsLimitation.maxRooms, true);
  errorKey = validateSearchDataResults.errorKey;
  URL = validateSearchDataResults.url;
  var partialTranslations = dataTranslations === null || dataTranslations === void 0 ? void 0 : dataTranslations.headerInformation;
  var roomCodes = dataTranslations === null || dataTranslations === void 0 ? void 0 : dataTranslations.headerInformation.config.roomCodes;
  var globalTranslations = partialTranslations.content.global;
  var swappedRoomCodes = (0, _utils.swapKeysAndValues)(roomCodes);
  var AEMTranslations = {
    adultsLabel: t('dashboard.bookings.adults'),
    childrenLabel: t('dashboard.bookings.children'),
    roomTypeLabel: t('search.roomType'),
    locationPlaceholder: t('hoteldetails.location'),
    submitButtonLabel: t('search.submit.search'),
    datepickerCheckinLabel: t('account.dashboard.checkin.text'),
    datepickerCheckoutLabel: t('account.dashboard.checkout.text'),
    locationErrorLabel: t('error.blank.location'),
    numberOfNightsPlaceholder: t('search.nights'),
    companyNameInputPlaceholder: t('ccui.search.companyName.placeholder'),
    companyIdInputPlaceholder: t('ccui.search.companyId.placeholder'),
    promotionCategoryPlaceholder: t('ccui.search.promotionCategory.placeholder'),
    numberOfNightsErrorMessage: t('ccui.search.nrOfnights.errormessage'),
    datepickerInvalidDates: partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$ = partialTranslations.datePicker) === null || _partialTranslations$ === void 0 ? void 0 : _partialTranslations$.invalidDate
  };
  var errorMessages = {
    ["".concat(_utilities.ERROR_KEYS.invalidLocation)]: t('error.blank.location'),
    ["".concat(_utilities.ERROR_KEYS.invalidDate)]: dataTranslations === null || dataTranslations === void 0 || (_dataTranslations$hea2 = dataTranslations.headerInformation) === null || _dataTranslations$hea2 === void 0 || (_dataTranslations$hea2 = _dataTranslations$hea2.form) === null || _dataTranslations$hea2 === void 0 ? void 0 : _dataTranslations$hea2.invalidDate,
    ["".concat(_utilities.ERROR_KEYS.arrivalDateInThePast)]: t('ccui.search.datepicker.errorpast'),
    ["".concat(_utilities.ERROR_KEYS.arrivalDateInTheFuture)]: t('ccui.search.datepicker.errorfuture'),
    ["".concat(_utilities.ERROR_KEYS.invalidNights)]: dataTranslations === null || dataTranslations === void 0 || (_dataTranslations$hea3 = dataTranslations.headerInformation) === null || _dataTranslations$hea3 === void 0 || (_dataTranslations$hea3 = _dataTranslations$hea3.form) === null || _dataTranslations$hea3 === void 0 ? void 0 : _dataTranslations$hea3.invalidNights,
    ["".concat(_utilities.ERROR_KEYS.invalidOccupancy)]: partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$2 = partialTranslations.form) === null || _partialTranslations$2 === void 0 ? void 0 : _partialTranslations$2.invalidRooms
  };
  var message = validationKey && errorMessages[validationKey];
  var mappedRoomLabels = (0, _utils.getMappedRooms)(roomCodes, globalTranslations);
  var searchStyles = (0, _ccui.getSearchStyles)(isLessThanSm, (0, _utils.logicalAndOperator)(validationKey !== '', validationKey !== undefined), language, items === null || items === void 0 ? void 0 : items.places, validationField);
  var translationDateLanguage = {
    locale: (0, _utils.ternaryCondition)(language === 'en', _enGB.default, _de.default)
  };
  var contractRateSummary = (_contractRateCompany$ = contractRateCompany === null || contractRateCompany === void 0 ? void 0 : contractRateCompany.name) !== null && _contractRateCompany$ !== void 0 ? _contractRateCompany$ : AEMTranslations.companyIdInputPlaceholder;
  if (isNegotiatedRatesByCompIdEnabled) {
    contractRateSummary = contractRateCompany ? "".concat(contractRateCompany.name, "/").concat(COMPID || CORPID) : "".concat(AEMTranslations.companyNameInputPlaceholder, " or ").concat(AEMTranslations.companyIdInputPlaceholder);
  }
  var searchSummaryProps = searchSummaryActive ? {
    handleEdit: () => {
      updateSearchEvent({
        searchSummaryActive: false
      });
    },
    isSummaryActive: searchSummaryActive,
    location: defaultLocation,
    dateInterval: (0, _isValid.default)(startDate) && startDate && endDate && (!isLessThanSm ? (0, _utilities.formatSummaryDateRange)('dd MMM', startDate, endDate, translationDateLanguage) : (0, _utilities.formatSummaryDateRange)('EEE dd MMM', startDate, endDate, translationDateLanguage)),
    roomSummary: (0, _utilities.getRoomsPlaceholder)(defaultRooms, {
      adult: partialTranslations.content.global.adult,
      adults: partialTranslations.content.global.adults,
      child: partialTranslations.content.global.child,
      children: partialTranslations.content.global.children,
      room: partialTranslations.content.global.room,
      rooms: partialTranslations.content.global.rooms
    }),
    numberOfNightsSummary: NIGHTS === null || NIGHTS === void 0 ? void 0 : NIGHTS.toString(),
    promotionCategorySummary: AEMTranslations.promotionCategoryPlaceholder,
    contractRateSummary,
    editText: t('amend.edit'),
    isLessThanSm,
    isLessThanMd
  } : {};
  var suggestions = items || initialData;
  var noOfNightsError = t(savedNights ? "".concat(AEMTranslations.numberOfNightsErrorMessage) : "".concat(dataTranslations === null || dataTranslations === void 0 || (_dataTranslations$hea4 = dataTranslations.headerInformation) === null || _dataTranslations$hea4 === void 0 || (_dataTranslations$hea4 = _dataTranslations$hea4.form) === null || _dataTranslations$hea4 === void 0 ? void 0 : _dataTranslations$hea4.invalidNights));
  return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
    children: !searchSummaryActive ? (0, _jsxRuntime.jsx)(_Search.default, {
      locale: language,
      savedNights: savedNights,
      onLocationInputChange: (0, _debounce.default)(handleLocationInputChange, 300),
      onLocationInputClear: handleLocationInputClear,
      onInputLocationFocus: handleLocationInputFocus,
      onSelectDates: handleSelectDates,
      noOfNightsError: noOfNightsError,
      suggestions: suggestions,
      roomCodes: swappedRoomCodes,
      errorField: validationField,
      errorMessage: message,
      isSearchActive: searchConsoleIsActive,
      mappedRoomLabels: (0, _utils.swapKeysAndValues)(mappedRoomLabels),
      isDatepickerError: isDatepickerError,
      onOccupancyChange: handleRoomOccupancyChange,
      channel: channel,
      isDatePickerFocus: isDatePickerFocus,
      contractRateCompanyState: [contractRateCompany, setContractRateCompany],
      isNegotiatedRatesByCompIdEnabled: isNegotiatedRatesByCompIdEnabled,
      searchLocation,
      defaultLocation,
      defaultRooms,
      ARRdd,
      ARRmm,
      ARRyyyy,
      ROOMS,
      NIGHTS,
      dataStayRules: dataSearchRules,
      dataRoomOccupancyLimitations: dataSearchRules,
      partialTranslations,
      AEMTranslations,
      screenSize,
      handleButtonClick,
      searchStyles,
      startDate: startDate,
      endDate: endDate,
      handleChangeNumberOfNights,
      hideErrorForMinNights,
      displayDatesNotification,
      isActiveMatchedOffer,
      matchedOffer
    }, router.asPath) : (0, _jsxRuntime.jsx)(_atoms.SearchSummary, _objectSpread({}, searchSummaryProps))
  });
  function getSuggestions(_x) {
    return _getSuggestions.apply(this, arguments);
  }
  function _getSuggestions() {
    _getSuggestions = (0, _asyncToGenerator2.default)(function* (value) {
      var response = yield fetch("".concat(publicRuntimeConfig.NEXT_PUBLIC_SNOWDROP_BASE_URL, "v1/autocomplete?input=/").concat(value, "&gplaces[components]=country:uk|country:de"));
      return response.json();
    });
    return _getSuggestions.apply(this, arguments);
  }
  function handleLocationInputChange(value) {
    if (value && value.trim().length >= MIN_LENGTH_SEARCH_TERM) {
      getSuggestions(value).then(data => {
        updateSearchEvent({
          items: data
        });
        if (validationField === _utilities.FIELDS.location) {
          updateSearchEvent({
            errorCode: [undefined, undefined]
          });
        }
      });
    } else {
      updateSearchEvent({
        items: null
      });
    }
    updateSearchEvent({
      locationInputError: false
    });
  }
  function handleLocationFromUrl(value) {
    if (value && value.trim().length >= MIN_LENGTH_SEARCH_TERM) {
      getSuggestions(value).then(data => {
        updateSearchEvent({
          items: data
        });
        if (validationField === _utilities.FIELDS.location) {
          updateSearchEvent({
            errorCode: [undefined, undefined]
          });
        }
      });
    } else {
      getSuggestions(value).then(data => {
        updateSearchEvent({
          items: data
        });
        if (validationField === _utilities.FIELDS.location) {
          updateSearchEvent({
            errorCode: [undefined, undefined]
          });
        }
      });
      updateSearchEvent({
        locationInputError: true
      });
    }
    updateSearchEvent({
      locationInputError: true
    });
  }
  function handleLocationInputClear() {
    updateSearchEvent({
      items: null
    });
    if (validationField === _utilities.FIELDS.location) {
      updateSearchEvent({
        errorCode: [undefined, undefined]
      });
    }
  }
  function handleLocationInputFocus(searchTerm) {
    if ((searchTerm === null || searchTerm === void 0 ? void 0 : searchTerm.length) === 0) {
      if (validationField === _utilities.FIELDS.location) {
        updateSearchEvent({
          errorCode: [undefined, undefined]
        });
      }
      updateSearchEvent({
        items: null
      });
    }
  }
  function handleSelectDates(dates) {
    var [start, end] = dates;
    var setNumberOfNights = (displayDatesNotification, startDate, endDate) => {
      var daysDifference = (0, _dateFns.differenceInDays)(endDate, startDate);
      if (displayDatesNotification && isNaN(daysDifference)) {
        if (!(0, _isValid.default)(endDate) && (0, _isValid.default)(startDate)) {
          return 1;
        } else {
          return 0;
        }
      }
      return 1;
    };
    updateSearchEvent({
      startDate: start,
      endDate: end
    });
    updateSearchEvent({
      savedNights: Number(end && start ? (0, _dateFns.differenceInDays)(end, start) : setNumberOfNights(displayDatesNotification, start, end))
    });
    if (validationField === _utilities.FIELDS.datepicker || validationField === _utilities.FIELDS.numberOfNights) {
      updateSearchEvent({
        errorCode: [undefined, undefined]
      });
    }
  }
  function handleChangeNumberOfNights(event) {
    var numberOfNights = Number(event.target.value);
    updateSearchEvent({
      savedNights: numberOfNights
    });
    if (!startDate) {
      var today = new Date();
      updateSearchEvent({
        startDate: today
      });
      updateSearchEvent({
        endDate: numberOfNights > 0 && numberOfNights < 365 ? (0, _add.default)(today, {
          days: numberOfNights
        }) : endDate
      });
      updateSearchEvent({
        errorCode: [undefined, undefined]
      });
      return;
    }
    updateSearchEvent({
      endDate: numberOfNights > 0 && numberOfNights < 365 ? (0, _add.default)(startDate, {
        days: numberOfNights
      }) : endDate
    });
    if (validationField === _utilities.FIELDS.datepicker || validationField === _utilities.FIELDS.numberOfNights) {
      updateSearchEvent({
        errorCode: [undefined, undefined]
      });
    }
  }
  function handleNrOfNightsURLError() {
    if (isNumberOfNightsError && NIGHTS > 364) {
      updateSearchEvent({
        startDate: placeholderDate
      });
      updateSearchEvent({
        endDate: (0, _add.default)(startDate, {
          days: 1
        })
      });
      updateSearchEvent({
        savedNights: NIGHTS
      });
    } else if (isNumberOfNightsError && (isNaN(NIGHTS) || NIGHTS <= 0)) {
      updateSearchEvent({
        startDate: null
      });
      updateSearchEvent({
        endDate: null
      });
      updateSearchEvent({
        savedNights: 0
      });
    }
  }
  function handleRoomOccupancyChange() {
    if (validationField === _utilities.FIELDS.occupancy) {
      updateSearchEvent({
        errorCode: [undefined, undefined]
      });
    }
  }
  function handleButtonClick(_x2) {
    return _handleButtonClick.apply(this, arguments);
  }
  function _handleButtonClick() {
    _handleButtonClick = (0, _asyncToGenerator2.default)(function* (queryParams) {
      var _queryParams$rooms;
      if (!(queryParams !== null && queryParams !== void 0 && queryParams.searchTerm) || !items || validationField === _utilities.FIELDS.location) {
        updateSearchEvent({
          errorCode: [_utilities.ERROR_KEYS.invalidLocation, _utilities.ERROR_FIELDS.invalidLocation]
        });
        setIsSearchError === null || setIsSearchError === void 0 || setIsSearchError(false);
        return;
      }
      if (savedNights === 0 || savedNights > 364) {
        setIsSearchError === null || setIsSearchError === void 0 || setIsSearchError(false);
        return;
      }
      if (validationField === _utilities.FIELDS.datepicker && errorCode) {
        switch (errorCode[0]) {
          case _utilities.ERROR_FIELDS.invalidDate:
            {
              updateSearchEvent({
                errorCode: [_utilities.ERROR_KEYS.invalidDate, _utilities.ERROR_FIELDS.invalidDate]
              });
              break;
            }
          case _utilities.ERROR_FIELDS.arrivalDateInTheFuture:
            {
              updateSearchEvent({
                errorCode: [_utilities.ERROR_KEYS.arrivalDateInTheFuture, _utilities.ERROR_FIELDS.arrivalDateInTheFuture]
              });
              break;
            }
          case _utilities.ERROR_FIELDS.arrivalDateInThePast:
            {
              updateSearchEvent({
                errorCode: [_utilities.ERROR_KEYS.arrivalDateInThePast, _utilities.ERROR_FIELDS.arrivalDateInThePast]
              });
              break;
            }
        }
        setIsSearchError === null || setIsSearchError === void 0 || setIsSearchError(false);
        return;
      }
      if (validationField === _utilities.FIELDS.occupancy) {
        updateSearchEvent({
          errorCode: [_utilities.ERROR_KEYS.invalidOccupancy, _utilities.ERROR_FIELDS.invalidOccupancy]
        });
        setIsSearchError === null || setIsSearchError === void 0 || setIsSearchError(false);
        return;
      }
      var stayDetailsInfo = (0, _utilities.setSearchLocationInLocalStorage)(queryParams, stayDetailsState);
      setStayDetailsState(stayDetailsState => {
        stayDetailsState.data.info = stayDetailsInfo;
        return stayDetailsState;
      });
      var referrer = {
        data: {
          referrer: router.asPath
        }
      };
      setSearchReferrer(referrer);
      setDistanceFromSearch(_molecules.DISTANCE_FROM_SEARCH_INITIAL_VALUE);
      var paramsMappedForURL = (0, _utilities.mapSearchParamsForURL)(queryParams);
      var roomOccupancyParamsForURL = queryParams === null || queryParams === void 0 || (_queryParams$rooms = queryParams.rooms) === null || _queryParams$rooms === void 0 ? void 0 : _queryParams$rooms.filter(room => room.roomType).map((room, idx) => "ADULT".concat(idx + 1, "=").concat(room.adults, "&CHILD").concat(idx + 1, "=").concat(room.children, "&COT").concat(idx + 1, "=").concat(room.shouldIncludeCot ? 1 : 0, "&INTTYP").concat(idx + 1, "=").concat(mappedRoomLabels[room.roomType])).join('&');
      var URLToRedirect = "ARRdd=".concat(paramsMappedForURL.ARRdd, "&ARRmm=").concat(paramsMappedForURL.ARRmm, "&ARRyyyy=").concat(paramsMappedForURL.ARRyyyy, "&NIGHTS=").concat(paramsMappedForURL.nights, "&ROOMS=").concat(paramsMappedForURL.roomsNumber, "&").concat(roomOccupancyParamsForURL);
      if (contractRateCompany) {
        if (isNegotiatedRatesByCompIdEnabled) {
          var companyId = contractRateCompany.corpId !== contractRateCompany.searchCriteriaId ? "&COMPID=".concat(contractRateCompany.searchCriteriaId) : '';
          URLToRedirect += "&CORPID=".concat(contractRateCompany.corpId).concat(companyId);
        } else {
          URLToRedirect += "&CORPID=".concat(contractRateCompany.corpId);
        }
      }
      if (prevReservationId) {
        URLToRedirect += "&reservationId=".concat(prevReservationId);
      }
      var singleHotelSearchLabels = {
        bookingChannel: partialTranslations.config.api.bookingChannel.leisure,
        mappedRoomLabels: mappedRoomLabels
      };
      deleteSessionStorageValues === null || deleteSessionStorageValues === void 0 || deleteSessionStorageValues();
      window.location = yield (0, _utilities.singleHotelSearchCCUI)(stayDetailsState, paramsMappedForURL, singleHotelSearchLabels, URLToRedirect, queryParams, country, language, _api.BOOKING_CHANNEL.CCUI);
    });
    return _handleButtonClick.apply(this, arguments);
  }
}