"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HeroSection;
exports.getHeroDescription = getHeroDescription;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _image = _interopRequireDefault(require("next/image"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function HeroSection(_ref) {
  var {
    data
  } = _ref;
  var baseDataTestId = 'DLPHeroSection';
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var {
    title,
    description,
    picture
  } = data;
  var {
    isLessThanLg
  } = (0, _utils.useScreenSize)();
  return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, heroSectionStyle), {}, {
    children: [(0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, textContainerStyle), {}, {
      w: {
        mobile: 'full',
        md: !picture ? '54rem' : '50%'
      },
      children: [(0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, breadcrumbStyles), {}, {
        children: (0, _jsxRuntime.jsx)(_atoms.Breadcrumb, {
          openInNewTab: true,
          items: data.breadcrumbs
        })
      })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, titleStyle), {}, {
        children: title
      })), description && getHeroDescription(description.toString(), baseDataTestId, isLessThanLg, t)]
    })), picture && (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, pictureStyle), {}, {
      children: [(0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, _objectSpread({}, breadcrumbStyles)), {}, {
        display: {
          mobile: 'block',
          md: 'none'
        },
        children: (0, _jsxRuntime.jsx)(_atoms.Breadcrumb, {
          openInNewTab: true,
          items: data.breadcrumbs
        })
      })), (0, _jsxRuntime.jsx)(_image.default, {
        src: picture,
        alt: title,
        width: 700,
        height: 600,
        objectFit: "contain",
        style: {
          borderRadius: 'var(--chakra-space-sm)'
        }
      })]
    }))]
  }));
}
function getHeroDescription(description, baseDataTestId, isLessThanLg, t) {
  if (isLessThanLg || description.toString().length > 200) {
    return (0, _jsxRuntime.jsx)(_atoms.CollapseExpandText, {
      startingHeight: 185,
      noOfLines: 8,
      baseTestId: baseDataTestId,
      expandButtonText: t('hoteldetails.readmore'),
      collapseButtonText: t('hoteldetails.readless'),
      contentText: description,
      isFadeEffect: true
    });
  }
  return description;
}
var heroSectionStyle = {
  justifyContent: 'space-between',
  flexDirection: {
    mobile: 'column',
    md: 'row'
  },
  align: 'flex-end'
};
var textContainerStyle = {
  pr: {
    mobile: '0',
    md: 'lg'
  },
  order: {
    mobile: 2,
    md: 1
  }
};
var breadcrumbStyles = {
  py: 'lg',
  display: {
    mobile: 'none',
    md: 'block'
  }
};
var titleStyle = {
  color: 'tertiary',
  fontSize: {
    mobile: '2.5rem',
    md: '3.5rem'
  },
  fontWeight: 'var(--chakra-fontWeights-black)',
  letterSpacing: {
    mobile: '0',
    md: '-1px'
  },
  lineHeight: {
    mobile: '2.75rem'
  },
  mb: {
    mobile: 0,
    lg: 'md'
  },
  mt: {
    mobile: 'md',
    lg: '5xl'
  }
};
var pictureStyle = {
  w: {
    mobile: 'full',
    md: '50%'
  },
  mt: {
    mobile: 'md',
    sm: 0
  },
  order: {
    mobile: 1,
    md: 2
  },
  overflow: 'hidden',
  alignSelf: 'baseline'
};