"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelCard;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function HotelCard(_ref) {
  var _data$links$detailsPa, _data$links, _data$brand, _data$name, _data$topSectionImage, _data$topSectionImage2, _data$topSectionImage3, _data$topSectionImage4, _data$hotelFacilities;
  var {
    data
  } = _ref;
  var baseDataTestId = 'DLP';
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var hotelSlug = (_data$links$detailsPa = data === null || data === void 0 || (_data$links = data.links) === null || _data$links === void 0 ? void 0 : _data$links.detailsPage) !== null && _data$links$detailsPa !== void 0 ? _data$links$detailsPa : '';
  var hotelBrand = (_data$brand = data === null || data === void 0 ? void 0 : data.brand) !== null && _data$brand !== void 0 ? _data$brand : '';
  var hotelName = (_data$name = data === null || data === void 0 ? void 0 : data.name) !== null && _data$name !== void 0 ? _data$name : '';
  var imageURLHotelExterior = "".concat((_data$topSectionImage = data === null || data === void 0 || (_data$topSectionImage2 = data.topSectionImages) === null || _data$topSectionImage2 === void 0 || (_data$topSectionImage2 = _data$topSectionImage2[0]) === null || _data$topSectionImage2 === void 0 ? void 0 : _data$topSectionImage2.imageSrc) !== null && _data$topSectionImage !== void 0 ? _data$topSectionImage : '/');
  var imageAltText = "".concat(hotelName, " ").concat((_data$topSectionImage3 = data === null || data === void 0 || (_data$topSectionImage4 = data.topSectionImages) === null || _data$topSectionImage4 === void 0 || (_data$topSectionImage4 = _data$topSectionImage4[0]) === null || _data$topSectionImage4 === void 0 || (_data$topSectionImage4 = _data$topSectionImage4.tags) === null || _data$topSectionImage4 === void 0 ? void 0 : _data$topSectionImage4[0]) !== null && _data$topSectionImage3 !== void 0 ? _data$topSectionImage3 : 'exterior');
  var hotelBrandLogos = {
    hubLogo: '/content/dam/pi/websites/desktop/icons/brand/pi-icon-badge-hub.svg',
    zipLogo: '/content/dam/pi/websites/desktop/icons/brand/pi-icon-badge-zip.svg'
  };
  var hotelDistanceLabels = {
    distanceUnitPlural: 'miles',
    fromLocation: 'from your search'
  };
  var URLToRedirect = "/".concat(country, "/").concat(language, "/hotels").concat(hotelSlug, ".html");
  return (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, wrapperStyles), {}, {
    children: (0, _jsxRuntime.jsx)(_react.Link, _objectSpread(_objectSpread({
      href: URLToRedirect
    }, linkStyles), {}, {
      children: (0, _jsxRuntime.jsx)(_atoms.Card, _objectSpread(_objectSpread({}, cardContainerStyles), {}, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'hotel-card'),
        children: (0, _jsxRuntime.jsxs)(_react.Flex, {
          direction: "column",
          w: "full",
          children: [(0, _jsxRuntime.jsx)(_molecules.SRHotelThumbnail, {
            imageData: {
              imageSrc: imageURLHotelExterior,
              imageAlt: imageAltText
            },
            brand: hotelBrand,
            brandLogos: hotelBrandLogos,
            testId: (0, _utils.formatDataTestId)(baseDataTestId, 'hotel-thumbnail'),
            styles: hotelThumbnailWrapperStyles
          }), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, hotelDetailsBoxStyles), {}, {
            children: [(0, _jsxRuntime.jsx)(_molecules.SRHotelTitle, {
              title: hotelName,
              testId: (0, _utils.formatDataTestId)(baseDataTestId, 'hotel-title'),
              styles: titleStyles
            }), (data === null || data === void 0 ? void 0 : data.distanceFromReference) && (0, _jsxRuntime.jsx)(_molecules.SRHotelDistance, {
              distance: parseFloat(data === null || data === void 0 ? void 0 : data.distanceFromReference.toFixed(2)),
              unit: "miles",
              labels: hotelDistanceLabels,
              testId: (0, _utils.formatDataTestId)(baseDataTestId, 'hotel-distance'),
              styles: hotelDistanceTextStyles
            }), (data === null || data === void 0 ? void 0 : data.hotelFacilities) && (0, _jsxRuntime.jsx)(_molecules.SRHotelFacilities, {
              facilities: (_data$hotelFacilities = data === null || data === void 0 ? void 0 : data.hotelFacilities) !== null && _data$hotelFacilities !== void 0 ? _data$hotelFacilities : [],
              roomTypes: [],
              isDLPPage: true
            })]
          })), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
            justifyContent: "space-between",
            gap: "md"
          }, hotelButtonBoxStyles), {}, {
            "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'hotel-button'),
            children: [(0, _jsxRuntime.jsx)(_react.Text, {
              fontWeight: "bold",
              fontSize: "sm",
              children: t('dlp.hotelCard.viewHotel')
            }), (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({
              size: "xxs",
              variant: "circle"
            }, buttonStyles), {}, {
              children: (0, _jsxRuntime.jsx)(_atoms.Icon, {
                svg: (0, _jsxRuntime.jsx)(_atoms.ChevronRight, {
                  color: "var(--chakra-colors-baseWhite)"
                })
              })
            }))]
          }))]
        })
      }))
    }))
  }));
}
var wrapperStyles = {
  w: {
    mobile: 'full',
    xs: '22.375rem',
    sm: '16.25rem',
    md: '14.333rem',
    lg: '19.313rem'
  }
};
var linkStyles = {
  cursor: 'default',
  _hover: {
    textDecoration: 'none'
  },
  _focus: {
    textDecoration: 'none',
    boxShadow: 'none'
  },
  _visited: {
    textDecoration: 'none'
  },
  _link: {
    textDecoration: 'none'
  },
  _active: {
    textDecoration: 'none'
  }
};
var cardContainerStyles = {
  bgColor: 'baseWhite',
  w: 'full',
  minH: {
    mobile: 'auto',
    sm: '19.625rem',
    lg: '23.375rem',
    xl: '22rem'
  },
  border: '1px solid var(--chakra-colors-lightGrey4)',
  borderRadius: 'var(--chakra-space-radiusSmall)',
  boxShadow: 'none',
  padding: '0',
  _hover: {
    cursor: 'pointer',
    boxShadow: '0 0 var(--chakra-space-sm) 0 var(--chakra-colors-lightGrey2)',
    transitionProperty: 'border, box-shadow',
    transitionDuration: '0s, 0s',
    transitionTimingFunction: 'ease, ease',
    transitionDelay: '0s, 0s'
  }
};
var titleStyles = {
  fontSize: 'md',
  lineHeight: 'var(--chakra-lineHeights-3)',
  fontWeight: 'semibold',
  color: 'darkGrey1'
};
var hotelThumbnailWrapperStyles = {
  minH: {
    mobile: '12.875rem',
    sm: '9.125rem',
    lg: '12.875rem'
  }
};
var hotelDetailsBoxStyles = {
  px: {
    mobile: 'sm',
    sm: 'md'
  },
  py: {
    mobile: 'sm',
    sm: 'md'
  },
  flexDirection: 'column',
  w: 'full',
  minH: {
    mobile: 'auto',
    sm: '8.5rem'
  }
};
var hotelDistanceTextStyles = {
  fontSize: 'sm',
  fontWeight: 'normal',
  lineHeight: '2',
  paddingTop: {
    mobile: 'sm',
    sm: 'xs'
  },
  paddingBottom: 'sm',
  flex: 0
};
var hotelButtonBoxStyles = {
  pl: {
    mobile: 'sm',
    md: '0'
  },
  pr: {
    mobile: '0'
  },
  alignSelf: 'end',
  m: '0 var(--chakra-space-md) var(--chakra-space-md)'
};
var buttonStyles = {
  bgColor: 'primary',
  _hover: {
    bg: 'var(--chakra-colors-btnPrimaryHoverBg) radial-gradient(circle, transparent 1%, var(--chakra-colors-btnPrimaryHoverBg) 1%) center/15000%',
    boxShadow: '0 4px 8px var(--chakra-colors-lightGrey1)',
    _disabled: {
      bg: 'lightGrey3'
    }
  }
};