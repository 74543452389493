"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getEmployeeByIdQuery = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var getEmployeeByIdQuery = () => (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query getEmployeeDetails($companyId: String!, $employeeId: String!, $activationKey: String) {\n    getEmployeeDetails(\n      companyId: $companyId\n      employeeId: $employeeId\n      activationKey: $activationKey\n    ) {\n      id\n      ghNumber\n      emailAddress\n      position\n      phoneNumber\n      mobileNumber\n      textConfirmation\n      title\n      firstName\n      lastName\n      centralCardId\n      address {\n        addressLine1\n        addressLine2\n        addressLine3\n        addressLine4\n        addressLine5\n        postCode\n        country\n      }\n      accessLevel\n      employeeStatus\n      dialingCode\n      employeeAnswers {\n        customerReferenceAnswer\n        purchaseOrderAnswer\n        userDefinedAnswers {\n          miID\n          miAnswer\n        }\n      }\n      guestHistoryNumber\n      lockedForEditing\n      password\n    }\n  }\n"])));
exports.getEmployeeByIdQuery = getEmployeeByIdQuery;