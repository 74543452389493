"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Input = void 0;
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _server = require("@whitbread-eos/utils/server");
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["className", "type"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var Input = exports.Input = React.forwardRef((_ref, ref) => {
  var {
      className,
      type
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  var {
    placeholder,
    id,
    disabled,
    value,
    onChange,
    error,
    hasLabels = true
  } = props;
  var handleChange = event => {
    var _event$target;
    if (onChange) onChange(event === null || event === void 0 || (_event$target = event.target) === null || _event$target === void 0 ? void 0 : _event$target.value);
  };
  return (0, _jsxRuntime.jsxs)("div", {
    className: "relative w-full",
    children: [(0, _jsxRuntime.jsx)("input", {
      "data-testid": "".concat(id, "-Input"),
      id: "".concat(id, "-Input"),
      type: type,
      className: (0, _server.cn)('peer flex h-14 text-darkGrey1 border-lightGrey1 focus:outline-primaryColor focus:outline-2 hover:border-darkGrey1 w-full rounded border bg-transparent p-4 text-base transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-darkGrey2 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:border-lightGrey1', className),
      ref: ref,
      value: value,
      disabled: disabled,
      placeholder: placeholder,
      onChange: e => handleChange(e)
    }), hasLabels && (0, _jsxRuntime.jsx)("label", {
      htmlFor: "".concat(id, "-Input"),
      className: "hidden absolute peer-focus:!block text-darkGrey1 peer-focus:text-primaryColor -top-2.5 ml-3 text-sm px-1 bg-baseWhite ".concat(error ? 'text-red' : '', " ").concat(value ? '!block' : ''),
      "data-testid": "".concat(id, "-Label"),
      children: placeholder
    })]
  });
});
Input.displayName = 'Input';