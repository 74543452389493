"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCorporateDiscountRatePlanCode = void 0;
exports.getMatchedOffer = getMatchedOffer;
exports.getRatePlanCode = getRatePlanCode;
exports.updateRateInfoWithCorporateDiscount = updateRateInfoWithCorporateDiscount;
exports.useDiscountRateInfoHotelAvailability = useDiscountRateInfoHotelAvailability;
exports.useForDiscountedRateFlag = useForDiscountedRateFlag;
exports.useForDiscountedRateMicroSite = useForDiscountedRateMicroSite;
exports.useGetDiscountRateComapnyId = useGetDiscountRateComapnyId;
exports.useGetDiscountRateReservationData = useGetDiscountRateReservationData;
exports.useUpdateRateName = useUpdateRateName;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _ = require(".");
var _api = require("@whitbread-eos/api");
var _nextI18next = require("next-i18next");
var _router = require("next/router");
var _useGetCountryLanguage = _interopRequireDefault(require("./use-get-country-language"));
var _useHotelAvailability = require("./use-hotel-availability");
var _useRequest = require("./use-request");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function getRatePlanCode(offers, targetCorpId) {
  var offer = offers === null || offers === void 0 ? void 0 : offers.find(offer => (offer === null || offer === void 0 ? void 0 : offer.corpId) === targetCorpId);
  return offer === null || offer === void 0 ? void 0 : offer.ratePlanCode;
}
var getCorporateDiscountRatePlanCode = ratePlans => {
  var planWithDiscount = ratePlans === null || ratePlans === void 0 ? void 0 : ratePlans.find(plan => (plan === null || plan === void 0 ? void 0 : plan.isCorporateDiscountAvailable) === true);
  return planWithDiscount ? planWithDiscount === null || planWithDiscount === void 0 ? void 0 : planWithDiscount.ratePlanCode : '';
};
exports.getCorporateDiscountRatePlanCode = getCorporateDiscountRatePlanCode;
function updateRateInfoWithCorporateDiscount(dataHotelInformations, companyData, ratePlanCode) {
  var _dataHotelInformation;
  dataHotelInformations === null || dataHotelInformations === void 0 || (_dataHotelInformation = dataHotelInformations.ratesInformation) === null || _dataHotelInformation === void 0 || (_dataHotelInformation = _dataHotelInformation.rateClassifications) === null || _dataHotelInformation === void 0 || _dataHotelInformation.forEach(rateInfo => {
    if ((rateInfo === null || rateInfo === void 0 ? void 0 : rateInfo.ratePlanCode) === ratePlanCode) {
      var _companyData$companyP, _companyData$companyP2;
      rateInfo.rateName = (_companyData$companyP = companyData === null || companyData === void 0 || (_companyData$companyP2 = companyData.companyProfile) === null || _companyData$companyP2 === void 0 ? void 0 : _companyData$companyP2.name) !== null && _companyData$companyP !== void 0 ? _companyData$companyP : '';
      rateInfo.isCorporateDiscountAvailable = true;
    }
  });
}
function useForDiscountedRateFlag() {
  var {
    [_api.FT_PI_DISCOUNT_RATE]: isDiscountRateEnabled
  } = (0, _.useFeatureToggle)();
  return !!isDiscountRateEnabled;
}
function useForDiscountedRateMicroSite() {
  var {
    query: {
      CORPID
    }
  } = (0, _router.useRouter)();
  return useForDiscountedRateFlag() && !!CORPID;
}
function useDiscountRateInfoHotelAvailability(_ref) {
  var _companyData$companyP3;
  var {
    hotelId,
    hotelBrand,
    arrival,
    departure,
    rooms,
    offers
  } = _ref;
  var {
    query: {
      CORPID
    }
  } = (0, _router.useRouter)();
  var isDiscountRateEnabled = useForDiscountedRateMicroSite();
  var {
    isLoading: isLoadingCompanyData,
    isError: isErrorCompanyData,
    data: companyData
  } = (0, _useRequest.useQueryRequest)(['searchCompanyById', CORPID], _api.SEARCH_COMPANY_BY_ID, {
    corpId: CORPID
  }, {
    enabled: !!CORPID && isDiscountRateEnabled,
    retry: false
  }, undefined, true);
  var hotelAvailabilityQueryEnabled = !!(companyData && !isLoadingCompanyData);
  var {
    isLoading: isLoadingHotelAvailabilities,
    isError: isErrorHotelAvailabilities,
    data: dataHotelAvailabilityData,
    error: errorHotelAvailabilities
  } = (0, _useHotelAvailability.useHotelAvailabilityDiscountRate)({
    hotelId,
    hotelBrand,
    arrival,
    departure,
    rooms,
    channel: _api.BOOKING_CHANNEL.PI,
    queryEnabled: hotelAvailabilityQueryEnabled,
    companyId: companyData === null || companyData === void 0 || (_companyData$companyP3 = companyData.companyProfile) === null || _companyData$companyP3 === void 0 ? void 0 : _companyData$companyP3.companyId
  });
  var rateInformationQueryEnabled = !isErrorHotelAvailabilities && !errorHotelAvailabilities && !isLoadingHotelAvailabilities && !!dataHotelAvailabilityData;
  var {
    isLoading: isLoadingHotelInformations,
    isError: isErrorHotelInformations,
    data: dataHotelRateInformations,
    error: errorHotelInformations
  } = (0, _useHotelAvailability.useHotelRatesInformationDiscountRate)(hotelId, hotelBrand, dataHotelAvailabilityData, _api.BOOKING_CHANNEL.PI, rateInformationQueryEnabled);
  var ratePlanCode = getRatePlanCode(offers, CORPID);
  updateRateInfoWithCorporateDiscount(dataHotelRateInformations, companyData, ratePlanCode);
  var dataHotelAvailability = dataHotelAvailabilityData;
  var hotelAvailabilityResponse = {
    isLoadingHotelAvailability: isLoadingHotelInformations || isLoadingHotelAvailabilities,
    isErrorHotelAvailability: isErrorHotelInformations || isErrorHotelAvailabilities,
    dataHotelAvailability: dataHotelRateInformations && dataHotelAvailability && _objectSpread(_objectSpread({}, dataHotelRateInformations), dataHotelAvailability),
    errorHotelAvailability: errorHotelInformations || errorHotelAvailabilities
  };
  return {
    hotelAvailabilityResponse,
    dataHotelAvailability,
    isDiscountRateLoading: isLoadingHotelInformations || isLoadingCompanyData || isLoadingHotelAvailabilities,
    isDiscountRateError: isErrorHotelInformations || isErrorCompanyData || isErrorHotelAvailabilities
  };
}
function useUpdateRateName(bookingData, language, country) {
  var _dataHeaderInformatio;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var HDPPath = '';
  if (typeof window !== 'undefined') HDPPath = localStorage.getItem('HDPPath');
  var urlParams = new URLSearchParams(HDPPath);
  var {
    data: dataHeaderInformation
  } = (0, _useRequest.useQueryRequest)(['GetStaticContent', language, country], _api.GET_STATIC_CONTENT, {
    language,
    country,
    site: _api.SITE_LEISURE,
    businessBooker: false
  });
  var offer = dataHeaderInformation === null || dataHeaderInformation === void 0 || (_dataHeaderInformatio = dataHeaderInformation.headerInformation) === null || _dataHeaderInformatio === void 0 || (_dataHeaderInformatio = _dataHeaderInformatio.content) === null || _dataHeaderInformatio === void 0 || (_dataHeaderInformatio = _dataHeaderInformatio.global) === null || _dataHeaderInformatio === void 0 ? void 0 : _dataHeaderInformatio.offers;
  var isDiscountRateEnabled = useForDiscountedRateFlag();
  var CORPID = urlParams.get('CORPID');
  if (!CORPID || !offer) return;
  var ratePlanCodes = getRatePlanCode(offer, CORPID);
  if (isDiscountRateEnabled) bookingData.reservationByIdList.forEach(reservation => {
    if (reservation.roomStay.ratePlanCode === ratePlanCodes) {
      var _bookingData$upgradeT;
      if (bookingData !== null && bookingData !== void 0 && bookingData.upgradeToFlex && bookingData !== null && bookingData !== void 0 && (_bookingData$upgradeT = bookingData.upgradeToFlex) !== null && _bookingData$upgradeT !== void 0 && _bookingData$upgradeT.flexRateCode) {
        bookingData.upgradeToFlex.flexRateCode = '';
      }
      reservation.roomStay.rateExtraInfo.rateName = t("promotion.discountrate.".concat(ratePlanCodes, ".ratename"));
    }
  });
}
function getMatchedOffer(offersConfigData, targetRatePlanCode) {
  return targetRatePlanCode ? offersConfigData === null || offersConfigData === void 0 ? void 0 : offersConfigData.find(offer => (offer === null || offer === void 0 ? void 0 : offer.ratePlanCode) === targetRatePlanCode) : null;
}
function useGetDiscountRateComapnyId(hotelAvailabilityParams) {
  var _headerInformationDat, _hotelAvailabilityPar;
  var comapanyId = '';
  var isDiscountRateFeatureEnabled = useForDiscountedRateFlag();
  var {
    language,
    country
  } = (0, _useGetCountryLanguage.default)();
  var {
    data: headerInformationData
  } = (0, _useRequest.useQueryRequest)(['GetStaticContent', language, country], _api.GET_STATIC_CONTENT, {
    language,
    country,
    site: _api.SITE_LEISURE,
    businessBooker: false,
    enabled: isDiscountRateFeatureEnabled
  });
  var matchedOffer = getMatchedOffer(headerInformationData === null || headerInformationData === void 0 || (_headerInformationDat = headerInformationData.headerInformation) === null || _headerInformationDat === void 0 || (_headerInformationDat = _headerInformationDat.content) === null || _headerInformationDat === void 0 || (_headerInformationDat = _headerInformationDat.global) === null || _headerInformationDat === void 0 ? void 0 : _headerInformationDat.offers, hotelAvailabilityParams === null || hotelAvailabilityParams === void 0 ? void 0 : hotelAvailabilityParams.rateCode);
  var corpId = matchedOffer === null || matchedOffer === void 0 ? void 0 : matchedOffer.corpId;
  var companyIdQueryEnabled = isDiscountRateFeatureEnabled && (hotelAvailabilityParams === null || hotelAvailabilityParams === void 0 || (_hotelAvailabilityPar = hotelAvailabilityParams.channel) === null || _hotelAvailabilityPar === void 0 ? void 0 : _hotelAvailabilityPar.toLowerCase()) === _api.Area.PI.toLowerCase() && !!headerInformationData && !!corpId;
  var {
    isLoading: isLoadingCompanyData,
    isError: isErrorCompanyData,
    data: companyData
  } = (0, _useRequest.useQueryRequest)(['searchCompanyById', corpId], _api.SEARCH_COMPANY_BY_ID, {
    corpId: corpId
  }, {
    enabled: !!corpId && companyIdQueryEnabled,
    retry: false
  }, undefined, true);
  if (!!companyData && !isLoadingCompanyData && !isErrorCompanyData && companyIdQueryEnabled) {
    var _companyData$companyP4;
    comapanyId = companyData === null || companyData === void 0 || (_companyData$companyP4 = companyData.companyProfile) === null || _companyData$companyP4 === void 0 ? void 0 : _companyData$companyP4.companyId;
  }
  return comapanyId;
}
function useGetDiscountRateReservationData(offersConfigData, bookingConfirmationData, variant) {
  var _bookingConfirmationD, _discountRateReservat;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var isDiscountRateFeatureEnabled = useForDiscountedRateFlag();
  if (!isDiscountRateFeatureEnabled) {
    return {
      isDiscountRate: false,
      maxRooms: 0,
      matchedDiscountRate: '',
      roomLimitMessage: '',
      unAvailableMessage: ''
    };
  }
  var discountRateReservation = bookingConfirmationData === null || bookingConfirmationData === void 0 || (_bookingConfirmationD = bookingConfirmationData.reservationByIdList) === null || _bookingConfirmationD === void 0 ? void 0 : _bookingConfirmationD.find(room => {
    return (offersConfigData === null || offersConfigData === void 0 ? void 0 : offersConfigData.some(offer => {
      var _room$roomStay;
      return (offer === null || offer === void 0 ? void 0 : offer.ratePlanCode) === (room === null || room === void 0 || (_room$roomStay = room.roomStay) === null || _room$roomStay === void 0 ? void 0 : _room$roomStay.ratePlanCode);
    })) && isDiscountRateFeatureEnabled && [_api.Area.PI, _api.Area.CCUI].includes(variant);
  });
  var matchedOffer = getMatchedOffer(offersConfigData, discountRateReservation === null || discountRateReservation === void 0 || (_discountRateReservat = discountRateReservation.roomStay) === null || _discountRateReservat === void 0 ? void 0 : _discountRateReservat.ratePlanCode);
  return discountRateReservation && matchedOffer ? {
    isDiscountRate: true,
    maxRooms: Number(matchedOffer === null || matchedOffer === void 0 ? void 0 : matchedOffer.maxRooms) || 0,
    matchedDiscountRate: (matchedOffer === null || matchedOffer === void 0 ? void 0 : matchedOffer.ratePlanCode) || '',
    roomLimitMessage: matchedOffer !== null && matchedOffer !== void 0 && matchedOffer.ratePlanCode ? t("promotion.discountrate.".concat(matchedOffer === null || matchedOffer === void 0 ? void 0 : matchedOffer.ratePlanCode, ".roomlimit"), {
      maxRooms: matchedOffer === null || matchedOffer === void 0 ? void 0 : matchedOffer.maxRooms
    }) : '',
    unAvailableMessage: matchedOffer !== null && matchedOffer !== void 0 && matchedOffer.ratePlanCode ? t("promotion.discountrate.".concat(matchedOffer === null || matchedOffer === void 0 ? void 0 : matchedOffer.ratePlanCode, ".unavailable")) : ''
  } : {
    isDiscountRate: false,
    maxRooms: 0,
    matchedDiscountRate: '',
    roomLimitMessage: '',
    unAvailableMessage: ''
  };
}