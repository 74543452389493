"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.capitalizeFirstLetter = void 0;
exports.formatHDPUrl = formatHDPUrl;
exports.formatIBAssetsUrl = formatIBAssetsUrl;
exports.getLabelTypeOrStyling = getLabelTypeOrStyling;
exports.parseRegistrationQuestions = void 0;
var _api = require("@whitbread-eos/api");
var parseRegistrationQuestions = rawQuestions => {
  var _rawQuestions$userDef;
  var questionsArray = [];
  var fillQuestionObject = (id, mandatory, type, options, answer, label) => {
    return {
      id,
      mandatory,
      type: type === _api.BBAnswerType.U ? 'select' : 'text',
      options,
      answer: answer !== null && answer !== void 0 ? answer : '',
      label
    };
  };
  if (rawQuestions !== null && rawQuestions !== void 0 && rawQuestions.purchaseOrderManagement && rawQuestions !== null && rawQuestions !== void 0 && rawQuestions.purchaseOrderManagement.active) {
    var _rawQuestions$purchas, _rawQuestions$purchas2;
    questionsArray.push(fillQuestionObject(rawQuestions.purchaseOrderManagement.questionId, rawQuestions.purchaseOrderManagement.mandatory, (_rawQuestions$purchas = rawQuestions.purchaseOrderManagement.managementInformationAnswer) === null || _rawQuestions$purchas === void 0 ? void 0 : _rawQuestions$purchas.answerType, (_rawQuestions$purchas2 = rawQuestions.purchaseOrderManagement.managementInformationAnswer) === null || _rawQuestions$purchas2 === void 0 ? void 0 : _rawQuestions$purchas2.answers, rawQuestions.purchaseOrderManagement.answer, rawQuestions.purchaseOrderManagement.label));
  }
  if (rawQuestions !== null && rawQuestions !== void 0 && rawQuestions.customerReferenceManagement && rawQuestions !== null && rawQuestions !== void 0 && rawQuestions.customerReferenceManagement.active) {
    var _rawQuestions$custome, _rawQuestions$custome2;
    questionsArray.push(fillQuestionObject(rawQuestions.customerReferenceManagement.questionId, rawQuestions.customerReferenceManagement.mandatory, (_rawQuestions$custome = rawQuestions.customerReferenceManagement.managementInformationAnswer) === null || _rawQuestions$custome === void 0 ? void 0 : _rawQuestions$custome.answerType, (_rawQuestions$custome2 = rawQuestions.customerReferenceManagement.managementInformationAnswer) === null || _rawQuestions$custome2 === void 0 ? void 0 : _rawQuestions$custome2.answers, rawQuestions.customerReferenceManagement.answer, rawQuestions.customerReferenceManagement.label));
  }
  if (rawQuestions !== null && rawQuestions !== void 0 && (_rawQuestions$userDef = rawQuestions.userDefinedQuestions) !== null && _rawQuestions$userDef !== void 0 && _rawQuestions$userDef.length && (rawQuestions === null || rawQuestions === void 0 ? void 0 : rawQuestions.userDefinedQuestions[0]) !== null) {
    rawQuestions.userDefinedQuestions.map(question => {
      var _question$questionId, _question$managementI, _question$managementI2;
      questionsArray.push(fillQuestionObject((_question$questionId = question.questionId) !== null && _question$questionId !== void 0 ? _question$questionId : '', question.mandatory, (_question$managementI = (_question$managementI2 = question.managementInformationAnswer) === null || _question$managementI2 === void 0 ? void 0 : _question$managementI2.answerType) !== null && _question$managementI !== void 0 ? _question$managementI : _api.BBAnswerType.F, question.managementInformationAnswer.answers, question.userDefinedAnswer, question.label));
    });
  }
  return questionsArray;
};
exports.parseRegistrationQuestions = parseRegistrationQuestions;
function formatIBAssetsUrl() {
  var path = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return "https://".concat(process.env.NEXT_PUBLIC_ASSETS_URL).concat(path);
}
function formatHDPUrl(redirectURL) {
  if (!redirectURL || redirectURL.indexOf('&') === -1) {
    return redirectURL;
  }
  var checkNoOfPairs = redirectURL.match(/&/g) || [];
  var checkNoOfParams = redirectURL.match(/=/g) || [];
  if ((checkNoOfPairs === null || checkNoOfPairs === void 0 ? void 0 : checkNoOfPairs.length) === 0 || (checkNoOfParams === null || checkNoOfParams === void 0 ? void 0 : checkNoOfParams.length) !== (checkNoOfPairs === null || checkNoOfPairs === void 0 ? void 0 : checkNoOfPairs.length) + 1) {
    return redirectURL;
  }
  return redirectURL.split('&').map(kvPair => {
    if (kvPair.indexOf('ARRdd') > -1 || kvPair.indexOf('ARRmm') > -1) {
      var [key, value] = kvPair.split('=');
      return "".concat(key, "=").concat(('0' + value).slice(-2));
    }
    return kvPair;
  }).join('&');
}
var capitalizeFirstLetter = input => {
  var lowerCase = input.toLowerCase();
  return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
};
exports.capitalizeFirstLetter = capitalizeFirstLetter;
function getLabelTypeOrStyling(status, isLabelType) {
  var isEmployeeStatus = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  switch (status) {
    case _api.EmployeeStatus.Active:
      return isLabelType ? !isEmployeeStatus ? 'userMgmt.manageEmployees.seachresults.status.active' : 'userMgmt.account.status.active' : 'bg-success';
    case _api.EmployeeStatus.Inactive:
      return isLabelType ? !isEmployeeStatus ? 'userMgmt.manageEmployees.seachresults.status.inactive' : 'userMgmt.account.status.inactive' : 'bg-warning';
    case _api.EmployeeStatus.Suspended:
    case _api.EmployeeStatus.Purged:
    case _api.EmployeeStatus.Deactivated:
      return isLabelType ? !isEmployeeStatus ? 'userMgmt.manageEmployees.seachresults.status.deactivated' : 'userMgmt.account.status.deactivated' : 'bg-error';
    default:
      return '';
  }
}