"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "getEmployeeByIdQuery", {
  enumerable: true,
  get: function get() {
    return _getEmployeeById.getEmployeeByIdQuery;
  }
});
Object.defineProperty(exports, "getEmployeesQuery", {
  enumerable: true,
  get: function get() {
    return _getEmployees.getEmployeesQuery;
  }
});
Object.defineProperty(exports, "getProfileManagementLabelsQuery", {
  enumerable: true,
  get: function get() {
    return _getProfileManagementLabels.getProfileManagementLabelsQuery;
  }
});
Object.defineProperty(exports, "getRegistrationQuestionsQuery", {
  enumerable: true,
  get: function get() {
    return _getRegistrationQuestions.getRegistrationQuestionsQuery;
  }
});
Object.defineProperty(exports, "getUserManagementLabels", {
  enumerable: true,
  get: function get() {
    return _getUserManagementLabels.getUserManagementLabels;
  }
});
var _getEmployeeById = require("./getEmployeeById");
var _getEmployees = require("./getEmployees");
var _getProfileManagementLabels = require("./getProfileManagementLabels");
var _getRegistrationQuestions = require("./getRegistrationQuestions");
var _getUserManagementLabels = require("./getUserManagementLabels");