"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Tabs;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _icons = require("../../assets/icons");
var _truncateLabel = require("../../utils/truncateLabel");
var _Button = _interopRequireDefault(require("../Button"));
var _Icon = _interopRequireDefault(require("../Icon"));
var _Tooltip = _interopRequireDefault(require("../Tooltip"));
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["prefixDataTestId", "singleContent", "shortMobileLabels", "showRoomInventory", "styles", "isScrollable", "isMobileView", "hasRoomLabels"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var ROOM_COUNT_OVERFLOW = 7;
var LABEL_LENGTH_OVERFLOW = 7;
function Tabs(_ref) {
  var _rest$options, _rest$startingTab, _rest$tabScrollSize, _rest$options2;
  var {
      prefixDataTestId,
      singleContent,
      shortMobileLabels = false,
      showRoomInventory = false,
      styles,
      isScrollable,
      isMobileView,
      hasRoomLabels
    } = _ref,
    rest = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  var options = (_rest$options = rest === null || rest === void 0 ? void 0 : rest.options) !== null && _rest$options !== void 0 ? _rest$options : [];
  var handleIndexChange = newIndex => {
    if (rest !== null && rest !== void 0 && rest.setStartingTab) {
      if (rest !== null && rest !== void 0 && rest.onChange) {
        rest.onChange(newIndex);
      }
      rest === null || rest === void 0 || rest.setStartingTab(newIndex);
    }
  };
  var currentTab = (_rest$startingTab = rest === null || rest === void 0 ? void 0 : rest.startingTab) !== null && _rest$startingTab !== void 0 ? _rest$startingTab : 0;
  var tabScrollSize = (_rest$tabScrollSize = rest === null || rest === void 0 ? void 0 : rest.tabScrollSize) !== null && _rest$tabScrollSize !== void 0 ? _rest$tabScrollSize : 0;
  var isDisabledLeftArrow = currentTab <= 0;
  var isDisabledRightArrow = currentTab + tabScrollSize >= options.length;
  return (0, _jsxRuntime.jsxs)(_react.Tabs, _objectSpread(_objectSpread({}, rest), {}, {
    "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, 'TabsComponent'),
    sx: isScrollable ? _objectSpread({}, scrollableTabsStyles) : '',
    children: [(0, _jsxRuntime.jsxs)(_react.TabList, _objectSpread(_objectSpread({}, styles === null || styles === void 0 ? void 0 : styles.tabList), {}, {
      children: [isScrollable && (0, _jsxRuntime.jsx)(_Button.default, {
        "data-testid": (0, _utils.formatDataTestId)('TabScrollLeft', 'Button'),
        className: "tabs-scroll-arrow tabs-scroll-arrow--left",
        variant: "tertiary",
        isDisabled: isDisabledLeftArrow,
        onClick: () => {
          if (currentTab - tabScrollSize < 0) {
            handleIndexChange(currentTab - 1);
          } else {
            handleIndexChange(currentTab - tabScrollSize);
          }
        },
        children: (0, _jsxRuntime.jsx)(_Icon.default, {
          svg: (0, _jsxRuntime.jsx)(_icons.RoundedArrowLeft, {
            color: isDisabledLeftArrow ? 'var(--chakra-colors-lightGrey2)' : ''
          })
        })
      }), options.map(option => {
        var _option$index;
        return (0, _jsxRuntime.jsx)(_react.Tab, _objectSpread(_objectSpread({
          px: 1,
          "data-testid": (0, _utils.formatDataTestId)(option.label, 'TabButton')
        }, styles === null || styles === void 0 ? void 0 : styles.tab), {}, {
          display: !isScrollable || currentTab === option.index || currentTab + 1 === option.index ? 'flex' : 'none',
          children: (0, _jsxRuntime.jsxs)(_react.Flex, {
            flexDir: "column",
            "data-testid": (0, _utils.formatDataTestId)(option.label, 'TabButtonColumn'),
            children: [(0, _utils.ternaryCondition)(showRoomInventory, renderRoomInventoryLabel(option.label, option.roomTypeInventoryCount, option.roomTypeInventoryRoomTypesWithCount), renderLabel(options.length > ROOM_COUNT_OVERFLOW, shortMobileLabels, (_option$index = option.index) !== null && _option$index !== void 0 ? _option$index : 0, option.label)), !!option.description && renderDescription(option.description, options.length > ROOM_COUNT_OVERFLOW, shortMobileLabels), !!option.images && option.images]
          })
        }), option.index);
      }), isScrollable && (0, _jsxRuntime.jsx)(_Button.default, {
        "data-testid": (0, _utils.formatDataTestId)('TabScrollRight', 'Button'),
        className: "tabs-scroll-arrow tabs-scroll-arrow--right",
        variant: "tertiary",
        onClick: () => {
          if (currentTab + tabScrollSize + 1 >= options.length) {
            handleIndexChange(currentTab + 1);
          } else {
            handleIndexChange(currentTab + tabScrollSize);
          }
        },
        isDisabled: isDisabledRightArrow,
        children: (0, _jsxRuntime.jsx)(_Icon.default, {
          svg: (0, _jsxRuntime.jsx)(_icons.RoundedArrowRight, {
            color: isDisabledRightArrow ? 'var(--chakra-colors-lightGrey2)' : ''
          })
        })
      })]
    })), singleContent ? singleContent : (0, _jsxRuntime.jsx)(_react.TabPanels, {
      "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, 'TabPanels'),
      children: rest === null || rest === void 0 || (_rest$options2 = rest.options) === null || _rest$options2 === void 0 ? void 0 : _rest$options2.map(option => (0, _jsxRuntime.jsx)(_react.TabPanel, {
        children: option.content
      }, option.index))
    })]
  }));
  function renderRoomInventoryLabel(label, roomTypeInventoryCount, roomTypeInventoryRoomTypesWithCount) {
    if (roomTypeInventoryCount && roomTypeInventoryRoomTypesWithCount) {
      return (0, _jsxRuntime.jsx)(_Tooltip.default, {
        description: roomTypeInventoryRoomTypesWithCount,
        variant: 'infoGrey',
        svg: (0, _jsxRuntime.jsx)(_icons.Info, {}),
        placement: "bottom-start",
        children: (0, _jsxRuntime.jsxs)(_react.Text, {
          as: "h4",
          "data-testid": (0, _utils.formatDataTestId)(label, 'TabButtonWithToolTipLabel'),
          children: [label, " (", roomTypeInventoryCount, ")"]
        })
      });
    } else {
      return (0, _jsxRuntime.jsxs)(_react.Text, {
        as: "h4",
        "data-testid": (0, _utils.formatDataTestId)(label, 'TabButtonLabel'),
        children: [label, " (", roomTypeInventoryCount, ")"]
      });
    }
  }
  function formatLabel(label, index) {
    if (hasRoomLabels) {
      if (isMobileView && !isScrollable) {
        return index + 1;
      } else {
        return label;
      }
    }
    return (0, _truncateLabel.truncateLabel)(label, LABEL_LENGTH_OVERFLOW);
  }
  function renderLabel(overflow, shortMobileLabels, index) {
    var label = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
    if (shortMobileLabels && overflow) {
      return (0, _jsxRuntime.jsx)(_Tooltip.default, {
        description: label,
        variant: 'infoGrey',
        svg: (0, _jsxRuntime.jsx)(_icons.Info, {}),
        children: (0, _jsxRuntime.jsx)(_react.Text, {
          as: "h4",
          "data-testid": (0, _utils.formatDataTestId)(label, 'TabButtonLabel'),
          style: {
            fontSize: '17px'
          },
          children: formatLabel(label, index)
        })
      });
    } else {
      return (0, _jsxRuntime.jsx)(_react.Text, {
        as: "h4",
        "data-testid": (0, _utils.formatDataTestId)(label, 'TabButtonLabel'),
        style: {
          fontSize: hasRoomLabels ? '17px' : 'inherit'
        },
        children: label
      });
    }
  }
  function renderDescription(description, overflow, shortMobileLabels) {
    if (shortMobileLabels && overflow) {
      return (0, _jsxRuntime.jsx)(_Tooltip.default, {
        description: description,
        variant: 'infoGrey',
        svg: (0, _jsxRuntime.jsx)(_icons.Info, {}),
        children: (0, _jsxRuntime.jsx)(_react.Text, {
          as: "h5",
          "data-testid": (0, _utils.formatDataTestId)(description, 'TabButtonDescription'),
          children: description.substring(0, LABEL_LENGTH_OVERFLOW - 3) + '...'
        })
      });
    } else {
      return (0, _jsxRuntime.jsx)(_react.Text, {
        as: "h5",
        "data-testid": (0, _utils.formatDataTestId)(description, 'TabButtonDescription'),
        children: description
      });
    }
  }
}
var scrollableTabsStyles = {
  '.tabs-scroll-arrow': {
    width: '35px',
    '&:disabled': {
      backgroundColor: 'lightGrey5'
    },
    '&:hover:disabled': {
      backgroundColor: 'lightGrey5'
    }
  }
};