"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SearchResultsCCUIVariantContainer;
exports.viewTypeLabelsConstants = exports.getSoldOutHotels = exports.getMlosHotels = exports.getHotelsWithAvailableRooms = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _add = _interopRequireDefault(require("date-fns/add"));
var _differenceInDays = _interopRequireDefault(require("date-fns/differenceInDays"));
var _format = _interopRequireDefault(require("date-fns/format"));
var _nextI18next = require("next-i18next");
var _router = require("next/router");
var _react2 = require("react");
var _index = require("../../index");
var _utilities = require("../../search/utilities");
var _constants = require("../constants");
var _utilities2 = require("../utilities");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function getNewSearchResults(_x, _x2) {
  return _getNewSearchResults.apply(this, arguments);
}
function _getNewSearchResults() {
  _getNewSearchResults = (0, _asyncToGenerator2.default)(function* (queryClient, paramsForQuery) {
    var hotelAvailablilitiesQueryKey = ['hotelAvailabilities', paramsForQuery.startDate, paramsForQuery.endDate, paramsForQuery.rooms, paramsForQuery.place, paramsForQuery.oldWorldChannel, paramsForQuery.channel, paramsForQuery.subChannel, paramsForQuery.page, paramsForQuery.initialPageSize, paramsForQuery.lazyLoadPageSize, paramsForQuery.country, paramsForQuery.language, paramsForQuery.sort];
    var queryResult = yield queryClient.fetchQuery({
      queryKey: paramsForQuery.companyId ? [...hotelAvailablilitiesQueryKey, paramsForQuery.companyId] : hotelAvailablilitiesQueryKey,
      queryFn: () => (0, _utils.graphQLRequest)(_api.MULTI_HOTEL_AVAILABILITIES_QUERY, _objectSpread({}, paramsForQuery))
    });
    var results = queryResult.hotelAvailabilities.multiHotelAvailabilities;
    var total = queryResult.hotelAvailabilities.total;
    return {
      results,
      total
    };
  });
  return _getNewSearchResults.apply(this, arguments);
}
var MAX_NR_OF_MAP_ITEMS = 40;
var MAPPED_ROOM_TYPES = {
  DB: 'DB',
  SB: 'SB',
  FAM: 'FAM',
  TWIN: 'TWIN',
  DIS: 'DIS'
};
var {
  listView,
  mapView
} = _molecules.VIEW_TYPE_CONSTANTS;
var viewTypeLabelsConstants = exports.viewTypeLabelsConstants = {
  LIST_VIEW: 'list view',
  MAP_VIEW: 'map view'
};
function SearchResultsCCUIVariantContainer(_ref) {
  var _router$query, _headerInformationDat, _partialTranslations$, _partialTranslations$2, _headerInformationDat2;
  var {
    multiSearchParams,
    queryClient,
    isSearchError
  } = _ref;
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var router = (0, _router.useRouter)();
  var viewType = (_router$query = router.query) === null || _router$query === void 0 ? void 0 : _router$query.VIEW;
  var baseDataTestId = 'SRP';
  var roomTypes = multiSearchParams.rooms.map(room => room.type);
  var {
    isLessThanSm
  } = (0, _utils.useScreenSize)();
  var [currentPage, setCurrentPage] = (0, _react2.useState)(1);
  var [availabilityResult, setAvailabilityResult] = (0, _react2.useState)(null);
  var [items, setItems] = (0, _react2.useState)([]);
  var [resultsMeta, setResultsMeta] = (0, _react2.useState)({
    total: 0,
    currentResults: 0,
    SRisLoading: true,
    SRisError: false,
    SRError: null,
    SRisSuccess: false
  });
  var [hotelsWithAvailableRooms, setHotelsWithAvailableRooms] = (0, _react2.useState)([]);
  var [openingSoonHotels, setOpeningSoonHotels] = (0, _react2.useState)([]);
  var [soldOutHotels, setSoldOutHotels] = (0, _react2.useState)([]);
  var [mlosHotels, setMlosHotels] = (0, _react2.useState)([]);
  var isValidDate = (0, _utils.isDateValid)(Number(multiSearchParams.arrivalDay), Number(multiSearchParams.arrivalMonth), Number(multiSearchParams.arrivalYear));
  var startDate = isValidDate ? new Date(Number(multiSearchParams.arrivalYear), Number(multiSearchParams.arrivalMonth && multiSearchParams.arrivalMonth - 1), Number(multiSearchParams.arrivalDay)) : new Date();
  var endDate = (0, _add.default)(startDate, {
    days: Number(multiSearchParams.numberOfNights)
  });
  var changeViewType = (0, _react2.useCallback)(() => {
    if (viewType === mapView || viewType === undefined) {
      router.push({
        query: _objectSpread(_objectSpread({}, router.query), {}, {
          VIEW: listView
        })
      });
      return;
    }
    router.push({
      query: _objectSpread(_objectSpread({}, router.query), {}, {
        VIEW: mapView
      })
    });
  }, [viewType]);
  var changeSortValue = () => {
    return;
  };
  var {
    data: partialTranslations,
    isError: partialTranslationsIsError,
    error: partialTranslationsError,
    isLoading: partialTranslationsisLoading
  } = (0, _utils.useQueryRequest)(['searchInformation', country, language], _api.SEARCH_INFORMATION_RESULTS, {
    country,
    language
  });
  var {
    data: headerInformationData,
    isError: headerInformationIsError,
    error: headerInformationError,
    isLoading: headerInformationIsLoading
  } = (0, _utils.useQueryRequest)(['GetStaticContent', language, country], _api.GET_STATIC_CONTENT, {
    country,
    language,
    site: _api.SITE_LEISURE,
    businessBooker: false
  });
  (0, _react2.useEffect)(() => {
    if (!partialTranslationsisLoading && !partialTranslationsIsError) {
      setCurrentPage(() => 1);
      setResultsMeta(resultsMeta => _objectSpread(_objectSpread({}, resultsMeta), {}, {
        SRisLoading: true,
        SRisError: false,
        SRError: null,
        SRisSuccess: false
      }));
      getNewSearchResults(queryClient, _objectSpread(_objectSpread({}, paramsForQuery), {}, {
        page: 1
      })).then(_ref2 => {
        var {
          results,
          total
        } = _ref2;
        setResultsMeta(resultsMeta => _objectSpread(_objectSpread({}, resultsMeta), {}, {
          total,
          currentResults: results.length,
          SRisLoading: false,
          SRError: null,
          SRisError: false,
          SRisSuccess: true
        }));
        setHotelsInOrder(results, {
          clearPreviousData: true
        });
        setItems(() => [...results]);
        setCurrentPage(page => page + 1);
      }).catch(error => {
        setResultsMeta(resultsMeta => _objectSpread(_objectSpread({}, resultsMeta), {}, {
          SRisError: true,
          SRError: error,
          SRisSuccess: false
        }));
      });
    }
  }, [multiSearchParams]);
  (0, _react2.useEffect)(() => {
    if (!multiSearchParams.code) {
      var _parsedStay$data;
      var stayDetailsState = window.localStorage.getItem('StayDetailsState');
      var parsedStay = stayDetailsState && JSON.parse(stayDetailsState);
      multiSearchParams.code = parsedStay === null || parsedStay === void 0 || (_parsedStay$data = parsedStay.data) === null || _parsedStay$data === void 0 || (_parsedStay$data = _parsedStay$data.info) === null || _parsedStay$data === void 0 || (_parsedStay$data = _parsedStay$data.suggestion) === null || _parsedStay$data === void 0 ? void 0 : _parsedStay$data.hotelId;
    }
    (0, _utilities.isHotelAvailable)((0, _utilities.mapMultiSearchToQueryParams)(multiSearchParams), country, language, MAPPED_ROOM_TYPES, _constants.APP_VARIANT.CCUI).then(setAvailabilityResult);
  }, [multiSearchParams]);
  var fetchNewHotels = () => {
    if (resultsMeta.SRisLoading) return;
    setResultsMeta(resultsMeta => _objectSpread(_objectSpread({}, resultsMeta), {}, {
      SRisLoading: true,
      SRisError: false,
      SRError: null,
      SRisSuccess: false
    }));
    getNewSearchResults(queryClient, paramsForQuery).then(_ref3 => {
      var {
        results,
        total
      } = _ref3;
      setResultsMeta(resultsMeta => _objectSpread(_objectSpread({}, resultsMeta), {}, {
        total,
        currentResults: (0, _utilities2.getCurrentResultsNumber)(results, items, total),
        SRisLoading: false,
        SRisSuccess: true
      }));
      setHotelsInOrder(results);
      setItems(items => [...items, ...results]);
      setCurrentPage(page => page + 1);
      if (isLessThanSm) {
        window.scrollTo(0, 4000 * currentPage);
      } else {
        window.scrollTo(0, 1500 * currentPage);
      }
    }).catch(error => {
      setResultsMeta(resultsMeta => _objectSpread(_objectSpread({}, resultsMeta), {}, {
        SRisError: true,
        SRError: error,
        SRisSuccess: false
      }));
    });
  };
  var setHotelsInOrder = function setHotelsInOrder(hotels) {
    var {
      clearPreviousData
    } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
      clearPreviousData: false
    };
    var newHotelsWithAvailableRooms = getHotelsWithAvailableRooms(hotels);
    var newOpeningSoonHotels = (0, _utilities2.getOpeningSoonHotels)(hotels, multiSearchParams);
    var newMlosHotels = searchByHotel && currentPage === 1 ? getMlosHotels(hotels) : mlosHotels;
    var newSoldOutHotels = getSoldOutHotels(hotels, multiSearchParams, newMlosHotels);
    if (searchByHotel && currentPage === 1) {
      setMlosHotels(currentHotels => [...(!clearPreviousData ? currentHotels : []), ...newMlosHotels]);
    }
    setHotelsWithAvailableRooms(currentHotels => [...(!clearPreviousData ? currentHotels : []), ...newHotelsWithAvailableRooms]);
    setOpeningSoonHotels(currentHotels => [...(!clearPreviousData ? currentHotels : []), ...newOpeningSoonHotels]);
    setSoldOutHotels(currentHotels => [...(!clearPreviousData ? currentHotels : []), ...newSoldOutHotels]);
    var totalHotels = [...(!clearPreviousData ? mlosHotels : []), ...mlosHotels, ...(!clearPreviousData ? hotelsWithAvailableRooms : []), ...newHotelsWithAvailableRooms, ...(!clearPreviousData ? openingSoonHotels : []), ...newOpeningSoonHotels, ...(!clearPreviousData ? soldOutHotels : []), ...newSoldOutHotels];
    var isMapView = viewType === mapView;
    var {
      LIST_VIEW,
      MAP_VIEW
    } = viewTypeLabelsConstants;
    (0, _utils.updateSearchResultsAnalytics)({
      addedResults: !clearPreviousData ? hotels.length : undefined,
      isNewSearch: clearPreviousData,
      multiSearchParams,
      startDate,
      endDate,
      viewType: isMapView ? MAP_VIEW : LIST_VIEW,
      searchResults: isMapView ? 0 : totalHotels.length,
      searchResultsDisplayed: totalHotels
    });
  };
  var place = (0, _utils.getPlace)(multiSearchParams, language);
  var searchByHotel = place.locationFormat === _api.SR_FORMAT.LATLONG;
  var headerAnnouncement = headerInformationData === null || headerInformationData === void 0 || (_headerInformationDat = headerInformationData.headerInformation) === null || _headerInformationDat === void 0 ? void 0 : _headerInformationDat.announcement.text;
  var defaultHotelsOrder = (0, _react2.useMemo)(() => [...mlosHotels, ...hotelsWithAvailableRooms, ...openingSoonHotels, ...soldOutHotels], [mlosHotels, hotelsWithAvailableRooms, openingSoonHotels, soldOutHotels]);
  var paramsForQuery = {
    startDate: (0, _format.default)(startDate, 'yyyy-MM-dd'),
    endDate: (0, _format.default)(endDate, 'yyyy-MM-dd'),
    rooms: multiSearchParams.rooms,
    place,
    oldWorldChannel: multiSearchParams.bookingChannel,
    channel: _constants.APP_VARIANT.CCUI,
    subChannel: multiSearchParams.bookingChannel,
    page: currentPage,
    initialPageSize: partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$ = partialTranslations.searchInformation) === null || _partialTranslations$ === void 0 || (_partialTranslations$ = _partialTranslations$.config) === null || _partialTranslations$ === void 0 || (_partialTranslations$ = _partialTranslations$.api) === null || _partialTranslations$ === void 0 ? void 0 : _partialTranslations$.initialPageSize,
    lazyLoadPageSize: partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$2 = partialTranslations.searchInformation) === null || _partialTranslations$2 === void 0 || (_partialTranslations$2 = _partialTranslations$2.config) === null || _partialTranslations$2 === void 0 || (_partialTranslations$2 = _partialTranslations$2.api) === null || _partialTranslations$2 === void 0 ? void 0 : _partialTranslations$2.lazyLoadPageSize,
    country,
    language,
    sort: multiSearchParams.sort,
    companyId: multiSearchParams.corpId
  };
  if (headerInformationIsError || partialTranslationsIsError) {
    return (0, _jsxRuntime.jsxs)(_react.Box, {
      children: [(0, _jsxRuntime.jsx)(_react.Box, {
        children: "Error on loading translations..."
      }), (0, _jsxRuntime.jsx)(_react.Box, {
        children: partialTranslationsError.message
      }), (0, _jsxRuntime.jsx)(_react.Box, {
        children: headerInformationError.message
      })]
    });
  }
  if (partialTranslationsisLoading || headerInformationIsLoading || resultsMeta.SRisLoading && !items.length) {
    return (0, _jsxRuntime.jsx)(_atoms.LoadingSpinner, {
      loadingText: t('searchresults.list.hotel.loading')
    });
  }
  if (!resultsMeta.SRisLoading && resultsMeta.SRisError && resultsMeta.SRError) {
    var errorMessage = (0, _utilities2.firstError)(resultsMeta.SRError).message;
    if (errorMessage === _constants.INVALID_LOCATION_ERROR_MESSAGE) {
      return displayInvalidLocationWarning(headerInformationData, partialTranslations, viewType, language, multiSearchParams, baseDataTestId);
    }
  }
  var hasMore = resultsMeta.currentResults > 0 && resultsMeta.total > Number(paramsForQuery.initialPageSize);
  if (viewType === undefined || viewType !== listView && viewType !== mapView) {
    router.push({
      query: _objectSpread(_objectSpread({}, router.query), {}, {
        VIEW: listView
      })
    });
  }
  var controlsLabels = {
    buttonLabels: partialTranslations.searchInformation.content.results.menu,
    filterLabels: partialTranslations.searchInformation.content.filter
  };
  if (viewType === mapView) {
    var resultedItems = isSearchError ? items.slice(0, MAX_NR_OF_MAP_ITEMS) : [];
    return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
      position: "relative"
    }, mapViewContainerStyles), {}, {
      children: [(0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, containerStyles), {}, {
        children: resultsMeta.total > 0 && (0, _jsxRuntime.jsx)(_atoms.Notification, {
          variant: "info",
          status: "info",
          description: headerAnnouncement,
          svg: (0, _jsxRuntime.jsx)(_atoms.Info, {}),
          isInnerHTML: true,
          wrapperStyles: notificationWrapperStyles
        })
      })), (0, _jsxRuntime.jsxs)(_react.Box, {
        position: "relative",
        height: "100%",
        paddingTop: "var(--chakra-space-lg)",
        children: [(0, _jsxRuntime.jsx)(_molecules.SRControls, {
          viewType: viewType,
          labels: controlsLabels,
          sortValue: multiSearchParams.sort,
          defaultFilters: [],
          onChangeViewType: changeViewType,
          onChangeSortValue: changeSortValue,
          onChangeFilters: handleChangeFilters,
          visibility: {
            isFilterDisabled: true
          }
        }), (0, _jsxRuntime.jsx)(_index.MapViewCCUIVariant, {
          items: resultedItems,
          multiSearchParams: multiSearchParams,
          locale: language,
          partialTranslations: partialTranslations,
          headerInformation: headerInformationData,
          baseDataTestId: baseDataTestId
        })]
      })]
    }));
  }
  return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, containerStyles), {}, {
    children: [resultsMeta.total === 0 && resultsMeta.SRisSuccess && displayNoHotelsFoundWarning(headerInformationData === null || headerInformationData === void 0 || (_headerInformationDat2 = headerInformationData.headerInformation) === null || _headerInformationDat2 === void 0 ? void 0 : _headerInformationDat2.results.notifications.noResults), resultsMeta.total > 0 && isSearchError && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [headerAnnouncement && (0, _jsxRuntime.jsx)(_atoms.Notification, {
        variant: "info",
        status: "info",
        description: (0, _jsxRuntime.jsx)(_atoms.DescriptionBox, {
          html: headerAnnouncement
        }),
        svg: (0, _jsxRuntime.jsx)(_atoms.Info, {}),
        isInnerHTML: true,
        wrapperStyles: notificationWrapperStyles
      }), (0, _jsxRuntime.jsx)(_molecules.SRControls, {
        viewType: viewType !== null && viewType !== void 0 ? viewType : listView,
        labels: controlsLabels,
        sortValue: multiSearchParams.sort,
        defaultFilters: [],
        onChangeFilters: handleChangeFilters,
        onChangeViewType: changeViewType,
        onChangeSortValue: changeSortValue,
        visibility: {
          isFilterDisabled: true,
          isSortDisabled: true
        }
      })]
    }), isSearchError && (0, _jsxRuntime.jsx)(_index.ListView, {
      fetchNewHotels: fetchNewHotels,
      items: items,
      hasMore: hasMore,
      resultsMeta: resultsMeta,
      partialTranslations: partialTranslations,
      orderedHotels: defaultHotelsOrder,
      isHotelAvailable: availabilityResult,
      roomTypes: roomTypes,
      multiSearchParams: multiSearchParams,
      language: language,
      currentPage: currentPage,
      baseDataTestId: baseDataTestId,
      headerInformation: headerInformationData,
      changeViewType: changeViewType,
      variant: "ccui"
    })]
  }));
}
var getHotelsWithAvailableRooms = hotels => hotels === null || hotels === void 0 ? void 0 : hotels.filter(hotel => {
  var _hotel$hotelInformati;
  if (!(hotel !== null && hotel !== void 0 && (_hotel$hotelInformati = hotel.hotelInformation) !== null && _hotel$hotelInformati !== void 0 && _hotel$hotelInformati.hotelOpeningDate)) {
    var _hotel$hotelAvailabil;
    return hotel === null || hotel === void 0 || (_hotel$hotelAvailabil = hotel.hotelAvailability) === null || _hotel$hotelAvailabil === void 0 ? void 0 : _hotel$hotelAvailabil.available;
  } else {
    try {
      var _hotel$hotelAvailabil2;
      return (hotel === null || hotel === void 0 || (_hotel$hotelAvailabil2 = hotel.hotelAvailability) === null || _hotel$hotelAvailabil2 === void 0 ? void 0 : _hotel$hotelAvailabil2.available) && (0, _differenceInDays.default)(new Date(hotel.hotelInformation.hotelOpeningDate), new Date()) <= 0;
    } catch (e) {
      return false;
    }
  }
});
exports.getHotelsWithAvailableRooms = getHotelsWithAvailableRooms;
var getSoldOutHotels = (hotels, multiSearchParams, mlosHotels) => hotels === null || hotels === void 0 ? void 0 : hotels.filter(hotel => {
  var _hotel$hotelInformati2;
  if (mlosHotels.find(mlosHotel => mlosHotel.hotelId === hotel.hotelId)) {
    return false;
  }
  if (!(hotel !== null && hotel !== void 0 && (_hotel$hotelInformati2 = hotel.hotelInformation) !== null && _hotel$hotelInformati2 !== void 0 && _hotel$hotelInformati2.hotelOpeningDate)) {
    var _hotel$hotelAvailabil3;
    return !(hotel !== null && hotel !== void 0 && (_hotel$hotelAvailabil3 = hotel.hotelAvailability) !== null && _hotel$hotelAvailabil3 !== void 0 && _hotel$hotelAvailabil3.available);
  }
  try {
    var _hotel$hotelAvailabil4, _hotel$hotelInformati3;
    return !(hotel !== null && hotel !== void 0 && (_hotel$hotelAvailabil4 = hotel.hotelAvailability) !== null && _hotel$hotelAvailabil4 !== void 0 && _hotel$hotelAvailabil4.available) && (0, _differenceInDays.default)(new Date(hotel === null || hotel === void 0 || (_hotel$hotelInformati3 = hotel.hotelInformation) === null || _hotel$hotelInformati3 === void 0 ? void 0 : _hotel$hotelInformati3.hotelOpeningDate), new Date("".concat(multiSearchParams === null || multiSearchParams === void 0 ? void 0 : multiSearchParams.arrivalMonth, "/").concat(multiSearchParams === null || multiSearchParams === void 0 ? void 0 : multiSearchParams.arrivalDay, "/").concat(multiSearchParams === null || multiSearchParams === void 0 ? void 0 : multiSearchParams.arrivalYear))) <= 0;
  } catch (e) {
    return false;
  }
});
exports.getSoldOutHotels = getSoldOutHotels;
var getMlosHotels = hotels => hotels === null || hotels === void 0 ? void 0 : hotels.filter(hotel => {
  var _hotel$hotelAvailabil5;
  return hotel === null || hotel === void 0 || (_hotel$hotelAvailabil5 = hotel.hotelAvailability) === null || _hotel$hotelAvailabil5 === void 0 ? void 0 : _hotel$hotelAvailabil5.hasMlosRestriction;
});
exports.getMlosHotels = getMlosHotels;
var displayInvalidLocationWarning = (headerInformationData, partialTranslations, viewType, language, multiSearchParams, baseDataTestId) => {
  var _partialTranslations$3, _partialTranslations$4;
  var description = (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_react.Text, {
      children: (_partialTranslations$3 = partialTranslations.searchInformation) === null || _partialTranslations$3 === void 0 || (_partialTranslations$3 = _partialTranslations$3.content) === null || _partialTranslations$3 === void 0 || (_partialTranslations$3 = _partialTranslations$3.results) === null || _partialTranslations$3 === void 0 || (_partialTranslations$3 = _partialTranslations$3.notifications) === null || _partialTranslations$3 === void 0 ? void 0 : _partialTranslations$3.errorTitle
    }), (0, _jsxRuntime.jsx)(_react.Text, {
      children: (_partialTranslations$4 = partialTranslations.searchInformation) === null || _partialTranslations$4 === void 0 || (_partialTranslations$4 = _partialTranslations$4.content) === null || _partialTranslations$4 === void 0 || (_partialTranslations$4 = _partialTranslations$4.results) === null || _partialTranslations$4 === void 0 || (_partialTranslations$4 = _partialTranslations$4.notifications) === null || _partialTranslations$4 === void 0 ? void 0 : _partialTranslations$4.availabilitiesErrorMessage
    })]
  });
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, containerStyles), {}, {
      children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
        status: "warning",
        variant: "alert",
        description: description,
        svg: (0, _jsxRuntime.jsx)(_atoms.Alert, {
          color: "var(--chakra-colors-alert)"
        }),
        wrapperStyles: notificationWrapperStyles
      })
    })), viewType === mapView && (0, _jsxRuntime.jsx)(_react.Box, {
      mt: "md",
      height: "100%",
      children: (0, _jsxRuntime.jsx)(_index.MapViewCCUIVariant, {
        items: [],
        multiSearchParams: multiSearchParams,
        locale: language,
        partialTranslations: partialTranslations,
        headerInformation: headerInformationData,
        baseDataTestId: baseDataTestId
      })
    })]
  });
};
var displayNoHotelsFoundWarning = description => {
  return (0, _jsxRuntime.jsx)(_atoms.Notification, {
    variant: "alert",
    status: "warning",
    description: description,
    svg: (0, _jsxRuntime.jsx)(_atoms.Alert, {}),
    wrapperStyles: notificationWrapperStyles
  });
};
function handleChangeFilters(filters) {
  return filters;
}
var notificationWrapperStyles = {
  marginTop: {
    base: 'md',
    lg: 'lg'
  },
  zIndex: '1'
};
var containerStyles = {
  maxWidth: {
    mobile: '100%',
    lg: 'var(--chakra-space-breakpoint-lg)',
    xl: 'var(--chakra-space-breakpoint-xl)'
  },
  px: {
    mobile: '1rem',
    sm: '1.25rem',
    md: '1.5rem',
    lg: '1.75rem',
    xl: '4.125rem'
  },
  width: '100%',
  mx: 'auto'
};
var mapViewContainerStyles = {
  height: '100%',
  flexDirection: 'column'
};