"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "FormCountries", {
  enumerable: true,
  get: function get() {
    return _formCountries.FormCountries;
  }
});
var _formCountries = require("./form-countries");