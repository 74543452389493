"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _getters = require("./getters");
Object.keys(_getters).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _getters[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _getters[key];
    }
  });
});
var _labels = require("./labels");
Object.keys(_labels).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _labels[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _labels[key];
    }
  });
});
var _search = require("./search");
Object.keys(_search).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _search[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _search[key];
    }
  });
});