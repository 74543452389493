"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_ANONYMOUS_NEWSLETTER_PREFERENCES = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var GET_ANONYMOUS_NEWSLETTER_PREFERENCES = exports.GET_ANONYMOUS_NEWSLETTER_PREFERENCES = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query getAnonymousNewsletterPreferences($brandCode: String!, $email: String!) {\n    anonymousNewsletterPreferences(brandCode: $brandCode, email: $email) {\n      optIn\n      secondOptIn\n      secondOptInReq\n      secondPartyOptIn\n      thirdPartyVendorsOptIn\n    }\n  }\n"])));