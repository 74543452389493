"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserType = exports.Scheme = exports.RegistrationRole = exports.QuestionLocation = exports.PaymentType = exports.PaymentStatus = exports.EmployeeStatus = exports.DashboardType = exports.Channel = exports.CardType = exports.CardStatus = exports.BookingType = exports.BookingAlertsFrequency = exports.BartBookingChannel = exports.AnswerType = exports.AddressType = exports.AddressCorrespondenceEnum = exports.ActionType = exports.AccessLevel = void 0;
var AccessLevel = exports.AccessLevel = function (AccessLevel) {
  AccessLevel["Booker"] = "BOOKER";
  AccessLevel["Self"] = "SELF";
  AccessLevel["Stayer"] = "STAYER";
  AccessLevel["Super"] = "SUPER";
  return AccessLevel;
}({});
var ActionType = exports.ActionType = function (ActionType) {
  ActionType["BookingDetails"] = "BOOKING_DETAILS";
  ActionType["Ciol"] = "CIOL";
  ActionType["Directions"] = "DIRECTIONS";
  ActionType["Upsells"] = "UPSELLS";
  return ActionType;
}({});
var AddressCorrespondenceEnum = exports.AddressCorrespondenceEnum = function (AddressCorrespondenceEnum) {
  AddressCorrespondenceEnum["CardholderAlternativeAddress"] = "CARDHOLDER_ALTERNATIVE_ADDRESS";
  AddressCorrespondenceEnum["CompanyCorrespondenceAddress"] = "COMPANY_CORRESPONDENCE_ADDRESS";
  AddressCorrespondenceEnum["CompanyRegisteredAddress"] = "COMPANY_REGISTERED_ADDRESS";
  return AddressCorrespondenceEnum;
}({});
var AddressType = exports.AddressType = function (AddressType) {
  AddressType["Business"] = "BUSINESS";
  AddressType["Home"] = "HOME";
  return AddressType;
}({});
var AnswerType = exports.AnswerType = function (AnswerType) {
  AnswerType["F"] = "F";
  AnswerType["U"] = "U";
  return AnswerType;
}({});
var BartBookingChannel = exports.BartBookingChannel = function (BartBookingChannel) {
  BartBookingChannel["Cbt"] = "CBT";
  BartBookingChannel["Mobile"] = "MOBILE";
  BartBookingChannel["Web"] = "WEB";
  BartBookingChannel["WebDe"] = "WEB_DE";
  return BartBookingChannel;
}({});
var BookingAlertsFrequency = exports.BookingAlertsFrequency = function (BookingAlertsFrequency) {
  BookingAlertsFrequency["A"] = "A";
  BookingAlertsFrequency["D"] = "D";
  BookingAlertsFrequency["M"] = "M";
  BookingAlertsFrequency["N"] = "N";
  BookingAlertsFrequency["W"] = "W";
  return BookingAlertsFrequency;
}({});
var BookingType = exports.BookingType = function (BookingType) {
  BookingType["Business"] = "BUSINESS";
  BookingType["Leisure"] = "LEISURE";
  return BookingType;
}({});
var CardStatus = exports.CardStatus = function (CardStatus) {
  CardStatus["Cancelled"] = "CANCELLED";
  CardStatus["Current"] = "CURRENT";
  CardStatus["NotActivated"] = "NOT_ACTIVATED";
  CardStatus["Pending"] = "PENDING";
  CardStatus["StopHot"] = "STOP_HOT";
  return CardStatus;
}({});
var CardType = exports.CardType = function (CardType) {
  CardType["Card"] = "CARD";
  CardType["None"] = "NONE";
  CardType["Piba"] = "PIBA";
  return CardType;
}({});
var Channel = exports.Channel = function (Channel) {
  Channel["Bb"] = "BB";
  Channel["Ccui"] = "CCUI";
  Channel["Distr"] = "DISTR";
  Channel["Employee"] = "EMPLOYEE";
  Channel["Pi"] = "PI";
  return Channel;
}({});
var DashboardType = exports.DashboardType = function (DashboardType) {
  DashboardType["FrequentBookings"] = "FREQUENT_BOOKINGS";
  DashboardType["PastSearches"] = "PAST_SEARCHES";
  DashboardType["RecentSearches"] = "RECENT_SEARCHES";
  DashboardType["UpcomingBooking"] = "UPCOMING_BOOKING";
  return DashboardType;
}({});
var EmployeeStatus = exports.EmployeeStatus = function (EmployeeStatus) {
  EmployeeStatus["Active"] = "ACTIVE";
  EmployeeStatus["Deactivated"] = "DEACTIVATED";
  EmployeeStatus["Inactive"] = "INACTIVE";
  EmployeeStatus["Purged"] = "PURGED";
  EmployeeStatus["Suspended"] = "SUSPENDED";
  return EmployeeStatus;
}({});
var PaymentStatus = exports.PaymentStatus = function (PaymentStatus) {
  PaymentStatus["NotRequired"] = "NOT_REQUIRED";
  PaymentStatus["PaymentRequired"] = "PAYMENT_REQUIRED";
  return PaymentStatus;
}({});
var PaymentType = exports.PaymentType = function (PaymentType) {
  PaymentType["AccountCompany"] = "ACCOUNT_COMPANY";
  PaymentType["Piba"] = "PIBA";
  PaymentType["ReserveWithoutCard"] = "RESERVE_WITHOUT_CARD";
  return PaymentType;
}({});
var QuestionLocation = exports.QuestionLocation = function (QuestionLocation) {
  QuestionLocation["B"] = "B";
  QuestionLocation["R"] = "R";
  return QuestionLocation;
}({});
var RegistrationRole = exports.RegistrationRole = function (RegistrationRole) {
  RegistrationRole["AccountCardHolder"] = "ACCOUNT_CARD_HOLDER";
  RegistrationRole["AccountHolder"] = "ACCOUNT_HOLDER";
  RegistrationRole["CardHolder"] = "CARD_HOLDER";
  RegistrationRole["CostCentreUser"] = "COST_CENTRE_USER";
  RegistrationRole["FinanceUser"] = "FINANCE_USER";
  RegistrationRole["FinanceUserCardHolder"] = "FINANCE_USER_CARD_HOLDER";
  return RegistrationRole;
}({});
var Scheme = exports.Scheme = function (Scheme) {
  Scheme["De"] = "DE";
  Scheme["Gb"] = "GB";
  return Scheme;
}({});
var UserType = exports.UserType = function (UserType) {
  UserType["Agent"] = "AGENT";
  UserType["Business"] = "BUSINESS";
  UserType["Leisure"] = "LEISURE";
  return UserType;
}({});