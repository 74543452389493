"use strict";
'use client';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Select = void 0;
var _server = require("@whitbread-eos/utils/server");
var _image = _interopRequireDefault(require("next/image"));
var _react = _interopRequireWildcard(require("react"));
var React = _react;
var _index = require("../Popover/index");
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var Select = _ref => {
  var {
    label,
    value,
    arrowIcon,
    testId,
    triggerIcon,
    options,
    onOptionChange,
    showValueInstead = false
  } = _ref;
  var [isOpen, setIsOpen] = (0, _react.useState)(false);
  var handleChange = value => {
    onOptionChange(value);
    setIsOpen(!isOpen);
  };
  var findDisplayValue = (options, value) => {
    var foundOption = options.find(option => option.value === value);
    return foundOption ? foundOption.displayValue : '';
  };
  var renderButton = (0, _jsxRuntime.jsxs)("div", {
    "data-testid": "".concat(testId, "-IB-Select-Trigger"),
    className: "relative flex justify-center items-center",
    children: [triggerIcon && (0, _jsxRuntime.jsx)(_image.default, {
      className: inputIconStyle,
      src: triggerIcon,
      alt: 'Select Icon',
      width: 24,
      height: 24,
      "data-testid": "".concat(testId, "-IB-Select-Icon")
    }), (0, _jsxRuntime.jsx)("div", {
      className: (0, _server.cn)(selectButtonStyle, isOpen ? 'outline outline-primaryColor outline-2 -outline-offset-2' : '', triggerIcon ? 'pl-14' : ''),
      children: showValueInstead ? value : findDisplayValue(options, value)
    }), (0, _jsxRuntime.jsx)("span", {
      className: (0, _server.cn)(labelStyle, isOpen ? 'text-primaryColor' : ''),
      "data-testid": "".concat(testId, "-IB-Select-Label"),
      children: label
    }), (0, _jsxRuntime.jsx)(_image.default, {
      className: (0, _server.cn)(arrowIconStyle, isOpen ? 'transform -scale-y-100' : ''),
      src: arrowIcon,
      alt: 'Iron icon',
      width: 24,
      height: 24
    })]
  });
  var renderOptions = () => {
    return options === null || options === void 0 ? void 0 : options.map((option, key) => (0, _jsxRuntime.jsx)("button", {
      "data-testid": "".concat(testId, "-").concat(key, "-Option"),
      type: "button",
      className: optionStyle,
      onClick: () => handleChange(option.value),
      children: option.displayValue
    }, option.value));
  };
  return (0, _jsxRuntime.jsx)("div", {
    "data-testid": "".concat(testId, "-IB-Select"),
    className: "relative",
    children: (0, _jsxRuntime.jsxs)(_index.Popover, {
      open: isOpen,
      onOpenChange: () => setIsOpen(!isOpen),
      children: [(0, _jsxRuntime.jsx)(_index.PopoverTrigger, {
        asChild: true,
        children: renderButton
      }), (0, _jsxRuntime.jsx)(_index.PopoverContent, {
        align: "start",
        avoidCollisions: false,
        "data-testid": "".concat(testId, "-IB-Select-Dropdown"),
        className: popoverStyle,
        children: renderOptions()
      })]
    })
  });
};
exports.Select = Select;
Select.displayName = 'Select';
var inputIconStyle = 'absolute left-4 w-6 h-6 text-transparent z-40';
var labelStyle = 'block absolute text-darkGrey1 -top-2.5 left-0 ml-3 text-sm px-1 bg-baseWhite peer-focus:text-primaryColor';
var selectButtonStyle = 'peer flex h-14 text-darkGrey1 border-lightGrey1 hover:border-darkGrey1 w-full rounded border bg-transparent p-4 pr-14 text-base transition-colors focus:outline-primaryColor bg-baseWhite';
var popoverStyle = 'w-full flex flex-col max-h-60 overflow-scroll p-0';
var optionStyle = 'w-full hover:bg-lightGrey5 text-left text-sm py-2.5 px-4 focus-visible:outline-none';
var arrowIconStyle = 'absolute right-4 w-6 h-6 cursor-pointer';