"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getMultiSearchParamsIB = getMultiSearchParamsIB;
exports.getSearchRedirectLink = exports.getSearchParams = exports.getQueryParams = void 0;
exports.mapSearchParamsForURL = mapSearchParamsForURL;
exports.roomOccupancyParamsForURL = void 0;
exports.updateSearchParamsIfError = updateSearchParamsIfError;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _ = require("..");
var _api = require("@whitbread-eos/api");
var _dateFns = require("date-fns");
var _headers = require("next/headers");
var _validators = require("../../validators");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var getSearchParams = () => {
  var url = (0, _headers.headers)().get('WB-Url');
  var {
    searchParams
  } = new URL(url || '');
  return searchParams;
};
exports.getSearchParams = getSearchParams;
var getQueryParams = (selectedDate, location, rooms) => {
  var _selectedDate$from, _selectedDate$to;
  if (!location) return;
  var start = (_selectedDate$from = selectedDate === null || selectedDate === void 0 ? void 0 : selectedDate.from) !== null && _selectedDate$from !== void 0 ? _selectedDate$from : new Date();
  var end = (_selectedDate$to = selectedDate === null || selectedDate === void 0 ? void 0 : selectedDate.to) !== null && _selectedDate$to !== void 0 ? _selectedDate$to : (0, _dateFns.add)(new Date(start), {
    days: 1
  });
  var queryParams = {
    searchTerm: location.suggestion,
    ARRdd: start.getDate(),
    ARRmm: start.getMonth() + 1,
    ARRyyyy: start.getFullYear(),
    nights: (0, _dateFns.differenceInDays)(end, start),
    roomsNumber: Object.keys(rooms).length,
    rooms: Object.values(rooms)
  };
  if ('placeId' in location) {
    queryParams.placeId = location.placeId;
  }
  if ('code' in location) {
    queryParams.code = location.code;
  }
  if ('geometry' in location) {
    queryParams.location = location.geometry.coordinates;
  }
  if ('brand' in location) {
    queryParams.brand = location.brand;
  }
  return queryParams;
};
exports.getQueryParams = getQueryParams;
var getSearchRedirectLink = (hotelCode, hotelHasAvailability, hotelIsOpeningSoon, hotelSlug, language, country, URLToRedirect, paramsMappedForURL, queryParams) => {
  var {
    listView
  } = _api.VIEW_TYPE;
  var [longitude = 0, latitude = 0] = queryParams.location || [];
  var location = "".concat(latitude, ",").concat(longitude);
  var baseUrl = "/".concat(country, "/").concat(language, "/business-booker");
  var urlForSrp = "search.html?searchModel.searchTerm";
  if (!hotelHasAvailability || hotelIsOpeningSoon) {
    return "".concat(baseUrl, "/").concat(urlForSrp, "=").concat(paramsMappedForURL.searchTerm, "&LOCATION=").concat(location, "&").concat(URLToRedirect, "&BOOKINGCHANNEL=").concat(_api.BOOKING_SUBCHANNEL.CBT, "&SORT=").concat(_api.DEFAULT_SORT_VALUE, "&VIEW=").concat(listView);
  }
  if (hotelSlug) {
    return "".concat(baseUrl, "/hotels").concat(hotelSlug, ".html?").concat((0, _.formatHDPUrl)(URLToRedirect));
  }
  if (!hotelCode) {
    return "".concat(baseUrl, "/").concat(urlForSrp, "=").concat(paramsMappedForURL.searchTerm, "&PLACEID=").concat(queryParams.placeId, "&").concat(URLToRedirect, "&BOOKINGCHANNEL=").concat(_api.BOOKING_SUBCHANNEL.CBT, "&SORT=").concat(_api.DEFAULT_SORT_VALUE, "&VIEW=").concat(listView);
  }
};
exports.getSearchRedirectLink = getSearchRedirectLink;
function mapSearchParamsForURL(params) {
  var _params$rooms;
  var roomsInputDataMapped = params === null || params === void 0 || (_params$rooms = params.rooms) === null || _params$rooms === void 0 ? void 0 : _params$rooms.map((room, idx) => {
    return {
      ["ADULT".concat(idx + 1)]: room.adults,
      ["CHILD".concat(idx + 1)]: room.children,
      ["COT".concat(idx + 1)]: room.shouldIncludeCot ? 1 : 0
    };
  });
  var roomInputDataObj = roomsInputDataMapped.reduce((prev, current) => _objectSpread(_objectSpread({}, prev), current), {});
  return _objectSpread(_objectSpread({}, params), roomInputDataObj);
}
var roomOccupancyParamsForURL = params => {
  var _params$rooms2;
  return params === null || params === void 0 || (_params$rooms2 = params.rooms) === null || _params$rooms2 === void 0 ? void 0 : _params$rooms2.filter(room => room.roomType).map((room, idx) => "ADULT".concat(idx + 1, "=").concat(room.adults, "&CHILD").concat(idx + 1, "=").concat(room.children, "&COT").concat(idx + 1, "=").concat(room.shouldIncludeCot ? 1 : 0, "&INTTYP").concat(idx + 1, "=").concat(room.roomType)).join('&');
};
exports.roomOccupancyParamsForURL = roomOccupancyParamsForURL;
function getMultiSearchParamsIB(searchParamsObj) {
  if (!searchParamsObj) return {};
  var {
    ARRdd,
    ARRmm,
    ARRyyyy,
    NIGHTS,
    ROOMS,
    CELLCODES = ''
  } = searchParamsObj;
  if (!ARRdd && !ARRmm && !ARRyyyy && !(0, _validators.isDateValid)(Number(ARRdd), Number(ARRmm), Number(ARRyyyy))) {
    return {
      arrival: undefined,
      departure: undefined,
      rooms: []
    };
  }
  if (!ARRdd || !ARRmm || !ARRyyyy || !NIGHTS || !ROOMS || !(0, _validators.isDateValid)(Number(ARRdd), Number(ARRmm), Number(ARRyyyy))) return {
    arrival: (0, _dateFns.format)((0, _dateFns.endOfDay)(new Date()), 'yyyy-MM-dd'),
    departure: (0, _dateFns.format)((0, _dateFns.endOfDay)((0, _dateFns.add)(new Date(), {
      days: Number(NIGHTS) || 1
    })), 'yyyy-MM-dd'),
    numberOfNights: Number(NIGHTS) || 1,
    rooms: [{
      adults: 1,
      children: 0,
      roomType: _api.ROOM_CODES.double,
      shouldIncludeCot: false
    }]
  };
  var arrival = new Date(+ARRyyyy, +ARRmm - 1, +ARRdd);
  var departure = (0, _dateFns.add)(arrival, {
    days: +NIGHTS
  });
  var rooms = [];
  for (var idx = 0; idx < +ROOMS; idx++) {
    rooms.push({
      adults: +searchParamsObj["ADULT".concat(idx + 1)],
      children: +searchParamsObj["CHILD".concat(idx + 1)],
      roomType: searchParamsObj["INTTYP".concat(idx + 1)],
      shouldIncludeCot: searchParamsObj["COT".concat(idx + 1)] === '1'
    });
  }
  return {
    arrival: (0, _dateFns.format)(arrival, 'yyyy-MM-dd'),
    departure: (0, _dateFns.format)(departure, 'yyyy-MM-dd'),
    numberOfUnits: +ROOMS,
    numberOfNights: +NIGHTS,
    rooms,
    cellCodes: String(CELLCODES)
  };
}
function validateRooms(searchParams, newSearchParams, rooms) {
  var shouldUpdate = false;
  for (var roomIndex = 1; roomIndex <= rooms; roomIndex++) {
    var adultKey = "".concat(_api.URLParams.adult).concat(roomIndex);
    var childKey = "".concat(_api.URLParams.child).concat(roomIndex);
    var cotKey = "".concat(_api.URLParams.cot).concat(roomIndex);
    var roomTypeKey = "".concat(_api.URLParams.roomType).concat(roomIndex);
    var {
      adult,
      child,
      cot,
      roomType
    } = getSearchDetails(roomIndex, searchParams);
    var allConditions = (0, _.validateRoomOccupancyConditions)(adult, child, roomType, cot);
    if (adult && child && cot && roomType && allConditions) {
      var resetRoomOccupancyParams = {
        [adultKey]: '1',
        [childKey]: '0',
        [cotKey]: '0',
        [roomTypeKey]: _api.ROOM_CODES.double
      };
      setSearchParams(newSearchParams, resetRoomOccupancyParams);
      for (var _roomIndex = 2; _roomIndex < 5; _roomIndex++) {
        newSearchParams.delete("".concat(_api.URLParams.adult).concat(_roomIndex));
        newSearchParams.delete("".concat(_api.URLParams.child).concat(_roomIndex));
        newSearchParams.delete("".concat(_api.URLParams.cot).concat(_roomIndex));
        newSearchParams.delete("".concat(_api.URLParams.roomType).concat(_roomIndex));
      }
      newSearchParams.set(_api.URLParams.rooms, '1');
      shouldUpdate = true;
    }
  }
  return shouldUpdate;
}
function updateSearchParamsIfError(searchParams, userRole) {
  var nights = searchParams.get(_api.URLParams.nights);
  var day = searchParams.get(_api.URLParams.day);
  var month = searchParams.get(_api.URLParams.month);
  var year = searchParams.get(_api.URLParams.year);
  var searchParamsObj = {};
  searchParams.forEach((value, key) => {
    searchParamsObj[key] = value;
  });
  var startDate = new Date(Number(year), Number(month) && Number(month) - 1, Number(day));
  var today = (0, _dateFns.startOfDay)(new Date());
  var shouldUpdate = false;
  var newSearchParams = new URLSearchParams(searchParams.toString());
  var daysInMonth = (0, _.getDaysInMonth)(Number(month), Number(year));
  var rooms = Number(searchParamsObj['ROOMS']);
  if (resetRoomsParams(rooms, userRole, searchParams, searchParamsObj, newSearchParams)) {
    shouldUpdate = true;
  }
  if (validateRooms(searchParams, newSearchParams, rooms)) {
    shouldUpdate = true;
  }
  if (resetNightsParams(nights, newSearchParams)) {
    shouldUpdate = true;
  }
  if (resetDateParams(year, month, day, startDate, today, daysInMonth, newSearchParams)) {
    shouldUpdate = true;
  }
  return {
    shouldUpdate,
    url: shouldUpdate ? "".concat(window.location.pathname, "?").concat(newSearchParams.toString()) : undefined
  };
}
function resetDateParams(year, month, day, startDate, today, daysInMonth, newSearchParams) {
  var shouldUpdate = false;
  if (year && month && day && ((0, _dateFns.isBefore)(startDate, today) || (0, _.isMoreThan364DaysInFuture)(startDate, today) || isNaN(Number(year)) || isNaN(Number(month)) || isNaN(Number(day)) || Number(month) < _api.DEFAULT_NUMBER || Number(month) > _api.MAX_NUMBER_OF_MONTHS || Number(day) < _api.DEFAULT_NUMBER || Number(day) > daysInMonth)) {
    var resetDate = {
      [_api.URLParams.nights]: '1',
      [_api.URLParams.day]: today.getDate().toString(),
      [_api.URLParams.month]: (today.getMonth() + 1).toString(),
      [_api.URLParams.year]: today.getFullYear().toString()
    };
    setSearchParams(newSearchParams, resetDate);
    shouldUpdate = true;
  }
  return shouldUpdate;
}
function resetRoomsParams(rooms, userRole, searchParams, searchParamsObj, newSearchParams) {
  var shouldUpdate = false;
  var resetRoomToDefault = () => {
    var resetRoomParamsToDefault = {
      [_api.URLParams.rooms]: '1',
      ["".concat(_api.URLParams.adult, "1")]: '1',
      ["".concat(_api.URLParams.child, "1")]: '0',
      ["".concat(_api.URLParams.cot, "1")]: '0',
      ["".concat(_api.URLParams.roomType, "1")]: _api.ROOM_CODES.double
    };
    setSearchParams(newSearchParams, resetRoomParamsToDefault);
    resetExtraRooms(newSearchParams, 2);
    shouldUpdate = true;
  };
  if (searchParamsObj['ROOMS'] && (Number.isNaN(rooms) || rooms < _api.DEFAULT_NUMBER || rooms > _api.MAX_ROOMS_ALLOWED || userRole === _api.BUSINESS_BOOKER_USER_ROLES.SELF && rooms > _api.DEFAULT_NUMBER)) {
    resetRoomToDefault();
  } else if (searchParamsObj['ROOMS']) {
    for (var roomIndex = 1; roomIndex <= rooms; roomIndex++) {
      var {
        adult,
        child,
        cot,
        roomType
      } = getSearchDetails(roomIndex, searchParams);
      if (!adult || !child || !cot || !roomType) {
        resetRoomToDefault();
        break;
      }
    }
  }
  return shouldUpdate;
}
function resetNightsParams(nights, newSearchParams) {
  var shouldUpdate = false;
  if (nights && (Number.isNaN(Number(nights)) || Number(nights) > _api.MAX_NUMBER_OF_NIGHTS_IB || Number(nights) < _api.DEFAULT_NUMBER)) {
    newSearchParams.set(_api.URLParams.nights, '1');
    shouldUpdate = true;
  }
  return shouldUpdate;
}
var resetExtraRooms = (searchParams, fromRoomIndex) => {
  for (var roomIndex = fromRoomIndex; roomIndex <= _api.MAX_ROOMS_ALLOWED; roomIndex++) {
    searchParams.delete("".concat(_api.URLParams.adult).concat(roomIndex));
    searchParams.delete("".concat(_api.URLParams.child).concat(roomIndex));
    searchParams.delete("".concat(_api.URLParams.cot).concat(roomIndex));
    searchParams.delete("".concat(_api.URLParams.roomType).concat(roomIndex));
  }
};
var getSearchDetails = (roomIndex, searchParams) => {
  var getParam = key => searchParams.get(key);
  var adultKey = "".concat(_api.URLParams.adult).concat(roomIndex);
  var childKey = "".concat(_api.URLParams.child).concat(roomIndex);
  var cotKey = "".concat(_api.URLParams.cot).concat(roomIndex);
  var roomTypeKey = "".concat(_api.URLParams.roomType).concat(roomIndex);
  return {
    adult: getParam(adultKey),
    child: getParam(childKey),
    cot: getParam(cotKey),
    roomType: getParam(roomTypeKey)
  };
};
var setSearchParams = (newSearchParams, paramValues) => {
  Object.keys(paramValues).forEach(key => {
    var value = paramValues[key];
    newSearchParams.set(key, value);
  });
};