"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.executeGraphQLQueries = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var executeGraphQLQueries = exports.executeGraphQLQueries = function () {
  var _ref = (0, _asyncToGenerator2.default)(function* (query, variables, selector, token) {
    var _process$env$NEXT_APP, _process, _process$env$NEXT_PUB, _process2;
    var disableCache = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
    var expirationTime = (_process$env$NEXT_APP = (_process = process) === null || _process === void 0 || (_process = _process.env) === null || _process === void 0 ? void 0 : _process.NEXT_APP_STATIC_CONTENT_CACHE_TTL) !== null && _process$env$NEXT_APP !== void 0 ? _process$env$NEXT_APP : 0;
    var endpoint = (_process$env$NEXT_PUB = (_process2 = process) === null || _process2 === void 0 || (_process2 = _process2.env) === null || _process2 === void 0 ? void 0 : _process2.NEXT_PUBLIC_GRAPHQL_ENDPOINT) !== null && _process$env$NEXT_PUB !== void 0 ? _process$env$NEXT_PUB : '';
    var cacheOptions = disableCache ? {
      cache: 'no-cache'
    } : {
      next: {
        revalidate: Number(expirationTime)
      }
    };
    try {
      var _result$errors;
      var res = yield fetch(endpoint, _objectSpread({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: "Bearer ".concat(token !== null && token !== void 0 ? token : '')
        },
        body: JSON.stringify({
          query,
          variables
        })
      }, cacheOptions));
      if (!res.ok) {
        return null;
      }
      var result = yield res.json();
      if (!(result !== null && result !== void 0 && result.data) || (result === null || result === void 0 || (_result$errors = result.errors) === null || _result$errors === void 0 ? void 0 : _result$errors.length) > 0) {
        return null;
      }
      return selector(result);
    } catch (error) {
      return null;
    }
  });
  return function executeGraphQLQueries(_x, _x2, _x3, _x4) {
    return _ref.apply(this, arguments);
  };
}();