"use strict";
'use client';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FormRadioGroup = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var React = _interopRequireWildcard(require("react"));
var _Label = require("../../Label");
var _RadioGroup = require("../../RadioGroup");
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["items", "onChange"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var FormRadioGroupItem = _ref => {
  var {
    value,
    label
  } = _ref;
  return (0, _jsxRuntime.jsxs)("div", {
    className: radioGroupItemStyle,
    children: [(0, _jsxRuntime.jsx)(_RadioGroup.RadioGroupItem, {
      value: value,
      id: value
    }), (0, _jsxRuntime.jsx)(_Label.Label, {
      className: radioGroupItemLabelStyle,
      htmlFor: value,
      children: label
    })]
  });
};
var FormRadioGroup = exports.FormRadioGroup = React.forwardRef((_ref2, ref) => {
  var {
      items,
      onChange
    } = _ref2,
    props = (0, _objectWithoutProperties2.default)(_ref2, _excluded);
  return (0, _jsxRuntime.jsx)(_RadioGroup.RadioGroup, _objectSpread(_objectSpread({
    ref: ref,
    onValueChange: onChange,
    className: radioGroupStyle
  }, props), {}, {
    children: items.map(item => (0, _jsxRuntime.jsx)(FormRadioGroupItem, {
      value: item.value,
      label: item.label
    }, item.value))
  }));
});
FormRadioGroup.displayName = _RadioGroup.RadioGroup.displayName;
var radioGroupStyle = 'mt-2';
var radioGroupItemStyle = 'flex items-center space-x-2 h-14';
var radioGroupItemLabelStyle = 'font-semibold text-base';